import React from 'react';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from 'react-toast-notifications';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import store from 'app/store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Spiner from 'common/Spiner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import CustomMsalProvider from 'CustomMsalProvider';

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
     <CustomMsalProvider>
      <ToastProvider placement='top-center' autoDismiss={true}>
        <BrowserRouter>
          <PersistGate loading={<Spiner />} persistor={persistor}>
            <App />
          </PersistGate>
        </BrowserRouter>
        <ToastContainer position='top-center' limit={1} />
      </ToastProvider>
      </CustomMsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
