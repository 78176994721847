import { useState, useEffect } from "react";
import {BrowserCacheLocation, LogLevel, ProtocolMode} from '@azure/msal-browser'

const useConfig = () => {
    const [config, setConfig] = useState();

    useEffect(() => {
        const loadAsync = async () => {

            let origin = window.location.origin;
            let pathname = window.location.pathname;
            let redirectU = `${origin}/${pathname.split('/')[1]}`;
            console.log(process.env.REACT_APP_CLIENT_ID)
            console.log(process.env.REACT_APP_AUTHORITY)
            console.log(process.env.REACT_APP_KNOWN_AUTHORITIES)
            console.log(redirectU)
            const msalConfig = {
                auth: {
                    clientId: process.env.REACT_APP_CLIENT_ID,
                    authority: process.env.REACT_APP_AUTHORITY,
                    knownAuthorities: [process.env.REACT_APP_KNOWN_AUTHORITIES],
                    redirectUri: `${redirectU}`,
                    protocolMode: ProtocolMode.OIDC,
                },
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage,
                    storeAuthStateInCookie: false,
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (level, message, containsPii) => {
                            if (containsPii) {
                                return
                            }
                            switch (level) {
                                case LogLevel.Error:
                                    console.error(message)
                                    return
                                case LogLevel.Info:
                                    console.info(message)
                                    return
                                case LogLevel.Verbose:
                                    console.debug(message)
                                    return
                                case LogLevel.Warning:
                                    console.warn(message)
                                    return
                                default:
                                    return
                            }
                        },
                    },
                },
            }
            setConfig(msalConfig);
            // sessionStorage.setItem("config", JSON.stringify(json));
        };
        loadAsync();
    }, []);

    return [config];
};

export default useConfig;