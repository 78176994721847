import { Badge } from 'antd'

export const fairArray = [
  1, 7, 8, 9, 10, 11, 15, 17, 19, 20, 33, 37, 36, 39, 42, 43, 49, 52, 54, 55,
  56, 63, 73,
]

export let brandedStands = [
  4541, 4546, 4547, 4548, 4549, 4556, 4560, 4996, 3143, 3153, 3158, 3159, 3266,
  3268, 3274, 3293, 3525, 3084, 3524, 3702, 3719, 3721, 3136, 3138, 3141, 3275,
  3716, 4401, 4402, 4463, 3755, 3757, 3760, 3762, 3766, 3767, 3768, 3769, 3773,
  3775, 3776, 3784, 4165, 4180, 4183, 4166, 4537, 5147, 4806, 4962, 4963, 5141,
  4387, 4404, 4405, 4554, 4409, 4538, 4975, 4531, 4532, 4533, 4385, 4961, 4535,
  4558, 4557, 4530, 5844, 5585, 5581, 5587, 5588, 5591, 4400, 4173, 4176, 4177,
  4178, 5724, 5725, 6035, 5590, 5592, 6017, 6034, 6076, 6082, 6087, 6088, 6073,
  6095, 6101, 6166, 6119, 6537, 6135, 5019, 6112, 6475, 6476, 6477, 6478, 5680, 6264,
  6330, 6331, 6457, 6474, 6252, 6254, 6262, 6263, 6495, 6535, 6100, 6556, 6559, 4555,
  6560 
]
export const getStatus = (value, text) => {
  switch (value) {
    case 'online':
      return <Badge status="success" className="text-capitalize" text={text} />
    case 'offline':
      return <Badge status="error" className="text-capitalize" text={text} />
    default:
      return
  }
}

export const getseprateButton = (layout, type) => {
  switch (layout) {
    case 'layout11':
      return type != 'Silver' ? true : false
    case 'layout11_new':
      return type != 'Silver' ? true : false
    case 'layout68':
      return type == 'Gold' ? true : false
    case 'layout69':
      return type == 'Gold' ? true : false
    default:
      return false
  }
}
export const getWebinarType = (layout, webinarId) => {
  console.log(webinarId)
  switch (layout) {
    case 'layout4':
    case 'layout6':
    case 'layout7':
    case 'layout8':
    case 'layout9':
    case 'layout11_new':
      return `video_rtw${webinarId}`
    case 'layout17':
    case 'layout41':
    case 'layout47':
    case 'layout50':
    case 'layout51':
    case 'layout52':
    case 'layout54':
    case 'layout63':
      return webinarId && webinarId === 1
        ? webinarId === 2
          ? `video_rtw${webinarId}`
          : 'video_rtw'
        : 'video_rtw'
    case 'layout33':
    case 'layout37':
    case 'layout42':
    case 'layout48':
      return `video_rtw${webinarId == 1 ? webinarId : 1}`
    case 'layout48_clone':
    case 'layout48_formation':
    case 'layout53':
    case 'layout57':
    case 'layout59':
    case 'layout76':
      return webinarId
        ? +webinarId === 2 || +webinarId === 1
          ? `video_rtw${webinarId}`
          : 'video_rtw'
        : 'video_rtw'
    case 'layout65':
    case 'layout66':
    case 'layout67':
    case 'layout69':
    case 'layout75':
    case 'layout20':
      return `video_rtw${webinarId}`
    case 'layout72':
    case 'layout73':
    default:
      return 'video_rtw'
  }
}
export const getHallWebinarType = (layout, hallId, webinarId) => {
  switch (layout) {
    case 'layout4':
    case 'layout6':
    case 'layout7':
    case 'layout8':
      return `video_h${hallId}tw${webinarId}`
    case 'layout9':
    case 'layout17':
    case 'layout41':
    case 'layout47':
      return `video_h${hallId}tw${webinarId}`
    case 'layout11_new':
      return `video_h${hallId}tw${webinarId}`
    case 'layout50':
    case 'layout51':
    case 'layout52':
    case 'layout54':
    case 'layout63':
      return webinarId && webinarId === 1
        ? webinarId === 2
          ? `video_htw${webinarId}`
          : 'video_htw'
        : 'video_htw'
    case 'layout33':
    case 'layout15':
    case 'layout37':
    case 'layout42':
    case 'layout48':
      return `video_h${hallId == 1 ? hallId : 1}tw${
        webinarId == 1 ? webinarId : 1
      }`
    case 'layout48_clone':
    case 'layout48_formation':
    case 'layout53':
    case 'layout57':
    case 'layout59':
    case 'layout76':
      return webinarId
        ? +webinarId === 2 || +webinarId === 1
          ? `video_h${hallId}tw${webinarId}`
          : 'video_htw'
        : 'video_h1tw1'
    case 'layout60':
      return hallId == 1
        ? `video_h1tw`
        : hallId == 2
        ? `video_h2tw`
        : `video_h3tw`
    case 'layout46':
      return hallId == 1 || hallId == 2 || hallId == 3
        ? `video_h${hallId}tw`
        : `video_h3tw`
    case 'layout65':
    case 'layout67':
    case 'layout66':
      return hallId == 1
        ? `video_h${hallId}tw${webinarId}`
        : `video_h1tw${webinarId}`
    case 'layout75':
      return `video_h1tw1`
    case 'layout71':
      return hallId == 1
        ? `video_h${hallId}tw${webinarId}`
        : `video_h3tw${webinarId}`
    case 'layout69':
    case 'layout20':
      return `video_h1tw${webinarId}`
    // case "layout8":
    //   return `video_h1tw1`;
    case 'layout72':
      return hallId == 1 || hallId == 2 || hallId == 3
        ? `video_h${hallId}tw${webinarId}`
        : `video_h3tw${webinarId}`
    case 'layout73':
    default:
      return 'video_htw'
  }
}

export const getWebinarVideo = (layout, webinarId) => {
  console.log(webinarId, 'webinarInCom')
  switch (layout) {
    case 'layout4':
    case 'layout6':
    case 'layout7':
    case 'layout8':
    case 'layout9':
    case 'layout11':
    case 'layout17':
    case 'layout39':
    case 'layout41':
    case 'layout46':
    case 'layout47':
    case 'layout50':
    case 'layout51':
    case 'layout52':
    case 'layout54':
    case 'layout63':
      return webinarId && webinarId === 1
        ? webinarId === 2
          ? `webinar${webinarId}`
          : 'webinar'
        : 'webinar'
    case 'layout33':
    case 'layout40_clone':
    case 'layout42':
    case 'layout37':
    case 'layout48':
    case 'layout48_clone':
      return `${webinarId}`
    case 'layout48_formation':
    case 'layout57':
    case 'layout59':
      return webinarId
        ? +webinarId === 2 || +webinarId === 1
          ? `webinar${webinarId}`
          : 'webinar'
        : 'webinar'
    case 'layout65':
    case 'layout66':
    case 'layout76':
    case 'layout2_prime':
    case 'layout53':
    case 'layout20':
      return `${webinarId}`
    case 'layout68':
      return 'webinar1'
    case 'layout69':
      return `${webinarId}`
    case 'layout71':
    case 'layout75':
      return `${webinarId}`
    case 'layout72':
    case 'layout73':
    case 'layout67':
    default:
      return 'webinar'
  }
}
export const getHallToReceptionVideo = (layout, hallId) => {
  switch (layout) {
    case 'layout4':
    case 'layout6':
    case 'layout7':
    case 'layout8':
    case 'layout9':
    case 'layout10':
    case 'layout15':
      return `hall${hallId > 1 ? 1 : hallId}/video_h${
        hallId > 1 ? 1 : hallId
      }tr`
    case 'layout17':
    case 'layout41':
    case 'layout47':
      return `hall${hallId > 1 ? 1 : hallId}/video_h${
        hallId > 1 ? 1 : hallId
      }tr`
    case 'layout50':
    case 'layout51':
    case 'layout52':
    case 'layout54':
    case 'layout60_clone':
    case 'layout63':
    case 'layout20':
    case 'layout33':
    case 'layout37':
    case 'layout75':
    case 'layout11_new':
    case 'layout40_clone':
    case 'layout48':
    case 'layout76':
      return `hall${hallId === 1 ? hallId : 1}/video_h${
        hallId === 1 ? hallId : 1
      }tr`
    case 'layout42':
    case 'layout48_clone':
    case 'layout48_formation':
    case 'layout53':
      return `hall${hallId >= 2 ? 2 : hallId}/video_h${
        hallId >= 2 ? 2 : hallId
      }tr`
    case 'layout46':
    case 'layout57':
    case 'layout59':
    case 'layout60':
      return `hall${hallId >= 3 ? 3 : hallId}/video_h${
        hallId >= 3 ? 3 : hallId
      }tr`
    case 'layout65':
      return `hall/hall1/video_h1tr.mp4`
    case 'layout66':
    case 'layout67':
      return `hall${hallId == 1 ? hallId : 1}/video_h${
        hallId == 1 ? hallId : 1
      }tr`
    case 'layout69':
      return `hall${hallId}/video_h${hallId}tr`
    case 'layout18':
    case 'layout71':
      return `hall${hallId === 1 ? hallId : 1}/video_h${
        hallId === 1 ? hallId : 1
      }tr`
    case 'layout72':
    case 'layout73':
    default:
      return `hall${hallId}/video_h${hallId}tr`
  }
}

export const getWebinarToReceptionVideo = (layout, webinarId) => {
  switch (layout) {
    case 'layout4':
    case 'layout6':
    case 'layout7':
    case 'layout8':
    case 'layout9':
    case 'layout10':
    case 'layout11_new':
      return `video_w${webinarId}tr`
    case 'layout17':
    case 'layout41':
    case 'layout47':
    case 'layout50':
    case 'layout51':
    case 'layout52':
    case 'layout54':
    case 'layout63':
      return webinarId && webinarId === 1
        ? webinarId === 2
          ? `video_w${webinarId}tr`
          : 'video_wtr'
        : 'video_wtr'
    case 'layout20':
    case 'layout33':
    case 'layout37':
    case 'layout40_clone':
    case 'layout42':
    case 'layout48':
      return `video_w${webinarId == 1 ? webinarId : 1}tr`
    case 'layout48_clone':
    case 'layout48_formation':
    case 'layout53':
    case 'layout57':
    case 'layout59':
    case 'layout76':
      return webinarId
        ? +webinarId === 2 || +webinarId === 1
          ? `video_w${webinarId}tr`
          : 'video_wtr'
        : 'video_wtr'
    case 'layout65':
    case 'layout66':
    case 'layout67':
    case 'layout69':
    case 'layout75':
      return `video_w${webinarId}tr`
    case 'layout71':
    case 'layout72':
    case 'layout73':
    default:
      return 'video_wtr'
  }
}

export const getWebinarToMainHallVideo = (layout, webinarId, hallId) => {
  switch (layout) {
    case 'layout2_prime':
    case 'layout4':
    case 'layout6':
    case 'layout7':
    case 'layout8':
    case 'layout9':
    case 'layout11_new':
      return `video_w${webinarId}th${getHallId(layout, hallId)}`
    case 'layout17':
    case 'layout40_clone':
    case 'layout41':
    case 'layout47':
    case 'layout50':
    case 'layout51':
    case 'layout52':
    case 'layout54':
    case 'layout63':
      return webinarId && webinarId === 1
        ? webinarId === 2
          ? `video_w${webinarId}th${getHallId(layout, hallId)}`
          : `video_wth${getHallId(layout, hallId)}`
        : `video_wth${getHallId(layout, hallId)}`
    case 'layout20':
    case 'layout33':
    case 'layout37':
    case 'layout11':
    case 'layout48':
    case 'layout48_clone':
    case 'layout48_formation':
    case 'layout57':
    case 'layout59':
    case 'layout76':
      return webinarId
        ? +webinarId === 2 || +webinarId === 1
          ? `video_w${webinarId}th${getHallId(layout, hallId)}`
          : `video_wth${getHallId(layout, hallId)}`
        : `video_wth${getHallId(layout, hallId)}`
    case 'layout65':
    case 'layout66':
    case 'layout67':
    case 'layout53':
    case 'layout69':
      return `video_w${webinarId}th${getHallId(layout, hallId)}`
    case 'layout71':
    case 'layout75':
      return `video_w${webinarId}th${getHallId(layout, hallId)}`
    case 'layout72':
    case 'layout73':
    default:
      return `video_wth${getHallId(layout, hallId)}`
  }
}
export const getMainHallToWebinarVideo = (layout, webinarId, hallId) => {
  switch (layout) {
    case 'layout40_clone':
    case 'layout63':
    case 'layout46':
      return (hallId && hallId === 1) || hallId === 2 || hallId === 3
        ? `video_h${getHallId(layout, hallId)}tw`
        : `video_h$3tw`
    case 'layout66':
    case 'layout67':
    case 'layout75':
    case 'layout76':
      return `video_h${getHallId(layout, hallId)}tw${webinarId}`
    case 'layout71':
      return (hallId && hallId === 1) || hallId === 2
        ? `video_h${getHallId(layout, hallId)}tw`
        : `video_h${getHallId(layout, hallId)}tw`
    case 'layout72':
      return hallId && hallId === 1
        ? hallId === 2
          ? `video_h${getHallId(layout, hallId)}tw${webinarId}`
          : `video_h${getHallId(layout, hallId)}tw`
        : `video_h${getHallId(layout, hallId)}tw`
    case 'layout73':
    default:
      return `video_h${getHallId(layout, hallId)}tw`
  }
}

export const getHallId = (layout, hallId) => {
  switch (layout) {
    case 'layout11':
      return +hallId === 1 ? 1 : +hallId === 2 ? 2 : +hallId === 3 ? 3 : 4
    case 'layout2_prime':
    case 'layout4':
    case 'layout6':
    case 'layout7':
    case 'layout9':
    case 'layout17':
    case 'layout37':
    case 'layout40':
    case 'layout40_clone':
      return hallId ? (+hallId === 1 ? hallId : 1) : 1
    case 'layout41':
    case 'layout51':
    case 'layout52':
    case 'layout54':
    case 'layout59':
    case 'layout47':
      return hallId ? (+hallId === 1 ? hallId : 1) : 1
    case 'layout56':
      return hallId ? (+hallId === 1 ? hallId : 1) : 1
    case 'layout63':
      return hallId ? (+hallId === 1 ? hallId : 1) : 1
    case 'layout10':
    case 'layout19':
    case 'layout33':
    case 'layout39':
    case 'layout42':
    case 'layout46':
      return +hallId === 1 ? 1 : +hallId === 2 ? 2 : +hallId === 3 ? 3 : 3
    case 'layout48':
      return hallId ? (+hallId === 1 ? hallId : 1) : 1
    case 'layout48_clone':
      return +hallId === 1 ? 1 : +hallId === 2 ? 2 : 2
    case 'layout49':
    case 'layout50':
    case 'layout53':
    case 'layout57':
    case 'layout60':
      return +hallId === 1 ? 1 : +hallId === 2 ? 2 : +hallId === 3 ? 3 : 3
    case 'layout65':
      return hallId ? (+hallId === 1 || +hallId === 2 ? hallId : 1) : 1
    case 'layout66':
    case 'layout67':
      return hallId == 1 ? hallId : 1
    case 'layout15':
    case 'layout18':
    case 'layout8':
      return hallId == 1 ? hallId : 1
    case 'layout20':
      return hallId && 1
    case 'layout71':
    case 'layout75':
    case 'layout76':
    case 'layout11_new':
      return hallId == 1 ? hallId : 1
    case 'layout72':
      return hallId && hallId
    case 'layout73':
      return hallId <= 3 ? hallId : 3
    default:
      return hallId
  }
}

export const detectBrowser = () => {
  var detect = function (regexp) {
    return regexp.test(window.navigator.userAgent)
  }
  switch (true) {
    case detect(/edge/i):
      return 'Edge'
    case detect(/opr/i) && (!!window.opr || !!window.opera):
      return 'Opera'
    case detect(/chrome/i) && !!window.chrome:
      return 'Chrome'
    case detect(/trident/i):
      return 'IE'
    case detect(/firefox/i):
      return 'Firefox'
    case detect(/safari/i):
      return 'Safari'
    default:
      return 'other'
  }
}

export const setMobileBtnColor = (data) => {
  document.documentElement.style.setProperty(
    '--virtualTourButtonsInnerTextColor',
    data?.virtual_tour_buttons_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--virtualTourButtonsBackgroundColor',
    data?.virtual_tour_buttons_background_color
  )
  document.documentElement.style.setProperty(
    '--goodiesBtnBackgroundColor',
    data?.goodies_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--goodiesBtnInnerText',
    data?.goodies_btn_inner_text
  )
  document.documentElement.style.setProperty(
    '--meetingBtnBackgroundColor',
    data?.meeting_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--meetingBtnInnerText',
    data?.meeting_btn_inner_text
  )
  document.documentElement.style.setProperty(
    '--virtualNetworkingTableBtnBackgroundColor',
    data?.virtual_networking_table_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--virtualNetworkingTableBtnInnerTextColor',
    data?.virtual_networking_table_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--pollBtnBackgroundColor',
    data?.poll_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--pollBtnInnerTextColor',
    data?.poll_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--mobileReceptionBtnInnerTextFront',
    data?.mobile_reception_buttons_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--mobileReceptionBtnBackgroundColorFront',
    data?.mobile_reception_buttons_background_color
  )
  document.documentElement.style.setProperty(
    '--speakersBtnBackgroundColor',
    data?.speakers_btn_background_color_front
  )
  document.documentElement.style.setProperty(
    '--speakersBtnInnerColor',
    data?.speakers_btn_inner_text_front
  )
  document.documentElement.style.setProperty(
    '--conferenceAgednaBtnBackgroundColor',
    data?.conference_agedna_btn_background_color_front
  )
  document.documentElement.style.setProperty(
    '--conferenceAgednaBtnInnerColor',
    data?.conference_agedna_btn_inner_text_front
  )
  document.documentElement.style.setProperty(
    '--companyMenuBtnBackgroundColor',
    data?.company_menu_btn_background_color_front
  )
  document.documentElement.style.setProperty(
    '--companyMenuBtnInnerText',
    data?.company_menu_btn_inner_text_front
  )
  document.documentElement.style.setProperty(
    '--faqsBtnBackgroundColor',
    data?.faqs_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--faqsBtnInnerTextColor',
    data?.faqs_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--exhibitorsHallBtnBackgroundColor',
    data?.exhibitors_hall_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--exhibitorsHallBtnInnerTextColor',
    data?.exhibitors_hall_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--aboutSidebarMenuBtnBackgroundColorFront',
    data?.about_sidebar_menu_btn_background_color_front
  )
  document.documentElement.style.setProperty(
    '--aboutSidebarMenuBtnInnerTextColorFront',
    data?.about_sidebar_menu_btn_inner_text_color_front
  )
  document.documentElement.style.setProperty(
    '--jobsBtnBackgroundColorFront',
    data?.jobs_btn_background_color_front
  )
  document.documentElement.style.setProperty(
    '--jobsBtnInnerTextColorFront',
    data?.jobs_btn_inner_text_color_front
  )
  document.documentElement.style.setProperty(
    '--coursesBtnBackgroundColorFront',
    data?.courses_btn_background_color_front
  )
  document.documentElement.style.setProperty(
    '--coursesBtnInnerTextColorFront',
    data?.courses_btn_inner_text_color_front
  )
  document.documentElement.style.setProperty(
    '--exhibitorsHallsInMainBtnBackgroundColor',
    data?.exhibitors_halls_in_main_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--exhibitorsHallsInMainBtnInnerText',
    data?.exhibitors_halls_in_main_btn_inner_text
  )
  document.documentElement.style.setProperty(
    '--dashboardBtnBackgroundColor',
    data?.dashboard_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--dashboardBtnInnerTextColor',
    data?.dashboard_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--dashboardBtnBackgroundColorFront',
    data?.dashboard_btn_background_color_front
  )
  document.documentElement.style.setProperty(
    '--dashboardBtnInnerTextFront',
    data?.dashboard_btn_inner_text_front
  )
  document.documentElement.style.setProperty(
    '--candidateNetworkingBtnBackgroundColor',
    data?.candidate_networking_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--candidateNetworkingBtnInnerTextColor',
    data?.candidate_networking_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--receptionTabBtnBackgroundColorFront',
    data?.reception_tab_btn_background_color_front
  )
  document.documentElement.style.setProperty(
    '--receptionTabBtnInnerTextFront',
    data?.reception_tab_btn_inner_text_front
  )
  document.documentElement.style.setProperty(
    '--virtualNetworkingTablesBtnInnerTextColor',
    data?.virtual_networking_tables_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--virtualNetworkingTablesBtnBackgroundColor',
    data?.virtual_networking_tables_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--sidebarExitSurveyBtnBackgroundColor',
    data?.sidebar_exit_survey_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--sidebarExitSurveyBtnInnerText',
    data?.sidebar_exit_survey_btn_inner_text
  )
  // document.documentElement.style.setProperty(
  //   "--speakersAgendaBtnBackgroundColor",
  //   data?.speakers_agenda_btn_background_color
  // );
  // document.documentElement.style.setProperty(
  //   "--speakersAgendaBtnInnerText",
  //   data?.speakers_agenda_btn_inner_text
  // );
  document.documentElement.style.setProperty(
    '--recruitersTabBtnBackgroundColor',
    data?.recruiters_tab_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--recruitersTabBtnInnerTextColor',
    data?.recruiters_tab_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--personalityTestSidebarBtnBackgroundColor',
    data?.personality_test_sidebar_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--personalityTestSidebarBtnInnerTextColor',
    data?.personality_test_sidebar_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--chatBtnBackgroundColor',
    data?.chat_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--chatBtnInnerTextColor',
    data?.chat_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--mediaBtnBackgroundColor',
    data?.media_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--mediaBtnInnerTextColor',
    data?.media_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--documentsBtnBackgroundColor',
    data?.documents_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--documentsBtnInnerTextColor',
    data?.documents_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--webinarsBtnBackgroundColor',
    data?.webinars_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--webinarsBtnInnerTextColor',
    data?.webinars_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--supportBtnBackgroundColor',
    data?.support_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--supportBtnInnerTextColor',
    data?.support_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--exhibitorBtnBackgroundColor',
    data?.exhibitor_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--exhibitorBtnInnerTextColor',
    data?.exhibitor_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--interviewsBtnBackgroundColor',
    data?.interviews_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--interviewsBtnInnerTextColor',
    data?.interviews_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--field1BtnBackgroundColor',
    data?.field1_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--field1BtnInnerTextColor',
    data?.field1_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--field2BtnBackgroundColor',
    data?.field2_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--field2BtnInnerTextColor',
    data?.field2_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--field3BtnBackgroundColor',
    data?.field3_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--field3BtnInnerTextColor',
    data?.field3_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--conferenceAgendaBtnBackgroundColor',
    data?.conference_agenda_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--conferenceAgendaBtnInnerTextColor',
    data?.conference_agenda_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--stageConferenceAgendaBtnBackgroundColor',
    data?.stage_conference_agenda_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--stageConferenceAgendaBtnInnerTextColor',
    data?.stage_conference_agenda_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--speakerAgendaBtnBackgroundColor',
    data?.speaker_agenda_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--speakerAgendaBtnInnerTextColor',
    data?.speaker_agenda_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--virtualNetworkingTableBtnBackgroundColor',
    data?.virtual_networking_table_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--virtualNetworkingTableBtnInnerTextColor',
    data?.virtual_networking_table_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--homeBtnBackgroundColor',
    data?.home_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--homeBtnInnerTextColor',
    data?.home_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--loginBtnBackgroundColor',
    data?.login_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--loginBtnInnerTextColor',
    data?.login_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--registerBtnBackgroundColor',
    data?.register_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--registerBtnInnerTextColor',
    data?.register_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--visitStandBtnBackgroundColor',
    data?.visit_stand_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--visitStandBtnInnerTextColor',
    data?.visit_stand_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--viewDetailBtnBackgroundColor',
    data?.view_detail_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--viewDetailBtnInnerTextColor',
    data?.view_detail_btn_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--needHelpBtnInnerText',
    data?.need_help_btn_inner_text
  )
  document.documentElement.style.setProperty(
    '--needHelpBtnBackgroundColor',
    data?.need_help_btn_background_color
  )
  document.documentElement.style.setProperty(
    '--companyMenuBtnInnerTextFront',
    data?.company_menu_btn_inner_text_front
  )
  document.documentElement.style.setProperty(
    '--companyMenuBtnBackgroundColorFront',
    data?.company_menu_btn_background_color_front
  )
}

export const setStylingLayout = (data) => {
  document.documentElement.style.setProperty(
    '--companiesMapsBtnBackgroundColorFront',
    data?.companies_maps_btn_background_color_front
  )
  document.documentElement.style.setProperty(
    '--companiesMapsBtnInnerTextColorFront',
    data?.companies_maps_btn_inner_text_color_front
  )
  document.documentElement.style.setProperty(
    '--buttonInnerColorFront',
    data?.button_inner_color_front
  )
  document.documentElement.style.setProperty(
    '--buttonBackgroundColorFront',
    data?.button_background_color_front
  )
  document.documentElement.style.setProperty(
    '--chatPopupWindowInnerColor',
    data?.chat_popup_window_inner_color
  )
  document.documentElement.style.setProperty(
    '--chatPopupWindowBackgroundColor',
    data?.chat_popup_window_background_color
  )
  document.documentElement.style.setProperty(
    '--countdown_inner_color',
    data?.countdown_inner_color
  )
  document.documentElement.style.setProperty(
    '--countDownBackgroundColor',
    data?.countdown_background_color
  )
  document.documentElement.style.setProperty(
    '--popupHeadingInnerColor',
    data?.popup_heading_inner_color
  )
  document.documentElement.style.setProperty(
    '--popupHeadingBackgroundColor',
    data?.popup_heading_background_color
  )
  document.documentElement.style.setProperty(
    '--standButtonsInnerTextColor',
    data?.stand_buttons_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--standButtonsBackgroundColor',
    data?.stand_buttons_background_color
  )
  document.documentElement.style.setProperty(
    '--multiHallInnerTextButtonColor',
    data?.multi_hall_btn_font_color_front
  )

  document.documentElement.style.setProperty(
    '--multiHallBackgroundButtonColor',
    data?.multi_hall_btn_bg_color_front
  )
  document.documentElement.style.setProperty(
    '--multiHallInnerTextButtonColorActive',
    data?.multi_hall_active_btn_font_color_front
  )
  document.documentElement.style.setProperty(
    '--multiHallBackgroundButtonColorActive',
    data?.multi_hall_active_btn_bg_color_front
  )
  document.documentElement.style.setProperty(
    '--receptionButtonsInnerTextColor',
    data?.reception_buttons_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--receptionButtonsBackgroundColor',
    data?.reception_buttons_background_color
  )
  document.documentElement.style.setProperty(
    '--registerEnterInnerTextColor',
    data?.register_enter_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--registerRnterBackground',
    data?.register_enter_background
  )
  document.documentElement.style.setProperty(
    '--topNavInnerTextColor',
    data?.topnav_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--sideBarInnerTextColor',
    data?.sidebar_inner_text_color
  )
  document.documentElement.style.setProperty(
    '--topNavBackgroundColor',
    data?.topnav_background_color
  )
  document.documentElement.style.setProperty(
    '--sidebarBackgroundColor',
    data?.sidebar_background_color
  )
  document.documentElement.style.setProperty(
    '--font_size_sidebar',
    data?.fontSize_option
  )
  document.documentElement.style.setProperty(
    '--font_weight_sidebar',
    data?.fontWeight_option
  )
  document.documentElement.style.setProperty(
    '--button_font_size_reception_booth',
    data?.buttonFontSize_option !== 'default'
      ? `${data?.buttonFontSize_option}px`
      : 'Default'
  )
  document.documentElement.style.setProperty(
    '--button_font_weight_reception_booth',
    data?.buttonFontWeight_option !== 'default'
      ? `${data?.buttonFontWeight_option}`
      : 'Default'
  )
}

export const parameterValue = (name, url) => {
  if (!url) url = window.location.href
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  var regexS = '[\\?&]' + name + '=([^&#]*)'
  var regex = new RegExp(regexS)
  var results = regex.exec(url)
  return results == null ? null : results[1]
}

export const setImageSrc = (srcUrl) => {
  let imageElement = document.getElementById('PageImageBackground')
  if (imageElement) imageElement.src = srcUrl
}
