export const userObj = {
  access_token:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiMGY5YTMzMmY0ZGY4NGYwODBlMGJhMWJkMWM3OGYzNTNhY2IzNTc4MjEyY2RlMjQ3YzcyMTI3ZjkxZmM4ZWZlNGI5ZGEwMTlhMDFjZTRiM2YiLCJpYXQiOjE2OTAxMTMzNjYuODM4MTcxLCJuYmYiOjE2OTAxMTMzNjYuODM4MTczLCJleHAiOjE3MjE3MzU3NjYuODI0MDE5LCJzdWIiOiI0MTA5NTUiLCJzY29wZXMiOltdfQ.gTbFS2H1Fhmn614B78X0iXyde_rS4-rMmLADWUfNh84_X0QfW_tf11r46ZHvbj47ZYFKtp3nx3oklzs9-ysj8sydGobOJoCJLIftT-mVbk-Z-t0Q3uF72rcD44YsLH0WDnaeVeqDgX5__juC3z6q-wKOrnh-h2xqMioL675tTd_7Omd7dSCGxdwu9Slv7yJbveYK4cZjqSveKtvgJvvCQ0u6zThtrny2lM4plOa_ph4psz_-dqH_BxxY2w3MzMw9N7geDMmawD3Gxlz5H1qFxoVb4Nvz8MeLzayaJ6KNAZYwSfDv3YoVawDKwUK3J1fAbYPG_9azgQkPYeYdzMfXyr3pXQjSOrKqXylpY2A3uORic1dvOy3dck3V7R_Ng96iio6xnJRbEAMwHunxJlNb5rCjPwmrOp9zGpLM2xRXscoknrOVnyWab6lHntwjEp4AN1WyciOMkvb9cDEahv4o-5CvwBChNiToZIEB0aumBgXvgkC0sNG3XHGMwqT_r9uiul7s9zlL54oPs-uYS5WGpuOmN6K_NZgovheyuxpw8EfffWe0YZ-l3aUpFCdtPOmHm1-Ki0J1rrr0BRJPFIABiuH8oD39nIFhCY1RkH4RfrxCcV39BGs__FH3PwYuF02zTTU6pk4Mcbuy27wk0qiDqheb3Lncvuu72PMERdZ9t8s',
  user: {
    id: 410955,
    name: 'umair shafique',
    first_name: '',
    last_name: '',
    email: 'testdev@vrd.com',
    fair_id: 344,
    user_role: 'User',
    language_id: 109,
    status: 'active',
    country_name: 'Sweden',
    city_name: 'Stockholm',
    postal_code: '10316',
    phone: '',
    cv: '',
    user_task_list: null,
    profile_image: null,
    user_job_title: '',
    user_company: '',
    show_network_profile: 1,
    extra_field_updated: [],
    rtcSetting: {
      auto_id: 197577,
      user_id: 410955,
      fair_id: null,
      sendbird_avatar: 'https://ui-avatars.com/api/?name=Test+dev',
      sendbird_user_id: '20201041095509',
      sendbird_access_token: '1b77456278ddc018a988dadaeb5fc1b587b0146c',
      sendbird_session_token: null,
      sendbird_session_expiry: null,
      created_at: '2023-05-10 06:49:07',
      updated_at: '2023-05-10 06:49:07',
    },
    extra_fields: [],
    is_take_test: 0,
  },
  expires_at: '2023-07-24 11:56:06',
}
