import React from 'react'
import { ReactComponent as HomeLogo } from '../logos/Home.svg'
import { ReactComponent as AboutUs } from '../logos/AboutUs.svg'
import { ReactComponent as CandidatesNetwork } from '../logos/CandidatesNetwork.svg'
import { ReactComponent as CareerTest } from '../logos/CareerTest.svg'
import { ReactComponent as Companies } from '../logos/Companies.svg'
import { ReactComponent as CompaniesMap } from '../logos/CompaniesMap.svg'
import { ReactComponent as ConferencAgenda } from '../logos/ConferencAgenda.svg'
import { ReactComponent as Courses } from '../logos/Courses.svg'
import { ReactComponent as Dashboard } from '../logos/Dashboard.svg'
import { ReactComponent as Exhibitorshall } from '../logos/Exhibitorshall.svg'
import { ReactComponent as ExistSurvey } from '../logos/ExistSurvey.svg'
import { ReactComponent as FAQ } from '../logos/FAQ.svg'
import { ReactComponent as Goodies } from '../logos/Goodies.svg'
import { ReactComponent as Help } from '../logos/Help.svg'
import { ReactComponent as Jobs } from '../logos/Jobs.svg'
import { ReactComponent as Landing } from '../logos/Landing.svg'
import { ReactComponent as NetworkingTables } from '../logos/NetworkingTables.svg'
import { ReactComponent as PersonalityTest } from '../logos/PersonalityTest.svg'
import { ReactComponent as Reception } from '../logos/Reception.svg'
import { ReactComponent as Speakers } from '../logos/Speakers.svg'
import { ReactComponent as SpeakersAgenda } from '../logos/SpeakersAgenda.svg'
import { ReactComponent as StageConferenceAgenda } from '../logos/StageConferenceAgenda.svg'
import { ReactComponent as Recruiters } from '../logos/Recruiters.svg'
import { ReactComponent as UserProfile } from '../logos/UserProfile.svg'
import { ReactComponent as Schedules } from '../logos/Schedules.svg'
import { ReactComponent as TaskList } from '../logos/TaskList.svg'
import { ReactComponent as Webinar } from '../logos/Webinar.svg'
import { ReactComponent as Resume } from '../logos/Resume.svg'
import { ReactComponent as Media } from '../logos/Media.svg'
import { ReactComponent as Chat } from '../logos/Chat.svg'
import { ReactComponent as Login } from '../logos/Login.svg'
import { ReactComponent as Register } from '../logos/Register.svg'
import { ReactComponent as Documents } from '../logos/Documents.svg'
import { ReactComponent as VistBooth } from '../logos/VistBooth.svg'
import { ReactComponent as ViewDetail } from '../logos/ViewDetail.svg'
import { ReactComponent as VirtualTour } from '../logos/VirtualTour.svg'
import { ReactComponent as Academia } from '../logos/Academia.svg'
import { ReactComponent as Skills } from '../logos/Skills.svg'
import { ReactComponent as Certificate } from '../logos/Certificate.svg'
import { ReactComponent as Employer } from '../logos/Employer.svg'
import { ReactComponent as Inventory } from '../logos/Inventory.svg'
import { ReactComponent as Location } from '../logos/Location.svg'
import { ReactComponent as Facebook } from '../logos/Facebook.svg'
import { ReactComponent as Linkedin } from '../logos/Linkedin.svg'
import { ReactComponent as Twitter } from '../logos/Twitter.svg'
import { ReactComponent as Youtube } from '../logos/Youtube.svg'
import { ReactComponent as Google } from '../logos/Google.svg'
import { ReactComponent as Outlook } from '../logos/Outlook.svg'
import { ReactComponent as Office365 } from '../logos/Office365.svg'
import { ReactComponent as FullScreen } from '../logos/FullScreen.svg'
import { ReactComponent as ExitFullScreen } from '../logos/ExitFullScreen.svg'
import { ReactComponent as FirstPosition } from '../logos/1st_position.svg'
import { ReactComponent as SecondPosition } from '../logos/2nd_position.svg'
import { ReactComponent as ThirdPosition } from '../logos/3rd_position.svg'
import { ReactComponent as Point } from '../logos/Point.svg'
import { ReactComponent as Cash } from '../logos/Cash.svg'
import { ReactComponent as Scoreboard } from '../logos/Scoreboard.svg'
import { ReactComponent as Contact } from '../logos/Contact.svg'
import { ReactComponent as Degree } from '../logos/Degree.svg'
import { ReactComponent as Email } from '../logos/Email.svg'
import { ReactComponent as MapPin } from '../logos/Map.svg'
import { ReactComponent as Web } from '../logos/Web.svg'
import { ReactComponent as Metaverse } from '../logos/Metaverse.svg'
import InstagramPNG from '../../assets/logos/Instagram.png'

const GetLog = (type) => {
  switch (type) {
    case 'Home':
      return <HomeLogo></HomeLogo>
    case 'AboutUs':
      return <AboutUs></AboutUs>
    case 'CandidatesNetwork':
      return <CandidatesNetwork></CandidatesNetwork>
    case 'CareerTest':
      return <CareerTest></CareerTest>
    case 'Companies':
      return <Companies></Companies>
    case 'CompaniesMap':
      return <CompaniesMap></CompaniesMap>
    case 'ConferencAgenda':
      return <ConferencAgenda></ConferencAgenda>
    case 'Courses':
      return <Courses></Courses>
    case 'Dashboard':
      return <Dashboard></Dashboard>
    case 'Exhibitorshall':
      return <Exhibitorshall></Exhibitorshall>
    case 'ExistSurvey':
      return <ExistSurvey></ExistSurvey>
    case 'FAQ':
      return <FAQ></FAQ>
    case 'Goodies':
      return <Goodies></Goodies>
    case 'Help':
      return <Help></Help>
    case 'Jobs':
      return <Jobs></Jobs>
    case 'Landing':
      return <Landing></Landing>
    case 'NetworkingTables':
      return <NetworkingTables></NetworkingTables>
    case 'PersonalityTest':
      return <PersonalityTest></PersonalityTest>
    case 'Reception':
      return <Reception></Reception>
    case 'Speakers':
      return <Speakers></Speakers>
    case 'SpeakersAgenda':
      return <SpeakersAgenda></SpeakersAgenda>
    case 'StageConferenceAgenda':
      return <StageConferenceAgenda></StageConferenceAgenda>
    case 'Recruiters':
      return <Recruiters></Recruiters>
    case 'UserProfile':
      return <UserProfile></UserProfile>
    case 'Schedules':
      return <Schedules></Schedules>
    case 'TaskList':
      return <TaskList></TaskList>
    case 'Webinar':
      return <Webinar></Webinar>
    case 'Resume':
      return <Resume></Resume>
    case 'Media':
      return <Media></Media>
    case 'Chat':
      return <Chat></Chat>
    case 'Login':
      return <Login></Login>
    case 'Register':
      return <Register></Register>
    case 'Documents':
      return <Documents></Documents>
    case 'VistBooth':
      return <VistBooth></VistBooth>
    case 'ViewDetail':
      return <ViewDetail></ViewDetail>
    case 'VirtualTour':
      return <VirtualTour></VirtualTour>
    case 'Academia':
      return <Academia></Academia>
    case 'Certificate':
      return <Certificate></Certificate>
    case 'Employer':
      return <Employer></Employer>
    case 'Inventory':
      return <Inventory></Inventory>
    case 'Location':
      return <Location></Location>
    case 'Skills':
      return <Skills></Skills>
    case 'Facebook':
      return <Facebook></Facebook>
    case 'Linkedin':
      return <Linkedin></Linkedin>
    case 'Twitter':
      return <Twitter></Twitter>
    case 'Youtube':
      return <Youtube></Youtube>
    case 'Instagram':
      return (
        <img
          style={{
            height: '2.5rem',
            width: '2.5rem',
            display: 'block',
            margin: 'auto',
          }}
          src={InstagramPNG}
          alt="Insta PNG"
        />
      )
    case 'Google':
      return <Google></Google>
    case 'Outlook':
      return <Outlook></Outlook>
    case 'Office365':
      return <Office365></Office365>
    case 'FullScreen':
      return <FullScreen></FullScreen>
    case 'ExitFullScreen':
      return <ExitFullScreen></ExitFullScreen>
    case 'FirstPosition':
      return <FirstPosition></FirstPosition>
    case 'SecondPosition':
      return <SecondPosition></SecondPosition>
    case 'ThirdPosition':
      return <ThirdPosition></ThirdPosition>
    case 'Point':
      return <Point></Point>
    case 'Scoreboard':
      return <Scoreboard></Scoreboard>
    case 'Cash':
      return <Cash></Cash>
    case 'Contact':
      return <Contact></Contact>
    case 'Degree':
      return <Degree></Degree>
    case 'Email':
      return <Email></Email>
    case 'Map':
      return <MapPin></MapPin>
    case 'Web':
      return <Web></Web>
    case 'Metaverse':
      return <Metaverse></Metaverse>
    default:
      return ''
  }
}

export default GetLog