const IsFairLive = (date, fairStart, fairEnd, dryRun) => {
  if (date && fairStart && fairEnd) {
    if (date >= fairStart && date <= fairEnd) {
      return true
    }
  }
  if (dryRun) {
    return true
  }

  return false
}

export default IsFairLive
