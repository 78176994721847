import React, { Fragment, useState, useEffect } from "react";
import StarRatings from "react-star-ratings";
import { useSelector } from "react-redux";
const ExitServayRating = ({
  survey,
  ratingVal,
  setRatingVal,
  handleChangeRating,
}) => {
  const [rating, setRating] = useState(0);
  const { app } = useSelector((state) => state);
  const handleRating = (optionVal, surveyId) => {
    // setRatingVal(optionVal);
    setRating(optionVal);
    handleChangeRating(optionVal, surveyId);
  };
  useEffect(() => {
    if (ratingVal) {
      ratingVal[survey?.survey_id] && setRating(ratingVal[survey?.survey_id]);
    }
  }, []);
  return (
    <Fragment>
      <StarRatings
        id={survey?.survey_id}
        name={survey?.survey_id}
        rating={
          app?.appLayout?.isTakeSurvey &&
          app?.appLayout?.fair?.options
            ?.enable_edit_candidate_exit_survey_front === 1
            ? +rating
            : rating
        }
        starRatedColor="rgb(255, 169, 13)"
        value={survey?.survey_id}
        starDimension="30px"
        starSpacing="4px"
        changeRating={(optionVal) => handleRating(optionVal, survey?.survey_id)}
        numberOfStars={survey?.survey_options?.length}
      />
    </Fragment>
  );
};

export default ExitServayRating;
