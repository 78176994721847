import { Progress } from 'antd';
import { Star } from 'react-feather';

const ProgressCard = ({ data }) => {
  return (
    <div className='ProgressDiv DFlex'>
      <p>
        {data?.option_value}
        <Star fill='black' />
      </p>
      <Progress percent={data?.total_percentage} showInfo={false} />
      <span>{data?.total_attempt}</span>
    </div>
  );
};

export default ProgressCard;
