import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { instanceWithAuth } from "App";
import { useHistory } from "react-router";
import PrivacyModal from "modals/privacyModal/PrivacyModal";
import { addUser, changeAuthDraw } from "redux/app/appSlice";
import { setStylingLayout } from "common/common";
import { setMobileBtnColor } from "common/common";
import { ReactTostify } from "components/reactToastify/ReactTostify";
import { Checkbox } from "antd";
import "./AccentureLogin.scss";

const AccentureLogin = () => {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [agreedPrivacy, setAgreedPrivacy] = useState(false);
  const [privacyViewModal, setPrivacyViewModal] = useState(false);
  const { app } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const fairData = JSON.parse(localStorage.getItem("fairData"));
  const loginRequest = {
    scopes: ["User.Read"],
  };
  const handlePrivacyViewModal = () => {
    setPrivacyViewModal(true);
  };
  const handlePrivacyHideModal = () => {
    setPrivacyViewModal(!privacyViewModal);
  };
  const loginUser = async (accessToken) => {
    const userData = {
      access_token: accessToken,
      fair_id: app?.appLayout?.fair?.id,
      fair_info: "Y",
      timezone: fairData.timezone,
      user_country: fairData?.userCountry,
      user_city: fairData?.userCity,
      user_postal_code: fairData?.userPostalCode,
      ip: fairData.ip,
      location: fairData.location,
      browser: fairData.browser,
      platform: fairData.platform,
    };
    try {
      setIsLoading(true);
      const res = await instanceWithAuth.post(
        "/front/spa/sso/login",
        { ...userData },
        {
          headers: {
            "requested-lang": app?.langId,
          },
        }
      );
      // if (res.status === 503) {
      //   history.push(`/${app?.appLayout?.fair?.short_name}/503`);
      // }
      if (res.status === 200) {
        let translations =
          res.data?.data?.user?.fair_info?.data?.fair?.fair_translations;
        let localLang = JSON.parse(localStorage.getItem("lang"));
        let defaultLanguage;
        let languageId = res.data?.data?.user?.fair_info?.data?.fair
          ?.default_language
          ? res.data?.data?.user?.fair_info?.data?.fair?.default_language
              ?.auto_id
          : 0;
        if (translations && localLang) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) => +filterLanguage.code === +localLang?.value
            );
          defaultLanguage = filterLanguages[0].keywords;
        }
        if (translations && !localLang) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) => +filterLanguage.code === +languageId
            );
          defaultLanguage = filterLanguages[0].keywords;
        }
        let userData = {
          ...res.data?.data?.user,
          fair_info: res.data?.data?.user?.fair_info?.data,
          authToken: res?.data?.data?.access_token,
          defaultLanguage: defaultLanguage,
        };
        localStorage.setItem(
          "fairTranslation",
          JSON.stringify(
            res.data?.data?.user?.fair_info?.data?.fair?.fair_translations
          )
        );
        dispatch(addUser(userData));
        ReactTostify(
          "success",
          app?.fairLanguage?.trans_string_you_have_successfully_login
            ? app?.fairLanguage?.trans_string_you_have_successfully_login
            : "You Have Successfully Login"
        );
        setStylingLayout(res.data?.data?.user?.fair_info?.data?.fair?.options);
        if (res.data?.data?.user?.fair_info?.data?.fair?.options?.mobile_color_option== 1) {
          setMobileBtnColor(res.data?.data?.user?.fair_info?.data?.fair?.options);
        }
        dispatch(changeAuthDraw(false));
        localStorage.setItem(
          "theme",
          JSON.stringify(res.data?.data?.user?.fair_info?.data)
        );
        localStorage.setItem("isLogin", true);
        if (
          app?.appLayout?.fair?.match_algorithm === "1" &&
          !app?.appLayout?.isTakeTest
        ) {
          history.push(`/${app?.fairName}/career/test`);
        } else {
          history.push(`/${app?.fairName}/home`);
        }
      }
      if (res.status === 401) {
        ReactTostify(
          "error",
          app?.fairLanguage?.trans_credentials_not_valid
            ? app?.fairLanguage?.trans_credentials_not_valid
            : "Credentials not valid"
        );
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.data.msg) {
        setDisableBtn(true);
        setTimeout(() => setDisableBtn(false), 5000);
        ReactTostify(
          "error",
          app?.fairLanguage?.trans_credentials_not_valid
            ? app?.fairLanguage?.trans_credentials_not_valid
            : "Credentials not valid"
        );
      } else {
        ReactTostify("error", "Something went wrong. Please try again!");
      }
    }
  };
  async function getToken() {
    try {
      await instance.loginPopup(loginRequest).then((res) => {
        setAccessToken(res.accessToken);
        res.accessToken && loginUser(res.accessToken);
      });
    } catch (err) {
      console.log("error-1");
    }
  }
  return (
    <div className="Wrapper">
      <Checkbox
        className="checkLabel"
        onChange={(e) => setAgreedPrivacy(e.target.checked)}
      >
        {app?.fairLanguage?.i_agree_with}
        <span
          style={{ color: "#40a9ff", cursor: "pointer" }}
          onClick={handlePrivacyViewModal}
        >
          {" "}
          {app?.fairLanguage?.privacy_policy_key}
        </span>
        <br />
        {!agreedPrivacy && (
          <span className="WraningText">
            Acknowledgement of the Privacy Policy is required
          </span>
        )}
      </Checkbox>
      <PrivacyModal
        privacyViewModal={privacyViewModal}
        handlePrivacyHideModal={handlePrivacyHideModal}
        privacyTitle={app?.fairLanguage?.privacy_policy_key}
      />
      <button
        disabled={agreedPrivacy ? false : true}
        onClick={() => getToken()}
        className="loginBtn"
      >
        LOG IN WITH ACCENTURE <br />
        <span>HIGHLY CONFIDENTIAL</span>
      </button>
    </div>
  );
};
export default AccentureLogin;
