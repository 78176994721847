import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter} from 'react-router-dom'
import ReactGA from 'react-ga';
import {getAppLayout,handlePersistRoute} from 'redux/app/appSlice'
import IsFairLive from 'common/IsFairLive'
const RouteChangeTracker = (props) => {
    const { app } = useSelector((state) => state)
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const pathN = props.location.pathname
    const patNArr = pathN.split('/')
    const fairShortName = patNArr[1]
    const fairName = fairShortName
    const createPathWithoutH = `/${fairShortName}`
    const createPathWitH = `/${fairShortName}/home`
    let updateState = false;
    let persistRoute = false;
    let routes = ['main-hall','reception','webinar','stand','network/candidates','networks/tables','networking'];
    routes.map((item)=>{
      if(props.location.pathname.indexOf(item) !== -1){
        persistRoute = true
      }
    }); 

    if(!app?.appLayout || app?.appLayout?.fair?.short_name !== fairShortName){
      updateState = true
    }
    if(pathN == createPathWithoutH || pathN == createPathWitH){
      updateState = false
    }
    

    useEffect( async ()=>{
        if(updateState){
            let apiData = {
              loginFair: false,
              data: { fairName },
            }
            if (app?.isLogin){
              apiData = {
                loginFair: true,
                data: {
                  fairName,
                  fairId: app?.appLayout?.fair?.id,
                  candidate_id: app?.user?.id,
                },
              }
            }
            dispatch(await getAppLayout(apiData));
            if(persistRoute){
              dispatch(handlePersistRoute(window.location.href)); 
            }
            const interval = setInterval(() => {
              setIsLoading(false)
            }, 5000)
            return () => clearInterval(interval)
        }else{
          setIsLoading(false)
        }

        if(window.location.href == app?.persistRoute){
          dispatch(handlePersistRoute("")); 
        }

    },[]);

  useEffect(()=>{
   if(app?.appLayout && app?.isLogin && app?.persistRoute && window.location.href !== app?.persistRoute && IsFairLive){
    const interval = setInterval(() => {
      window.location.href = app?.persistRoute
    }, 2000)
    return () => clearInterval(interval)
   }
  },[app?.appLayout,app?.isLogin]);

  let cStyle = {
    lineHeight:'200px',
    height:'200px',
    textAlign:'center'
  }

  let cH1Style = {
    lineHeight:'1.5',
    display: 'inline-block',
    verticalAlign:'middle'
  }

  if(isLoading){
    return(
      <div style={cStyle}>
        <h1 style={cH1Style}>Please wait...</h1>
      </div>
    )
  }
  
  if(!isLoading){
    return props.children 
  }
  
};

export default withRouter(RouteChangeTracker);