import React, { Fragment, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHallId } from 'redux/app/appSlice';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const HeaderHallsTabs = () => {
  const dispatch = useDispatch();
  const { app } = useSelector((state) => state);
  const [tabsWidth, setTabsWidth] = useState(window.innerWidth);
  const [innerWidth, setInnerWidth] = useState(false);
  const [dropflex, setDropflex] = useState(false);
  const hallData = app?.getHallLang?.hallsData && app?.getHallLang?.hallsData;

  const switchHallById = (hallId) => {
    dispatch(setHallId(hallId));
  };
  const activeHallName = hallData
    ?.filter((hall, ind) => +app?.hallId === +hall.hall_id)
    .map((hall, i) => hall.hall_name)[0];
  const widthRef = useRef(null);
  const handleClick = (e) => {
    e.preventDefault();
    setDropflex(!dropflex);
  };
  const updateDimensions = () => {
    setTabsWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    setTabsWidth(window.innerWidth);
  }, []);
  const menu = (
    <Menu>
      {hallData?.map(
        (hall, ind) => (
          // hall.show_hall === "Y" && (
          <Menu.Item key={`HallBtn${ind}`}>
            <button
              type='button'
              className={`SidebarTabs ${
                +app?.hallId === +hall.hall_id && 'active'
              }`}
              onClick={() => switchHallById(hall.hall_id)}>
              {hall.hall_name}
            </button>
          </Menu.Item>
        )
        // )
      )}
    </Menu>
  );
  return (
    <Fragment>
      {app?.appPage === 'mainHall' ? (
        <div className='HeaderTabDiv'>
          {hallData?.length > 6 || tabsWidth < 1700 || tabsWidth < 1300 ? (
            <Dropdown overlay={menu} trigger={['click']}>
              <div className='DropDownTabs'>
                <a
                  className='ant-dropdown-link'
                  onClick={(e) => handleClick(e)}>
                  {activeHallName}
                  <DownOutlined />
                </a>
              </div>
            </Dropdown>
          ) : (
            <div
              className='HeaderTabDiv d-none d-md-flex flex-row flex-wrap'
              ref={widthRef}>
              <div className='MainHallBtnsDiv DFlex justify-content-center'>
                {app?.getHallLang?.hallsData &&
                  app?.getHallLang?.hallsData?.map(
                    (hall, ind) => (
                      // hall.show_hall === "Y" && (
                      <button
                        type='button'
                        className={`${
                          +app?.hallId === +hall.hall_id && 'active'
                        }`}
                        key={`HallBtn${ind}`}
                        onClick={() => switchHallById(hall.hall_id)}>
                        {hall.hall_name}
                      </button>
                    )
                    // )
                  )}
              </div>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default HeaderHallsTabs;
