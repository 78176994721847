import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Chat from './Chat/Chat'
import { setStylingLayout } from 'common/common'
import { setMobileBtnColor } from 'common/common'
import Layouts from 'layouts/Layouts'
import { changeSideBarCollapsed,addUser } from 'redux/app/appSlice'
import { EventType,InteractionStatus } from '@azure/msal-browser'
import RouteChangeTracker from './RouteChangeTracker'
import { ReactTostify } from 'components/reactToastify/ReactTostify'
import {
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react'
import { userObj } from 'pages/login/UserObject'
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";


let fId = localStorage.getItem('fid');

export const instanceWithAuth = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_WITH_AUTH,
  headers: {
    'Content-Type': 'application/json',
    'app-id': '680740295896',
    // 'fair-id':fId,
    'requested-lang': 109,
    Accept: 'application/json',
  },
})

export const instanceWithOutAuth = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_WITHOUT_AUTH,
  headers: {
    'Content-Type': 'application/json',
    'app-id': '680740295896',
    // 'fair-id':fId,
    'requested-lang': 109,
    Accept: 'application/json',
  },
})
export const instanceWithBaseURL = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'app-id': '680740295896',
    // 'fair-id':fId,
    'requested-lang': 109,
    Accept: 'application/json',
  },
})
export const instanceWithFront = axios.create({
  baseURL: process.env.REACT_APP_FRONT_URL,
  headers: {
    'Content-Type': 'application/json',
    'app-id': ' 680740295896',
    // 'fair-id':fId,
    'requested-lang': 109,
    Accept: 'application/json',
  },
})

export const instanceWithChat = axios.create({
  baseURL: process.env.REACT_APP_FRONT_URL,
  headers: {
    appkey: process.env.REACT_APP_CHAT_RESET_KEY,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

export const findUrl = process.env.REACT_APP_FRONT_URL;

function App() {
  const { app } = useSelector((state) => state)
  const { instance,inProgress } = useMsal()
  const dispatch = useDispatch()
  const history = useHistory()
  const isAuthenticated = useIsAuthenticated()
  
  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        instance.setActiveAccount(event.payload.account)
        let acc = event.payload.account
        let fair = app?.appLayout?.fair
        let translations = fair?.fair_translations
        let localLang = JSON.parse(localStorage.getItem('lang'))
        let defaultLanguage
        let languageId = fair?.default_language
          ? fair?.default_language?.auto_id
          : 0
        if (translations && localLang) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) =>
                +filterLanguage.code === +localLang?.value
            )
          defaultLanguage = filterLanguages[0].keywords
        }
        if (translations && !localLang) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) => +filterLanguage.code === +languageId
            )
          defaultLanguage = filterLanguages[0].keywords
        }

        let userData = {
          ...userObj?.user,
          name:acc?.name,
          fair_info: fair,
          authToken: userObj?.access_token,
          defaultLanguage: defaultLanguage,
        }
        localStorage.setItem(
          'fairTranslation',
          JSON.stringify(fair?.fair_translations)
        )
        dispatch(addUser(userData))
        ReactTostify(
          'success',
          app?.fairLanguage?.trans_string_you_have_successfully_login
            ? app?.fairLanguage
                ?.trans_string_you_have_successfully_login
            : 'You Have Successfully Login'
        )
        localStorage.setItem('theme', JSON.stringify(fair))
        localStorage.setItem('isLogin', true)
        if (
          app?.appLayout?.fair?.match_algorithm === '1' &&
          !app?.appLayout?.isTakeTest
        ) {
          history.push(`/${app?.fairName}/career/test`)
        }
      }
      if (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        console.log(event)
      }
      if (event.eventType === EventType.LOGIN_FAILURE) {
        ReactTostify('error', event.error.errorMessage)
        instance.logoutRedirect()
      }
      if (event.eventType === EventType.LOGOUT_SUCCESS) {
        ReactTostify('success', 'Logout Sucessfully')
      }
      if (event.eventType === EventType.LOGOUT_END) {
        ReactTostify('success', 'Logout Sucessfully')
      }
    })
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [instance])

  const reportWindowSize = () => {
    const widthOutput = document.body.clientWidth
    if (widthOutput < 992) {
      dispatch(changeSideBarCollapsed(true))
    }
  }

  useEffect(() => {
    localStorage.removeItem('_grecaptcha')
    if (app?.appLayout?.fair?.options) {
      setStylingLayout(app?.appLayout?.fair?.options)
    }
    if (app?.appLayout?.fair?.options?.mobile_color_option == 1) {
      setMobileBtnColor(app?.appLayout?.fair?.options)
    }

    if (app?.sideBarCollapsed && window.innerWidth >= 991) {
      dispatch(changeSideBarCollapsed(false))
    }
    window.addEventListener('resize', reportWindowSize);
  }, [app?.appLayout, app.isLogin, app.isNotFound]);


  // Create a GrowthBook instance
const gb = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_KEY,
  // Enable easier debugging during development
  enableDevMode: false,
  // Update the instance in realtime as features change in GrowthBook
  subscribeToChanges: true,
  // Only required for A/B testing
  // Called every time a user is put into an experiment
  trackingCallback: (experiment, result) => {
    console.log("Experiment Viewed", {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

useEffect(() => {
  // Load features from the GrowthBook API and initialize the SDK
  gb.loadFeatures()
}, []);

// useEffect(() => {
//   // Set user attributes for targeting (from cookie, auth system, etc.)
//   gb.setAttributes({
//     id: user.id,
//     company: user.company,
//   });
// }, [user])


  return (
    <div className='App'>
        <RouteChangeTracker>
          <GrowthBookProvider growthbook={gb}>
            <Layouts />
          </GrowthBookProvider>
          <Chat />
        </RouteChangeTracker>
    </div>
  )
}

export default withRouter(App)
