import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useIsAuthenticated } from '@azure/msal-react'

const Chat = () => {
  const { app } = useSelector((state) => state)
  let attendeeUser = 'attendee@Virtualrecruitmentdays'
  let fairDateTime = app?.appLayout?.fair?.fairTiming
  const isAuthenticated = useIsAuthenticated()
  return (
    <div>
      {app?.appLayout?.fair?.options?.disable_chat_front === 0 &&
        fairDateTime.date >= fairDateTime.fair_start &&
        fairDateTime.date <= fairDateTime.fair_end &&
        isAuthenticated &&
        app?.user?.email != attendeeUser && (
          <Helmet>
            <script
              src="/cometChatPro/js/CometChatPro.js"
              type="text/javascript"
            ></script>
          </Helmet>
        )}
    </div>
  )
}

export default Chat
