import React, { Fragment, useEffect, useState } from 'react'
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom'
import { Layout } from 'antd'
import Spiner from 'common/Spiner'
import SideBar from './sidebar/SideBar'
import NavHeader from './header/NavHeader'
import routes from 'routes/routes'
import { instanceWithOutAuth } from 'App'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeAuthDraw,
  changeSideBarCollapsed,
  toggleNotFound,
  setFairName,
} from 'redux/app/appSlice'
import useFetchToken from "customHooks/useFetchToken";
import { useIsAuthenticated } from '@azure/msal-react'


const Layouts = () => {
  const dispatch = useDispatch()
  const { fairName } = useParams()
  const history = useHistory()
  const { app } = useSelector((state) => state)
  const [width, setWidth] = useState(window.innerWidth)
  const {getToken} = useFetchToken()
  const isAuthenticated = useIsAuthenticated()

  
  const toggle = () => {
    dispatch(changeSideBarCollapsed(!app?.sideBarCollapsed))
  }

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }
  const isMobile = width <= 876
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
  }, [])
  useEffect(() => {
    let updateUserTimeZone = setInterval(() => {
      let data = {
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        candidate_id: +app?.user?.id,
      }
      if (isAuthenticated) {
        
        (async() => {
          const bearer = `Bearer ${await getToken()}`;
          const result = instanceWithOutAuth.post(`/update/user/timezone`, data, {
            headers: {
              Authorization: bearer,
              'requested-lang': app?.langId,
            },
          })
        })()

      }
    }, 300000)

    if (!isAuthenticated) {
      clearInterval(updateUserTimeZone)
    }
  }, [isAuthenticated])
  useEffect(() => {
    if (window.innerWidth <= 991) {
      dispatch(changeSideBarCollapsed(true))
    }
    if (app?.fairClose) {
      localStorage.clear()
      history.push(`/${app?.fairCloseInfo?.shortName}/close`)
    }
    if (!app?.fairName) dispatch(changeAuthDraw(false))
    if (app?.isNotFound) dispatch(toggleNotFound(false))
    isAuthenticated && (
      <Redirect to={`/${app?.appLayout?.fair?.short_name}/home`} />
    )
  }, [app?.fairClose, app?.showAppLayout])
  if (app?.showAppLayout) {
    return (
      <Fragment>
        <Layout>
          {!app?.fairClose &&
            !app?.isNotFound &&
            !app.isLoading &&
            !isMobile && <SideBar collapsedState={app?.sideBarCollapsed} />}
          <Layout className="site-layout flex-wrap">
            {!app?.fairClose && !app?.isNotFound && !app.isLoading && (
              <NavHeader
                collapsedState={app?.sideBarCollapsed}
                toggle={toggle}
              />
            )}
            <Layout.Content className="site-layout-background w-100 PagesOuterDiv">
              <img
                src={`${process.env.REACT_APP_VIDEO_ASSETS_URL}/${app?.appLayout?.fair?.layout}/`}
                alt=""
                id="PageImageBackground"
              />
              <React.Suspense fallback={<Spiner />}>
                <Switch>
                  {routes?.map((route) =>
                    route?.isAuthProtected ? (
                      <Route
                        exact
                        key={`${route?.component}Key${route?.id}`}
                        precruitercard
                        {...route}
                        collapsedState={app?.sideBarCollapsed}
                      >
                        {isAuthenticated && route?.isAuthProtected ? (
                          <route.component />
                        ) : (
                          <Redirect
                            to={`/${app?.appLayout?.fair?.short_name}/home`}
                          />
                        )}
                      </Route>
                    ) : (
                      <Route
                        exact
                        key={`${route?.component}Key${route?.id}`}
                        {...route}
                        collapsedState={app?.sideBarCollapsed}
                      />
                    )
                  )}
                  {app?.appLayout?.fair?.short_name && !app.isNotFound ? (
                    <Redirect
                      to={`/${app?.appLayout?.fair?.short_name}/home`}
                    />
                  ) : (
                    <Redirect to={'/'} />
                  )}
                </Switch>
              </React.Suspense>
            </Layout.Content>
          </Layout>
        </Layout>
      </Fragment>
    )
  } else {
    return (
      <Fragment>
        <Layout>
          <Layout className="site-layout flex-wrap">
            <Layout.Content
              style={{ height: '100vw' }}
              className="d-flex justify-content-center w-100 PagesOuterDiv"
            >
              <React.Suspense fallback={<Spiner />}>
                <Switch>
                  {routes?.map((route) =>
                    route?.isAuthProtected ? (
                      <Route
                        exact
                        key={`${route?.component}Key${route?.id}`}
                        {...route}
                        collapsedState={app?.sideBarCollapsed}
                      >
                        {isAuthenticated ? (
                          <route.component />
                        ) : (
                          <Redirect
                            to={`/${app?.appLayout?.fair?.short_name}/home`}
                          />
                        )}
                      </Route>
                    ) : (
                      <Route
                        exact
                        key={`${route?.component}Key${route?.id}`}
                        {...route}
                        collapsedState={app?.sideBarCollapsed}
                      />
                    )
                  )}
                </Switch>
              </React.Suspense>
            </Layout.Content>
          </Layout>
        </Layout>
      </Fragment>
    )
  }
}

export default Layouts
