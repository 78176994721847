import { Drawer } from 'antd';
import { XCircle } from 'react-feather';
import { Fragment } from 'react';
import RegisterForm from './RegisterForm';
import { useDispatch, useSelector } from 'react-redux';
import { changeAuthDraw, handleLoginBtn } from 'redux/app/appSlice';
import SSOLogin from './ssologin/SSOLogin';
import './registerDrawer.scss';

const RegisterDrawer = () => {
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();

  let ssoLoginPlace = 'bottom';

  if (app?.appLayout?.fair?.options?.sso_placement_front == 'top') {
    ssoLoginPlace = 'top';
  }

  return (
    <div className='registerDrawer container-fluid'>
      <Drawer
        title={
          <div className='DFlex'>
            <p className='registerHeader'>
              {app?.fairLanguage?.registration
                ? app?.fairLanguage?.registration
                : 'Registration'}
            </p>
            <div
              className='SingCrossBtn btn bg-transparent outline-none'
              onClick={() => dispatch(changeAuthDraw(false))}>
              <XCircle className='SingCrossIcon' />
            </div>
          </div>
        }
        mask={false}
        style={{ top: '49px', width: 'auto' }}
        placement='right'
        className='registerDrawer'
        closable={false}
        visible={app?.isAuthDraw}>
        {ssoLoginPlace == 'top' && (
          <>
            <SSOLogin
              name={
                app?.isRegisterBtn
                  ? app?.fairLanguage?.login
                  : app?.fairLanguage?.register
              }></SSOLogin>
            <hr className='formHr' />
          </>
        )}
        {app?.isRegisterBtn && <RegisterForm />}
        <hr className='formHr' />
        {ssoLoginPlace == 'bottom' && (
          <SSOLogin
            name={
              app?.isRegisterBtn
                ? app?.fairLanguage?.login
                : app?.fairLanguage?.register
            }
          />
        )}
      </Drawer>
    </div>
  );
};

export default RegisterDrawer;
