import { fairArray } from 'common/common'
import React, { Fragment, useEffect, useRef } from 'react'
import { ChevronLeft } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import HeaderHallsTabs from './HeaderHallsTabs'
import {
  setHallId,
  setHallToReception,
  setStandToReception,
  setWebinarToMainHall,
  setWebinarToReception,
  setMeetingToReception,
  setConferenceToReception,
  setConferenceToMainHall,
  setNetworkingHallToReception,
  setStandToMainHall,
} from 'redux/app/appSlice'
import { toast } from 'react-toastify'
const HeaderText = ({ isLive, isDisableDate }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { app } = useSelector((state) => state)
  const toastId = useRef(null)
  const tZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const goToMainHallFromStand = () => {
    dispatch(setHallId(app?.hallId ? app?.hallId : 1))
    if (app?.appLayout?.fair?.layout == 'layout2_prime') {
      dispatch(setStandToMainHall(true))
    } else {
      history.push(
        `/${
          app?.fairName ? app?.fairName : app?.appLayout?.fair?.short_name
        }/main-hall`
      )
    }
  }
  const setHeaderText = (urlVal) => {
    switch (urlVal) {
      case 'reception':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <Link
              to={`${
                app?.fairName ? app?.fairName : app?.appLayout?.fair?.short_name
              }/home`}
            >
              <ChevronLeft /> {app?.fairLanguage?.back}
            </Link>
          </div>
        )
      case 'companies':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'jobs':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'about':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'landing':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'goodiesBag':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'speakers':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'StageConferenceAgenda':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'conferenceAgenda':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'dashboard':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'networkingtable':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'faqs':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'help':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'courses':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      case 'webinar':
      case 'VideoHall':
        return (
          <div
            className="HeaderBtnDiv  d-md-flex flex-row flex-wrap"
            onClick={() => toast.dismiss(toastId.current)}
          >
            {app?.appLayout?.fair?.layout != 'layout2_prime' &&
            app?.appLayout?.fair?.layout != 'layout31' ? (
              <button
                type="button"
                onClick={() => dispatch(setWebinarToReception(true))}
              >
                <ChevronLeft /> {app?.fairLanguage?.reception}
              </button>
            ) : (
              ''
            )}
            {app?.appLayout?.fair?.id != '313' &&
            app?.appLayout?.fair?.layout != 'layout31' ? (
              <button
                type="button"
                onClick={() => dispatch(setWebinarToMainHall(true))}
              >
                <ChevronLeft /> {app?.fairLanguage?.main_hall}
              </button>
            ) : (
              ''
            )}
            <Link
              to={`${
                app?.fairName ? app?.fairName : app?.appLayout?.fair?.short_name
              }/home`}
            >
              <ChevronLeft /> {app?.fairLanguage?.home}
            </Link>
          </div>
        )
      case 'conference':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button
              type="button"
              onClick={() => dispatch(setConferenceToReception(true))}
            >
              <ChevronLeft /> {app?.fairLanguage?.reception}
            </button>
            <button
              type="button"
              onClick={() => dispatch(setConferenceToMainHall(true))}
            >
              <ChevronLeft /> {app?.fairLanguage?.main_hall}
            </button>
            <Link
              to={`${
                app?.fairName ? app?.fairName : app?.appLayout?.fair?.short_name
              }/home`}
            >
              <ChevronLeft /> {app?.fairLanguage?.home}
            </Link>
          </div>
        )
      case 'stands':
        return (
          <Fragment>
            {app?.appLayout?.fair?.layout != 'layout31' && (
              <div
                className="HeaderBtnDiv  d-md-flex flex-row flex-wrap"
                onClick={() => toast.dismiss(toastId.current)}
              >
                {app?.appLayout?.fair?.layout != 'layout2_prime' && (
                  <button
                    type="button"
                    onClick={() => dispatch(setStandToReception(true))}
                  >
                    <ChevronLeft /> {app?.fairLanguage?.reception}
                  </button>
                )}
                <button type="button" onClick={goToMainHallFromStand}>
                  <ChevronLeft /> {app?.fairLanguage?.main_hall}
                </button>
              </div>
            )}
          </Fragment>
        )
      case 'meeting':
        return (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <Link
              to={`${
                app?.fairName ? app?.fairName : app?.appLayout?.fair?.short_name
              }/home`}
            >
              <ChevronLeft /> {app?.fairLanguage?.back}
            </Link>
            <button
              type="button"
              onClick={() =>
                fairArray.includes(
                  +app?.appLayout?.fair?.layout?.split('layout')[1]
                )
                  ? history.push(
                      `/${
                        app?.fairName
                          ? app?.fairName
                          : app?.appLayout?.fair?.short_name
                      }/reception`
                    )
                  : dispatch(setMeetingToReception(true))
              }
            >
              <ChevronLeft /> {app?.fairLanguage?.reception}
            </button>
          </div>
        )
      case 'mainHall':
        return (
          <div
            className="HeaderBtnDiv  d-md-flex flex-row flex-wrap"
            onClick={() => toast.dismiss(toastId.current)}
          >
            <button
              type="button"
              onClick={() => dispatch(setHallToReception(true))}
            >
              <ChevronLeft /> {app?.fairLanguage?.reception}
            </button>
            {/* <HeaderHallsTabs /> */}
          </div>
        )
      case 'networkinghall':
        return (
          <div
            className="HeaderBtnDiv  d-md-flex flex-row flex-wrap"
            onClick={() => toast.dismiss(toastId.current)}
          >
            <button
              type="button"
              onClick={() => dispatch(setNetworkingHallToReception(true))}
            >
              <ChevronLeft /> {app?.fairLanguage?.reception}
            </button>
          </div>
        )
      case 'networkingTable':
        return (
          <div
            className="HeaderBtnDiv  d-md-flex flex-row flex-wrap"
            onClick={() => toast.dismiss(toastId.current)}
          >
            <button type="button" onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        )
      default:
        return isDisableDate ? (
          <p
            className="d-sm-none d-md-block d-lg-block  ms-auto d-flex justify-content-center headerText"
            style={{ marginLeft: '100px' }}
          >
            {app?.fairLanguage?.time_zone} : {tZone}{' '}
          </p>
        ) : (
          <p className=" d-xm-none  d-sm-none ms-auto d-md-block d-lg-block  headerText">
            {app?.fairLanguage?.start_time}{' '}
            {`${app?.appLayout?.fair?.fairTiming?.fair_start_mobile}  ${app?.appLayout?.fair?.fairTiming?.fair_start_time}`}{' '}
            {app?.fairLanguage?.end_time}{' '}
            {`${app?.appLayout?.fair?.fairTiming?.fair_end_mobile}  ${app?.appLayout?.fair?.fairTiming?.fair_end_time}`}{' '}
            |{' '}
            {app?.fairLanguage?.time_zone
              ? app?.fairLanguage?.time_zone
              : 'TimeZone'}{' '}
            : {tZone}
            {isLive ? (
              <span className=" ms-4 d-inline-block bg-success py-2 px-4 text-white text-uppercase">
                {app?.fairLanguage?.live}
              </span>
            ) : null}
          </p>
        )
    }
  }

  useEffect(() => {}, [app?.appPage])

  return <Fragment>{setHeaderText(app?.appPage)}</Fragment>
}

export default HeaderText
