import { Drawer } from "antd";
import SideBar from "../layouts/sidebar/SideBar";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";

const MobileSidebarDrawer = ({ visible, setVisible }) => {
  const location = useLocation();
  useEffect(() => {
    setVisible(false);
  }, [location]);

  return (
    <>
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        key="left"
        className="MobileSidebar"
      >
        <SideBar />
      </Drawer>
    </>
  );
};

export default MobileSidebarDrawer;
