import { useState, useEffect } from 'react'
import { XCircle } from 'react-feather'
import { useToasts } from 'react-toast-notifications'
import { Alert, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { instanceWithAuth } from 'App'
import { useHistory } from 'react-router-dom'
import ExitSurvayReviewCard from 'components/exitServay/ExitSurvayReviewCard'
import ExitServayForm from 'components/exitServay/ExitServayForm'
import Spiner from 'common/Spiner'
import {
  getAppLayout,
  removeUser,
  toggleIsLoading,
  setSwitchCompanyId,
  setSwitchFair,
  resetPreviousFairDetails,
  handleClikLogout,
} from 'redux/app/appSlice'
import './exitServayModal.scss'
import $ from 'jquery'
import EditSurveyForm from 'components/exitServay/EditSurveyForm'
import useFetchToken from "customHooks/useFetchToken";

const ExitServayModal = ({ exitServayModal, setExitServayModal }) => {
  const { app } = useSelector((state) => state)
  let isTakeSurvey = app?.appLayout?.isTakeSurvey
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [surveyData, setSurveyData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [surveyInfo, setSurveyInfo] = useState()
  const history = useHistory()
  const {getToken} = useFetchToken()
  const getExitServayData = async (fair_id, candidate_id) => {
    setIsLoading(true)
    try {
      const data = {
        fair_id,
        candidate_id,
      }
      const bearer = `Bearer ${await getToken()}`;
      const result = await instanceWithAuth.post(`fair/survey`, data, {
        headers: {
          Authorization: bearer,
          'requested-lang': app?.langId,
        },
      })
      if (result?.status === 200) {
        setIsLoading(false)
        setSurveyData(result?.data?.data?.surveyList)
      }
    } catch (error) {
      setIsLoading(false)
      addToast(
        error?.response?.data?.msg
          ? error?.response?.data?.msg
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Something went wrong!',
        {
          appearance: 'error',
        }
      )
    }
  }
  const logoutUser = () => {
    window.CometChat.logout().then(
      () => {
        // console.log('Logout completed successfully');
      },
      (error) => {
        // console.log('Logout failed with exception:', { error });
      }
    )
    $('.chatMainContainer,.chat-popup').hide()
    dispatch(toggleIsLoading(true))
    let apiData = {
      loginFair: false,
      data: {
        fairName: app?.appLayout?.fair?.short_name,
        fairId: null,
      },
    }
    localStorage.setItem('isLogin', false)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('timeZone')
    localStorage.removeItem('theme')
    localStorage.removeItem('fairData')
    dispatch(handleClikLogout(false))
    dispatch(removeUser())
    dispatch(getAppLayout(apiData))
    dispatch(setSwitchFair(false))
    dispatch(setSwitchCompanyId(null))
    dispatch(resetPreviousFairDetails())
    history.push(`/${app?.appLayout?.fair?.short_name}/home`)
  }
  const servayInfoData = async () => {
    setIsLoading(true)
    try {
      const response = await instanceWithAuth.get(
        `/fair/fairsurveycontent/${app?.appLayout?.fair?.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'requested-lang': app?.langId,
          },
        }
      )
      if (response.status === 200) {
        setIsLoading(false)
        setSurveyInfo(response?.data?.data.survey_information)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    servayInfoData()
    let fair_id = app?.appLayout?.fair?.id
    let candidate_id = app?.user?.id

    if (fair_id)
      getExitServayData(fair_id, candidate_id)
  }, [app.langid])
  console.log(app?.fairLanguage?.edit_survey, 'isTakeSurvey')
  return (
    <Modal
      title={
        <div className="DFlex">
          <p>
            {isTakeSurvey &&
            app?.appLayout?.fair?.options
              ?.enable_edit_candidate_exit_survey_front === 1
              ? app?.fairLanguage?.edit_exit_survey
              : !isTakeSurvey
              ? app?.fairLanguage?.exit_survey
              : app?.fairLanguage?.exit_survey_result}
          </p>
          <button
            type="button"
            className="close-modal-btn btn btn-transparent outline-none"
            onClick={
              !isTakeSurvey &&
              app?.appLayout?.fair?.options?.disable_force_exit_survey == 0 &&
              app?.clickedLogout
                ? () => logoutUser()
                : () => {
                    setExitServayModal()
                    setSurveyData(null)
                  }
            }
          >
            X
          </button>
        </div>
      }
      destroyOnClose={true}
      footer={false}
      width={900}
      className="ExitServayModal"
      visible={exitServayModal}
    >
      {surveyData?.length > 0 ? (
        isTakeSurvey && !isLoading ? (
          isLoading ? (
            <Spiner />
          ) : app?.appLayout?.fair?.options
              ?.enable_edit_candidate_exit_survey_front === 1 ? (
            <EditSurveyForm
              surveyData={surveyData}
              isLoading={setIsLoading}
              setExitSurveyModal={setExitServayModal}
              surveyInfo={surveyInfo}
            />
          ) : (
            <ExitSurvayReviewCard
              surveyResult={surveyData}
              isLoading={setIsLoading}
              surveyInfo={surveyInfo}
              isTakeSurvey={isTakeSurvey}
            />
          )
        ) : !isTakeSurvey && !isLoading ? (
          <ExitServayForm
            surveyData={surveyData}
            surveyInfo={surveyInfo}
            isLoading={setIsLoading}
            setExitSurveyModal={setExitServayModal}
          />
        ) : (
          ''
        )
      ) : isLoading ? (
        <Spiner />
      ) : !isLoading && surveyData?.length == 0 ? (
        <Alert
          className="text-center mx-5"
          message={
            app?.fairLanguage?.no_surveys_found
              ? app?.fairLanguage?.no_surveys_found
              : 'No Surveys Found'
          }
          type="error"
        />
      ) : (
        ''
      )}
    </Modal>
  )
}

export default ExitServayModal
