import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { instanceWithOutAuth } from 'App';
import Spiner from 'common/Spiner';
import HtmlParser from 'react-html-parser';
import { setImageSrc } from 'common/common';

function TermCondition() {
  const { app } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [IsTermCondition, setIsTermCondition] = useState();
  const getTermConditionData = async (fairId, authToken, userId) => {
    try {
      setIsLoading(true);
      const result = await instanceWithOutAuth.get(
        `/auth/fair/terms/${fairId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'requested-lang': app?.langId,
          },
        }
      );
      if (result.status === 200) {
        setIsLoading(false);
        setIsTermCondition(result?.data?.data?.terms_conditions);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setImageSrc('');
    if (app?.appLayout?.fair?.id) {
      getTermConditionData(app?.appLayout?.fair?.id);
    }
  }, []);

  return <div>{isLoading ? <Spiner /> : HtmlParser(IsTermCondition)}</div>;
}

export default TermCondition;
