import React, { useState, Fragment } from 'react';
import { Upload, Button } from 'antd';
import { instanceWithOutAuth } from 'App';
import ImgCrop from 'antd-img-crop';

import { useDispatch, useSelector } from 'react-redux';
import { ReactTostify } from 'components/reactToastify/ReactTostify';
import { setUserInfo } from 'redux/app/appSlice';
import Spiner from 'common/Spiner';
import useFetchToken from "customHooks/useFetchToken";

export default function ImageUpload({ modalClose }) {
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const {getToken} = useFetchToken()
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onClick = () => {
    setFileTypeError(true);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const handleRemoveImage = () => {
    ReactTostify('success', 'This feature will added soon!');
  };
  const uploadImage = async () => {
    setIsLoading(true);
    setFileTypeError(false);

    if (fileList[0]?.originFileObj) {
      let formData = new FormData();
      formData.append("toc_checked", true);
      formData.append('fair_id', app?.appLayout?.fair?.id);
      formData.append('candidate_id', app?.user?.id);
      formData.append('profile_image', fileList[0]?.originFileObj);
      formData.append('fair_info', 'Y');
      try {
        const bearer = `Bearer ${await getToken()}`;
        let result = await instanceWithOutAuth.post(
          '/save/candidate/profile-image',
          formData,
          {
            headers: {
              Authorization: bearer,
              'requested-lang': app?.langId,
            },
          }
        );
        if (result.status === 200) {
          ReactTostify(
            'success',
            app?.fairLanguage?.trans_profile_image_updated
              ? app?.fairLanguage?.trans_profile_image_updated
              : 'Profile image upadated successfully!'
          );
          setFileList([]);
          modalClose();
          let user = {
            ...app?.user,
            ...result?.data?.data?.user,
          };
          dispatch(setUserInfo(user));
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        ReactTostify(
          'error',
          error?.response?.data?.msg
            ? error?.response?.data?.msg
            : error?.response?.data?.message
            ? error?.response?.data?.message
            : 'Something went wrong!'
        );
      }
    } else {
      ReactTostify(
        'error',
        app?.fairLanguage?.please_select_a_profile_image
          ? app?.fairLanguage?.please_select_a_profile_image
          : 'Please select a profile image'
      );
      setIsLoading(false);
    }
  };

  return (
    <div className='CropDiv'>
      {isLoading ? (
        <Spiner />
      ) : (
        <Fragment>
          <div className='InputDiv DBlock h-auto float-none'>
            <ImgCrop rotate>
              <Upload
                name='avatar'
                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                listType='picture-card'
                fileList={fileList}
                onChange={onChange}
                onClick={onClick}
                onPreview={onPreview}
                maxCount={1}
                multiple={false}>
                {fileList.length < 1 &&
                  `+ ${
                    app?.fairLanguage?.upload
                      ? app?.fairLanguage?.upload
                      : 'Upload'
                  }`}
              </Upload>
            </ImgCrop>
            {fileTypeError ? (
              <p className='FileUploadValidationError '>
                {app?.fairLanguage?.you_can_only_upload_JPG_PNG_file
                  ? app?.fairLanguage?.you_can_only_upload_JPG_PNG_file
                  : 'You can only upload JPG/PNG file!'}
              </p>
            ) : (
              ''
            )}
          </div>
          <Fragment>
            <div className='DFlex UploadBtnDiv'>
              {/* <Button
                className="FileUploadValidationError"
                onClick={handleRemoveImage}
                type="danger"
              >
                Remove Image
              </Button> */}
              <Button onClick={() => uploadImage()} type='primary'>
                {app?.fairLanguage?.upload
                  ? `${app?.fairLanguage?.upload} ${app?.fairLanguage?.image}`
                  : ' Upload Image'}
              </Button>
            </div>
          </Fragment>
        </Fragment>
      )}
    </div>
  );
}
