import { Modal } from 'antd'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import parse from 'html-react-parser'
import { toggleWelcomeModal } from 'redux/app/appSlice'
import { ReactTostify } from 'components/reactToastify/ReactTostify'
import { useDispatch } from 'react-redux'
import './welcomeRegistrationModal.scss'
import { instanceWithAuth } from './../../App'
import { useEffect } from 'react'
import './welcomeRegistrationModal.scss'
import GetLog from 'assets/getLogo/getLog'
import { DownloadOutlined } from '@ant-design/icons'
import Spiner from 'common/Spiner'
import { isMobileOnly } from "react-device-detect";

const WelcomeRegistrationModal = ({ showModal, webinarData = {} }) => {
  const { app } = useSelector((state) => state)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const [registrationData, setregistrationData] = useState()
  const userTimeZone = new Date()
  const fairObj = app?.appLayout?.fair
  const hideModal = () => {
    dispatch(toggleWelcomeModal(false))
  }
  const getWelcomeData = async () => {
    try {
      setIsLoading(true)
      let fairId = app?.appLayout?.fair?.id
      const res = await instanceWithAuth.get(
        `fair/welcomecontentafterregistration/${fairId}`,
        {
          headers: {
            'requested-lang': app?.langId,
          },
        }
      )
      if (res.status == 200) {
        setregistrationData(res?.data?.data?.welcome_content_after_registration)
        setIsLoading(false)
      }
    } catch (error) {
      ReactTostify(
        'error',
        error?.response?.data?.msg
          ? error?.response?.data?.msg
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Something went wrong!'
      )
    }
  }
  useEffect(() => {
    getWelcomeData()
  }, [app?.langId])
  if (isLoading) {
    return <Spiner />
  }

  return (
    <Modal
      title={<p> {app?.appLayout?.fair?.name} </p>}
      className="welcomeContentOuter"
      footer={false}
      visible={showModal}
      style={{ marginTop: '.5%' }}
      width={900}
      onCancel={hideModal}
    >
      {registrationData ? (
        <>
          {parse(registrationData)}
          <div>
          {app?.appLayout?.fair?.options?.disable_event_save_to_calendar === 0 &&
             <>
            <div className="saveToCalendarText">
              <p>
                {app?.fairLanguage?.save_to_calendar
                  ? app?.fairLanguage?.save_to_calendar
                  : 'Save To Calendar'}
              </p>
            </div>
            <hr />
            <div className="calenderBtns">
              <button
                onClick={() =>
                  window.open(fairObj?.icsForGoogle, '_blank', 'noreferrer')
                }
              >
                {GetLog('Google')}{' '}
                {app?.fairLanguage?.google_calendar
                  ? app?.fairLanguage?.google_calendar
                  : 'Google Calendar'}
              </button>

              {!isMobileOnly &&
                <button
                onClick={() =>
                  window.open(fairObj?.office365URL, '_blank', 'noreferrer')
                }
              >
              {GetLog('Office365')}{' '}
              {app?.fairLanguage?.office_365
                ? app?.fairLanguage?.office_365
                : 'Office 365'}
                </button>
              }

            </div>
            </>
          }
          </div>
        </>
      ) : (
        <>
          <div className="contentWrapper">
            <h4>
              Congratulations you are successfully registered with this event
            </h4>
            <p>
              We have also sent you a confirmation email, please add to your
              personal calendar to ensure you remember to attend this exclusive
              event. Please read the help section to avoid any issues during the
              fair. If you have not received a confirmation e-mail within 30
              minutes please check in your spam/junk or e-mail us at
              <b> support@virtualrecruitmentdays.com</b>
            </p>
            <p>We wish you a successful event</p>
          </div>
          <div>
            <span>
              <b>
                {app?.appLayout?.fair.start_time} To{' '}
                {app?.appLayout?.fair.end_time}
              </b>
              <br />
            </span>
            {app?.appLayout?.fair?.options?.disable_event_save_to_calendar === 0 &&
             <>
            <div className="saveToCalendarText">
              <p>
                {app?.fairLanguage?.save_to_calendar
                  ? app?.fairLanguage?.save_to_calendar
                  : 'Save To Calendar'}
              </p>
            </div>
            <hr />
            <div className="calenderBtns">
              <button
                onClick={() =>
                  window.open(fairObj?.icsForGoogle, '_blank', 'noreferrer')
                }
              >
                {GetLog('Google')}{' '}
                {app?.fairLanguage?.google_calendar
                  ? app?.fairLanguage?.google_calendar
                  : 'Google Calendar'}
              </button>

              {!isMobileOnly &&
                <button
                onClick={() =>
                  window.open(fairObj?.office365URL, '_blank', 'noreferrer')
                }
              >
              {GetLog('Office365')}{' '}
              {app?.fairLanguage?.office_365
                ? app?.fairLanguage?.office_365
                : 'Office 365'}
                </button>
              }

            </div>
            </>
          }
            
            
          </div>
        </>
      )}
    </Modal>
  )
}

export default WelcomeRegistrationModal
