import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Avatar from 'react-avatar'
import { Layout, Menu } from 'antd'
import { instanceWithOutAuth } from 'App'
import { setStylingLayout, setMobileBtnColor } from 'common/common'
import ExitServayModal from 'modals/exitServay/ExitServayModal'
import GetLogo from '../../assets/getLogo/getLog'
import Timer from 'common/Timer'
import ToolTip from './ToolTip'
import {
  setFairName,
  changeAuthDraw,
  addUser,
  setHallId,
  toggleSidebarActiveTab,
  setSidebarActiveTab,
  getHallLangApi,
  resetPreviousFairDetails,
  setSwitchFair,
} from 'redux/app/appSlice'
import IsFairLive from 'common/IsFairLive'
import { CornerDownLeft } from 'react-feather'
import './sidebar.scss'
import WebinarNotification from 'components/webinarNotification/WebinarNotification'
import useFetchToken from "customHooks/useFetchToken";
import { useIsAuthenticated } from '@azure/msal-react'

const SideBar = ({ collapsedState }) => {
  const { app } = useSelector((state) => state)
  const dispatch = useDispatch()
  let fairName = app?.appLayout?.fair?.name
  const history = useHistory()
  const [exitServayModal, setExitServayModal] = useState(false)
  const [isTiming, setIsTiming] = useState(false)
  const [changeImg, setChangeImg] = useState(false)
  const [sideBarList, setSideBarList] = useState([])
  const {getToken} = useFetchToken()
  const isAuthenticated = useIsAuthenticated()
  const appRegion = app?.appLayout?.fair?.fairTiming?.app_region
  let fairSideBarOptions = app?.appLayout?.fair?.front_left_menu
  let WebUrl = window.location.href.split('/')[4]

  if (fairName) {
    document.title = `Home | ${fairName}`
  }
  const goToMainHall = (hallId, hallUrl) => {
    dispatch(setHallId(hallId))
    history.push(hallUrl)
  }
  const findOptionOrder = (optionName) => {
    let option = fairSideBarOptions?.find((option) => option.key === optionName)
    return option?.order
  }

  const retainToggleExhibitorsSideMenu = () => {
    if (typeof(Storage) !== "undefined") {
      const eSideMenu = localStorage.getItem("toggleExhibitorsSideMenu");
      if(!eSideMenu){
        localStorage.setItem("toggleExhibitorsSideMenu", 1);
      }else if(eSideMenu == 0){
        // Store
        localStorage.setItem("toggleExhibitorsSideMenu", 1);
      }else{
        localStorage.setItem("toggleExhibitorsSideMenu", 0);
      }
      
     
    }
  }

  const handleExitSurveyModal = () => {
    dispatch(setSidebarActiveTab('exit-survey'))
    if (!app?.appLayout?.isTakeSurvey) {
      setExitServayModal(true)
      dispatch(toggleSidebarActiveTab(true))
    } else if (app?.appLayout?.isTakeSurvey) {
      setExitServayModal(true)
      dispatch(toggleSidebarActiveTab(true))
    }
  }
  const checkVisbileState = (isLogin, fairOption) => {
    if (!isLogin && fairOption == 1) {
      return false
    }
    return true
  }
  const handleBackToPreviousFair = async () => {
    const userData = {
      fair_info: 'Y',
    }
    try {
      const bearer = `Bearer ${await getToken()}`;
      const res = await instanceWithOutAuth.post(
        '/attendee/return/to/previous/fair',
        { ...userData },
        {
          headers: {
            Authorization: bearer,
            'requested-lang': app?.langId,
          },
        }
      )
      if (res.status === 200) {
        dispatch(setSwitchFair(false))
        dispatch(resetPreviousFairDetails())
        let translations =
          res.data?.data?.user?.fair_info?.data?.fair?.fair_translations
        let localLang = JSON.parse(localStorage.getItem('lang'))
        let defaultLanguage
        let languageId = res.data?.data?.user?.fair_info?.data?.fair
          ?.default_language
          ? res.data?.data?.user?.fair_info?.data?.fair?.default_language
              ?.auto_id
          : 0
        if (translations && localLang) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) => +filterLanguage.code === +localLang?.value
            )
          defaultLanguage = filterLanguages[0].keywords
        }
        if (translations && !localLang) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) => +filterLanguage.code === +languageId
            )
          defaultLanguage = filterLanguages[0].keywords
        }
        let userData = {
          ...res.data?.data?.user,
          fair_info: res.data?.data?.user?.fair_info?.data,
          authToken: res?.data?.data?.access_token,
          defaultLanguage: defaultLanguage,
        }
        dispatch(addUser(userData))
        localStorage.setItem(
          'fairTranslation',
          JSON.stringify(
            res.data?.data?.user?.fair_info?.data?.fair?.fair_translations
          )
        )
        let data = {
          fair_id: app?.appLayout?.fair?.id,
          authToken: res?.data?.data?.access_token,
          langId: app.langId,
        }
        let previosFairDetail = {
          previousFairId: res.data?.data?.coming_from,
          previousFairName: res.data?.data?.fair?.name,
        }
        dispatch(getHallLangApi(data))

        setStylingLayout(res.data?.data?.user?.fair_info?.data?.fair?.options)
        if (
          res.data?.data?.user?.fair_info?.data?.fair?.options
            ?.mobile_color_option == 1
        ) {
          setMobileBtnColor(
            res.data?.data?.user?.fair_info?.data?.fair?.options
          )
        }
        dispatch(changeAuthDraw(false))
        localStorage.setItem(
          'theme',
          JSON.stringify(res.data?.data?.user?.fair_info?.data)
        )
        localStorage.setItem('isLogin', true)
        dispatch(setFairName(res.data?.data?.fair?.short_name))

        history.push(`/${res.data?.data?.fair?.short_name}/main-hall`)
      }
    } catch (error) {}
  }

  useEffect(() => {
    dispatch(toggleSidebarActiveTab(false))
    dispatch(setSidebarActiveTab(WebUrl))
  }, [WebUrl])
  useEffect(() => {
    if (isAuthenticated && app?.appLayout?.fair?.id && app?.user?.authToken) {
      let data = {
        fair_id: app?.appLayout?.fair?.id,
        authToken: app?.user?.authToken,
        langId: app?.langId,
      }

      dispatch(getHallLangApi(data))
    }
  }, [app?.langId])
  useEffect(() => {
    dispatch(toggleSidebarActiveTab(false))
    let sideBarOptions = []
    let fairLayout = app?.appLayout?.fair?.layout
    let fairTiming = app?.appLayout?.fair?.fairTiming
    let fairOptions = app?.appLayout?.fair?.options
    let isFairLive =
      fairTiming &&
      IsFairLive(
        app?.appLayout?.fair?.fairTiming?.date,
        app?.appLayout?.fair?.fairTiming?.fair_start,
        app?.appLayout?.fair?.fairTiming?.fair_end,
        app?.appLayout?.fair?.dry_run
      )
    if (
      app?.appLayout?.fair?.short_name &&
      fairOptions &&
      app?.appLayout?.isTakeTest
    ) {
      if (fairLayout !== 'layout9')
        sideBarOptions.push({
          id: 1,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/home`,
          text: app?.fairLanguage?.home,
          icon: GetLogo('Home'),
          // icon: <Home size={18} />,
          isChild: null,
          isButton: false,
          order: findOptionOrder('home'),
        })
      if (fairOptions?.enable_custom_homepage == 1) {
        sideBarOptions.push({
          id: 20,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/landing`,
          text: app?.fairLanguage?.landing
            ? app?.fairLanguage?.landing
            : 'Landing',
          icon: GetLogo('Landing'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('landing'),
        })
      }
      if (isAuthenticated && isFairLive && fairOptions.enable_reception_tab_front)
        sideBarOptions.push({
          id: 2,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/reception`,
          text: app?.fairLanguage?.reception,
          icon: GetLogo('Reception'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('reception'),
        })
      if (
        isAuthenticated &&
        isFairLive &&
        app?.getHallLang?.hallsData?.length > 1 &&
        !fairOptions?.disable_exhibitors_hall
      ) {
        if (!fairOptions.enable_exhibitors_halls_in_main)
          sideBarOptions.push({
            id: 3,
            isBadge: null,
            text: app?.fairLanguage?.exhibitors_hall,
            icon: GetLogo('Exhibitorshall'),
            isButton: false,
            isChild:
              app?.getHallLang?.hallsData &&
              app?.getHallLang?.hallsData?.map((hall, ind) => {
                return {
                  id: hall?.hall_id,
                  url:
                    fairLayout == 'layout1_prime'
                      ? `/${app?.appLayout?.fair?.short_name}/reception`
                      : `/${app?.appLayout?.fair?.short_name}/main-hall`,
                  text: `${hall?.hall_name}`,
                  key: ind,
                }
              }),
            order: findOptionOrder('exhibitors_hall'),
          })
        else
          sideBarOptions.push({
            id: 3,
            isBadge: null,
            url: null,
            text: app?.fairLanguage?.exhibitors_hall,
            icon: GetLogo('Exhibitorshall'),
            isChild: null,
            isButton: true,
            order: findOptionOrder('exhibitors_hall'),
            clickHandler: () =>
              goToMainHall(
                app?.getHallLang?.hallsData &&
                  app?.getHallLang?.hallsData?.map((hall, ind) => {
                    return hall?.hall_id
                  }),
                fairLayout == 'layout1_prime'
                  ? `/${app?.appLayout?.fair?.short_name}/reception`
                  : `/${app?.appLayout?.fair?.short_name}/main-hall`
              ),
          })
      }

      if (
        isAuthenticated &&
        isFairLive &&
        app?.getHallLang?.hallsData?.length === 1 &&
        !fairOptions?.disable_exhibitors_hall
      )
        sideBarOptions.push({
          id: 4,
          isBadge: null,
          url: null,
          text: app?.fairLanguage?.exhibitors_hall,
          icon: GetLogo('Exhibitorshall'),
          isChild: null,
          isButton: true,
          order: findOptionOrder('exhibitors_hall'),
          clickHandler: () =>
            goToMainHall(
              app?.getHallLang?.hallsData &&
                app?.getHallLang?.hallsData?.map((hall, ind) => {
                  return hall?.hall_id
                }),
              fairLayout == 'layout1_prime'
                ? `/${app?.appLayout?.fair?.short_name}/reception`
                : `/${app?.appLayout?.fair?.short_name}/main-hall`
            ),
        })
      if (
        (isAuthenticated && !fairOptions?.disable_dashboard && isFairLive) ||
        (isAuthenticated &&
          !fairOptions?.disable_dashboard &&
          !isFairLive &&
          fairOptions?.enable_dashboard_front)
      )
        fairOptions?.disable_dashboard == 0 &&
          sideBarOptions.push({
            id: 5,
            isBadge: null,
            url: `/${app?.appLayout?.fair?.short_name}/dashboard`,
            text: app?.fairLanguage?.dashboard,
            icon: GetLogo('Dashboard'),
            isChild: null,
            isButton: false,
            order: findOptionOrder('dashboard'),
          })

      if (
        !fairOptions?.hide_conference_agedna_front &&
        checkVisbileState(
          isAuthenticated,
          fairOptions?.disable_conference_agedna_front_if_login
        )
      ) {
        sideBarOptions.push({
          id: 7,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/conferences/agenda/list`,
          text: app?.fairLanguage?.conference_agenda,
          icon: GetLogo('ConferencAgenda'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('conference_agenda'),
        })
      }

      if (
        fairOptions?.enable_stage_conference_agenda &&
        checkVisbileState(
          isAuthenticated,
          fairOptions?.disable_stage_conference_agenda_if_login
        )
      ) {
        sideBarOptions.push({
          id: 19,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/stage/conference/agenda/list`,
          text: app?.fairLanguage?.stage_conference_agenda,
          icon: GetLogo('StageConferenceAgenda'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('stage_conference_agenda'),
        })
      }

      if (
        fairOptions?.enable_speakers_agenda &&
        checkVisbileState(
          isAuthenticated,
          fairOptions?.enable_speakers_agenda_if_login
        )
      ) {
        sideBarOptions.push({
          id: 23,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/s/agenda/list`,
          text: app?.fairLanguage?.speakers_agenda,
          icon: GetLogo('SpeakersAgenda'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('speakers_agenda'),
        })
      }

      if (isAuthenticated && app?.previousFairId)
        sideBarOptions.push({
          id: 21,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/career/test`,
          text: `Return to ${app?.previousFairName} fair`,
          icon: <CornerDownLeft size={18} />,
          isChild: null,
          isButton: true,
          order: findOptionOrder('back_to_fair'),
          clickHandler: handleBackToPreviousFair,
        })
      if (
        isFairLive &&
        isAuthenticated &&
        fairOptions?.enable_personality_test_sidebar == '1'
      )
        sideBarOptions.push({
          id: 22,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/PersonalityTest`,
          text: app?.fairLanguage?.personality_test,
          icon: GetLogo('PersonalityTest'),
          isChild: null,
          order: findOptionOrder('personality_test'),
        })

      if (fairOptions?.hide_speakers_front === 0) {
        sideBarOptions.push({
          id: 8,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/speakers`,
          text: app?.fairLanguage?.speakers,
          icon: GetLogo('Speakers'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('speakers'),
        })
      }

      if (
        !fairOptions?.hide_company_menu_front &&
        checkVisbileState(
          isAuthenticated,
          fairOptions?.hide_company_menu_front_if_login
        )
      ) {
        sideBarOptions.push({
          id: 9,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/partners`,
          text: app?.fairLanguage?.companies,
          icon: GetLogo('Companies'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('companies'),
        })
      }
      if (fairOptions?.enable_recruiters_tab && isAuthenticated) {
        sideBarOptions.push({
          id: 4,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/recruiter/list`,
          text: app?.fairLanguage?.recruiters_sidebar
            ? app?.fairLanguage?.recruiters_sidebar
            : 'Recruiters',
          icon: GetLogo('Recruiters'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('recruiters'),
        })
      }

      if (!fairOptions?.disable_about_sidebar_menu_front)
        sideBarOptions.push({
          id: 6,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/about`,
          text: app?.fairLanguage?.about,
          icon: GetLogo('AboutUs'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('about_us'),
        })
      if (
        fairOptions?.enable_company_locatin &&
        !fairOptions?.disable_companies_maps_front
      ) {
        sideBarOptions.push({
          id: 10,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/companies/map`,
          text: app?.fairLanguage?.companies_maps
            ? app?.fairLanguage?.companies_maps
            : 'Companies Map',
          icon: GetLogo('CompaniesMap'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('companies_maps'),
        })
      }
      if (fairOptions?.enable_scoreboard_front &&
          checkVisbileState(
              app?.isLogin,
              fairOptions?.disable_scoreboard_front_if_login
          )
      ) {
        sideBarOptions.push({
          id: 20,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/score/board`,
          text: app?.fairLanguage?.scoreboard,
          icon: GetLogo('Scoreboard'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('scoreboard'),
        })
      }

      if (
        isAuthenticated &&
        isFairLive &&
        fairOptions?.enable_candidate_networking
      ) {
        sideBarOptions.push({
          id: 11,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/network/candidates`,
          text: app?.fairLanguage?.candidate_networking
            ? app?.fairLanguage?.candidate_networking
            : 'Candidates Network',
          icon: GetLogo('CandidatesNetwork'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('candidate_networking'),
        })
      }
      if (isAuthenticated && fairOptions?.enable_virtual_networking_tables) {
        sideBarOptions.push({
          id: 12,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/networks/tables`,
          text: app?.fairLanguage?.networking_tables
            ? app?.fairLanguage?.networking_tables
            : 'Networking Table',
          icon: GetLogo('NetworkingTables'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('networking_tables'),
        })
      }
      if (
        !fairOptions?.hide_jobs_front &&
        checkVisbileState(
          isAuthenticated,
          fairOptions?.disable_jobs_front_if_login
        )
      ) {
        sideBarOptions.push({
          id: 13,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/jobs`,
          text: app?.fairLanguage?.jobs_sidebar
            ? app?.fairLanguage?.jobs_sidebar
            : app?.fairLanguage?.fair_meta_jobs,
          icon: GetLogo('Jobs'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('jobs'),
        })
      }
      if (
        // (!isFairLive && fairOptions?.enable_courses) ||
        !fairOptions?.disable_courses_front &&
        fairOptions.enable_courses &&
        checkVisbileState(
          isAuthenticated,
          fairOptions?.disable_courses_front_if_login
        )
      ) {
        sideBarOptions.push({
          id: 14,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/courses`,
          text: app?.fairLanguage?.courses_sidebar
            ? app?.fairLanguage?.courses_sidebar
            : app?.fairLanguage?.courses,
          icon: GetLogo('Courses'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('courses'),
        })
      }
      if (isAuthenticated && isFairLive && +app?.appLayout?.fair?.goodies === 1) {
        sideBarOptions.push({
          id: 15,
          isBadge: +app?.appLayout?.fairCandidateGoodiesCount,
          url: `/${app?.appLayout?.fair?.short_name}/goodies`,
          text: app?.fairLanguage?.goodie_bag
            ? app?.fairLanguage?.goodie_bag
            : `${app?.fairLanguage?.goodie} ${app?.fairLanguage?.bag}`,
          icon: GetLogo('Goodies'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('goodies'),
        })
      }
      if (fairOptions?.disable_sidebar_exit_survey == 0) {
        if (isAuthenticated && isFairLive && fairOptions?.survey_enable === 1) {
          sideBarOptions.push({
            id: 16,
            isBadge: null,
            url: `/${app?.appLayout?.fair?.short_name}/exit-survey`,
            text:
              fairOptions?.enable_edit_candidate_exit_survey_front === 1 &&
              app?.appLayout?.isTakeSurvey
                ? app?.fairLanguage?.edit_exit_survey
                : app?.fairLanguage?.exit_survey
                ? app?.fairLanguage?.exit_survey
                : 'Exist Survey',
            icon: GetLogo('ExistSurvey'),
            isChild: null,
            isButton: true,
            order: findOptionOrder('exit_survey'),
            clickHandler: () => handleExitSurveyModal(),
          })
        }
      }
      if (!fairOptions?.disable_need_help)
        sideBarOptions.push({
          id: 17,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/help`,
          text: app?.fairLanguage?.need_help,
          icon: GetLogo('Help'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('need_help'),
        })
      if (
        !fairOptions?.disable_faqs &&
        checkVisbileState(isAuthenticated, fairOptions?.disable_faqs_if_login)
      ) {
        sideBarOptions.push({
          id: 18,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/faqs`,
          text: app?.fairLanguage?.faq ? app?.fairLanguage?.faq : "FAQ's",
          icon: GetLogo('FAQ'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('faq'),
        })
      }
      if (
        fairOptions?.enable_secondary_recruiters_tab_front == 1 &&
        isAuthenticated
      ) {
        sideBarOptions.push({
          id: 19,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/secondaryrecruiter/list`,
          text: app?.fairLanguage?.secondary_recruiters
            ? app?.fairLanguage?.secondary_recruiters
            : 'Secondary Recruiters',
          icon: GetLogo('Recruiters'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('secondary_recruiters'),
        })
      }
    } else {
      if (isAuthenticated && fairLayout !== 'layout9')
        sideBarOptions.push({
          id: 1,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/home`,
          text: app?.fairLanguage?.home,
          icon: GetLogo('Home'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('home'),
        })
      if (isAuthenticated)
        sideBarOptions.push({
          id: 2,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/career/test`,
          text: app?.fairLanguage?.fair_meta_careertest,
          icon: GetLogo('CareerTest'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('careertest'),
        })
      if (isAuthenticated)
        sideBarOptions.push({
          id: 3,
          isBadge: null,
          text: '',
          isChild: null,
          isButton: false,
        })
      if (isAuthenticated)
        sideBarOptions.push({
          id: 4,
          isBadge: null,
          text: '',
          isChild: null,
          isButton: false,
        })
      if (!fairOptions?.disable_about_sidebar_menu_front)
        sideBarOptions.push({
          id: 6,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/about`,
          text: app?.fairLanguage?.about,
          icon: GetLogo('AboutUs'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('about'),
        })
      if (!fairOptions?.disable_need_help)
        sideBarOptions.push({
          id: 17,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/help`,
          text: app?.fairLanguage?.need_help,
          icon: GetLogo('Help'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('need_help'),
        })
      if (!fairOptions?.disable_faqs) {
        sideBarOptions.push({
          id: 18,
          isBadge: null,
          url: `/${app?.appLayout?.fair?.short_name}/faqs`,
          text: app?.fairLanguage?.faq ? app?.fairLanguage?.faq : "FAQ's",
          icon: GetLogo('FAQ'),
          isChild: null,
          isButton: false,
          order: findOptionOrder('faq'),
        })
      }
      
    }

    if(app?.appLayout?.fair?.id === 335){
      sideBarOptions.push({
        id: 18,
        isBadge: null,
        url: `/${app?.appLayout?.fair?.short_name}/virtual/room`,
        text: 'Virtual Room',
        icon: GetLogo('Metaverse'),
        isChild: null,
        isButton: false,
        order: 30,
      })
    }

    if (app?.appLayout?.fair?.short_name) {
      let sortedSideBarOption = sideBarOptions?.sort((a, b) => {
        return a.order - b.order
      })
      setSideBarList(sortedSideBarOption)
      let currentDate = new Date()
      let startTime = new Date(app?.appLayout?.fair?.fairTiming?.fair_start)
      if (currentDate <= startTime) {
        setIsTiming(true)
      } else {
        setIsTiming(false)
      }
    }
    if (app?.appLayout?.fair?.options) {
      setStylingLayout(app?.appLayout?.fair?.options)
    }
    if (app?.appLayout?.fair?.options?.mobile_color_option == 1) {
      setMobileBtnColor(app?.appLayout?.fair?.options)
    }
  }, [
    app.appLayout,
    app.fairLanguage,
    app?.langId,
    isAuthenticated,
    app?.getHallLang?.hallsData,
  ])

  let fIcon = `${process.env.REACT_APP_CDN_URL}//comon/favicon.png`

  if (app?.appLayout?.fair?.mob_fav_icon) {
    fIcon = `${process.env.REACT_APP_ASSETS_URL}/${app?.appLayout?.fair?.mob_fav_icon}`
  }

  let exhibitorSideMenu = localStorage.getItem("toggleExhibitorsSideMenu");

  return (
    <Fragment>
      <Layout.Sider
        className="SideBar"
        width={240}
        style={{
          fontSize: `${app?.appLayout?.fair?.options?.fontSize_option}px`,
          fontWeight: app?.appLayout?.fair?.options?.fontWeight_option,
        }}
        trigger={null}
        collapsible
        collapsed={collapsedState}
      >
        {!collapsedState ? (
          <div className="logo text-md-center">
            <Link
              to={`/${
                app?.fairName ? app?.fairName : app?.appLayout?.fair?.short_name
              }/home`}
            >
              {!collapsedState && app.appLayout?.fair?.name}
            </Link>
          </div>
        ) : (
          <div className="MobileLogo text-md-center">
            <Link
              to={`/${
                app?.fairName ? app?.fairName : app?.appLayout?.fair?.short_name
              }/home`}
            >
              <img src={fIcon} alt="Mobile Logo" />
            </Link>
          </div>
        )}
        {!collapsedState && (
          <div className="ImgDiv DBlock h-auto">
            {collapsedState ? (
              <Avatar size="50" name={app.appLayout?.fair?.name} />
            ) : (
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}/${app.appLayout?.fair?.fair_image}`}
                alt={app?.fairName}
              />
            )}
          </div>
        )}
        {isTiming && !collapsedState ? (
          <div className="ms-md-1 TimingDiv DBlock h-auto">
            <Timer date={app.appLayout?.fair?.fairTiming?.fair_start} />
          </div>
        ) : null}
        <>
          <Menu
            className={`${collapsedState ? 'collapsed' : ''} ${
              isTiming ? 'TimingClass' : ''
            } leftSideBar`}
            mode="inline"
            defaultSelectedKeys={['1']}
            onOpenChange={()=> retainToggleExhibitorsSideMenu()}
            defaultOpenKeys={exhibitorSideMenu == 1 ? ['SubMenuItemKey'] : [] }
          >
            {sideBarList?.map((menu, index) =>
              menu?.isChild && menu?.isChild.length > 1 ? (
                <Menu.SubMenu
                  key={`SubMenuItemKey`}
                  icon={menu?.icon}
                  style={{
                    fontSize: `${app.appLayout?.fair?.options?.fontSize_option}px`,
                    fontWeight:
                      app?.appLayout?.fair?.options?.fontWeight_option,
                  }}
                  title={menu?.text}
                >
                  {menu.isChild.map((subMenu, ind) => (
                    <Menu.Item
                      className={`${
                        collapsedState ? 'navCollapsed' : 'navItem'
                      }`}
                      key={`SubInside${subMenu?.id}To${ind}`}
                      style={{
                        fontSize: `${app.appLayout?.fair?.options?.fontSize_option}px`,
                        fontWeight:
                          app?.appLayout?.fair?.options?.fontWeight_option,
                      }}
                    >
                      <button
                        className={`${
                          collapsedState ? 'HallCollBtn' : 'HallBtn'
                        }`}
                        type="button"
                        onClick={() => goToMainHall(subMenu?.id, subMenu?.url)}
                      >
                        {subMenu?.text}
                      </button>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <>
                  <Menu.Item
                    key={`MenuItemKey${index}`}
                    className={`${
                      collapsedState ? 'navCollapsed' : 'navItem'
                    } ${
                      menu.url &&
                      menu.url.split('/')[2] === app?.sidebarActiveTab
                        ? 'activeTab'
                        : ''
                    }`}
                    style={{
                      whiteSpace: 'normal',
                      height: 'auto',
                      padding: '8px',
                      fontSize: `${app.appLayout?.fair?.options?.fontSize_option}px`,
                      fontWeight:
                        app?.appLayout?.fair?.options?.fontWeight_option,
                    }}
                    icon={menu?.icon}
                  >
                    {menu?.isButton ? (
                      <button
                        type="button"
                        className="bg-transparent border-0"
                        onClick={menu?.clickHandler}
                      >
                        {menu?.text}
                      </button>
                    ) : (
                      <>
                        <Link
                          to={menu?.url}
                          className="DFlex"
                          style={{
                            fontSize: `${app.appLayout?.fair?.options?.fontSize_option}px`,
                            fontWeight:
                              app?.appLayout?.fair?.options?.fontWeight_option,
                          }}
                        >
                          {menu?.text}
                          {menu?.isBadge !== null &&
                            menu?.isBadge !== undefined && (
                              <span className="ant-badge ant-badge-not-a-wrapper badgeSideNav">
                                <sup
                                  data-show="true"
                                  className="ant-scroll-number ant-badge-count"
                                  title={menu?.isBadge}
                                >
                                  <span className="ant-scroll-number-only">
                                    <span className="ant-scroll-number-only-unit current">
                                      {menu?.isBadge}
                                    </span>
                                  </span>
                                </sup>
                              </span>
                            )}
                        </Link>
                      </>
                    )}
                  </Menu.Item>
                  {menu?.id === 2 &&
                    !collapsedState &&
                    !app?.appLayout?.isTakeTest && <ToolTip />}
                </>
              )
            )}

            {app?.appLayout?.fair?.id == '275' && (
              <div
                className="vcdIcon"
                onMouseEnter={() => setChangeImg(true)}
                onMouseLeave={() => setChangeImg(false)}
              >
                {collapsedState ? (
                  <img
                    src={
                      changeImg
                        ? `${process.env.REACT_APP_CDN_URL}/comon/sidebarvcdlhover.png`
                        : `${process.env.REACT_APP_CDN_URL}/comon/sidebarvcdl.png`
                    }
                    alt={app?.fairName}
                  />
                ) : (
                  <a href="https://www.virtualcareerdays.se/" target="_blank">
                    <img
                      src={
                        changeImg
                          ? `${process.env.REACT_APP_CDN_URL}/comon/sidebarvcdlhover.png`
                          : `${process.env.REACT_APP_CDN_URL}/comon/sidebarvcdl.png`
                      }
                      alt={app?.fairName}
                    />
                  </a>
                )}
              </div>
            )}
          </Menu>
        </>
        <WebinarNotification></WebinarNotification>
        {appRegion == 'eu' && (
          <div className="lanseraImgDiv">
            <a href="https://www.virtualdays.com" target="_blank">
              {app.appLayout?.fair?.options?.sidebar_background_color ==
                '#f7f6f7' ||
              app.appLayout?.fair?.options?.sidebar_background_color ==
                '#ffffff' ? (
                <img
                  alt="logo"
                  src={`${process.env.REACT_APP_CDN_URL}/comon/vdlogoblack.png`}
                ></img>
              ) : (
                <img
                  alt="logo"
                  src={`${process.env.REACT_APP_CDN_URL}/comon/vdlogo.png`}
                ></img>
              )}
            </a>
          </div>
        )}
        {/* ) : (
          <Link to={menu?.url} className='DFlex'></Link>
        )} */}
      </Layout.Sider>
      {exitServayModal && (
        <ExitServayModal
          exitServayModal={exitServayModal}
          setExitServayModal={() => setExitServayModal(!exitServayModal)}
        />
      )}
    </Fragment>
  )
}

export default SideBar
