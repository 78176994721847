import { toast } from "react-toastify";

export const ReactTostify = (type, text) => {
  switch (type) {
    case "error":
      toast.error(text);
      toast.clearWaitingQueue();
      break;
    case "success":
      toast.success(text);
      toast.clearWaitingQueue();
      break;
    case "warning":
      toast.warning(text);
      toast.clearWaitingQueue();
      break;
    case "info":
      toast.info(text);
      toast.clearWaitingQueue();
      break;
    default:
      toast(text);
      toast.clearWaitingQueue();
  }
};
