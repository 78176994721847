import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Form,
  Input,
  Checkbox,
  Button,
  Spin,
  Col,
  Row,
  Divider,
  Alert,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { instanceWithAuth } from "App";
import axios from "axios";
import AppRecaptcha from "common/recaptcha/AppRecaptcha";
import ExtraFields from "components/extrafields/ExtraFields.jsx";
import CvUpload from "components/cvupload/CvUpload.jsx";
import { ReactTostify } from "components/reactToastify/ReactTostify";
import PrivacyModal from "modals/privacyModal/PrivacyModal";
import TermsConditionModal from "modals/termsModal/TermsConditionModal";
import { toggleWelcomeModal } from "redux/app/appSlice";
import { setStylingLayout } from "common/common";
import { setMobileBtnColor } from "common/common";
import { addUser, changeAuthDraw, handleLoginBtn } from "redux/app/appSlice";
import { handleResetCurrentSelectedData } from "redux/dashboard/dashboardSlice";
import IsFairLive from "common/IsFairLive";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "./registerForm.scss";
import WelcomeRegistrationModal from "modals/welcomeRegistration/WelcomeRegistrationModal";

const RegisterForm = () => {
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isNotRobot, setIsNotRobot] = useState(false);
  const [cv, setCv] = useState(null);
  const [isErrorRobot, setIsErrorRobot] = useState(false);
  const [distoryRecatpcha, setDistoryRecatpcha] = useState(false);
  const [privacyViewModal, setPrivacyViewModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [isPhoneError, setIsPhoneError] = useState();
  const [extraData, setExtraData] = useState([]);
  const [userTimezone, setUserTimezone] = useState([]);
  const [termsCondtionViewModal, setTermsCondtionViewModal] = useState(false);
  const [isCvError, setIsCvError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  let fairSettingOptions =
    app?.appLayout?.fair?.options && app?.appLayout?.fair?.options;

  let isFairLive =
    app?.appLayout?.fair?.fairTiming &&
    IsFairLive(
      app?.appLayout?.fair?.fairTiming?.date,
      app?.appLayout?.fair?.fairTiming?.fair_start,
      app?.appLayout?.fair?.fairTiming?.fair_end,
      app?.appLayout?.fair?.dry_run
    )
  const onFinish = (values) => {
    // getRegistrationData(values);
    let channel = "";
    if (sessionStorage.getItem("channel")) {
      channel = sessionStorage.getItem("channel");
    }

    if (cv) {
      setIsCvError({ isError: false, errorMessage: "" });
      values.cv = cv;
    }
    if (!fairSettingOptions?.field_phone_number) getRegistrationData(values);
    else if (fairSettingOptions?.field_phone_number)
      getRegistrationData(values);
    else setIsPhoneError(true);
  };
  // const onFinish = (values) => {
  //   if (isNotRobot) {
  //     getRegistrationData(values);
  //     setIsErrorRobot(false);
  //   } else {
  //     setIsErrorRobot(true);
  //   }
  // };

  const checkRobot = (robotVal) => {
    if (robotVal) {
      setIsErrorRobot(false);
      setIsNotRobot(robotVal);
    } else {
      setIsErrorRobot(false);
    }
  };

  const handlePrivacyViewModal = () => {
    setPrivacyViewModal(true);
  };

  const handleTermsViewModal = () => {
    setTermsCondtionViewModal(true);
  };

  const handlePrivacyHideModal = () => {
    setPrivacyViewModal(!privacyViewModal);
  };

  const handleTermsHideModal = () => {
    setTermsCondtionViewModal(!termsCondtionViewModal);
  };

  const hasExtension = (docFile, exts) => {
    let fileName = docFile?.name;
    return new RegExp("(" + exts.join("|").replace(/\./g, "\\.") + ")$").test(
      fileName
    );
  };

  const ipapiFun = async (apiData) => {
    try {
      const systemInfo = await axios.get(
        "https://ipapi.co/json/?key=ulRK9gwyboDOcoUqctHtM7AMTDiF9kNhetQD3GDTyrPmLQarS9",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (systemInfo.status === 200) {
        let data = systemInfo?.data;
        let ipInfo = {
          countryName: data?.country_name,
          cityName: data?.city,
          postalCode: data?.postal,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        setUserTimezone({ ...userTimezone, ...ipInfo });
      }
    } catch (error) {
      let ipInfo = {
        countryName: "anonymous",
        cityName: "anonymous",
        postalCode: "anonymous",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      setUserTimezone({ ...userTimezone, ...ipInfo });
    }
  };
  const extraFieldsData = async (apiData) => {
    try {
      const response = await instanceWithAuth.post(
        `/fair/fairextrafields`,
        apiData,
        {
          headers: {
            "Content-Type": "application/json",
            "requested-lang": app?.langId,
          },
        }
      );
      // if (response.status === 503) {
      //   history.push(`/${app?.appLayout?.fair?.short_name}/503`);
      // }
      if (response.status === 200) {
        setExtraData(response?.data?.data?.extra_fields);
      }
    } catch (error) {
      ReactTostify(
        "error",
        error?.response?.data?.msg
          ? error?.response?.data?.msg
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  };

  const getRegistrationData = async (values) => {
    setIsPhoneError(true);
    if (+app?.appLayout?.fair?.fair_setting?.cv_required === 1 && !cv) {
      setIsCvError({
        isError: true,
        errorMessage: isCvError?.errorMessage
          ? isCvError?.errorMessage
          : "CV is required!",
      });
    } else {
      const fairData =
        localStorage.getItem("fairData") &&
        JSON.parse(localStorage.getItem("fairData"));
      const role = "User";
      let channel = "";
      if (sessionStorage.getItem("channel")) {
        channel = sessionStorage.getItem("channel");
      }
      let name =
        fairSettingOptions?.field_first_last_name === 1
          ? values?.firstName && values?.lastName
          : values?.name;
      if (
        name &&
        values?.email &&
        values?.password &&
        app?.appLayout?.fair?.id
      ) {
        setIsLoading(true);
        const formData = new FormData();
        let channel = "";
        if (sessionStorage.getItem("channel")) {
          channel = sessionStorage.getItem("channel");
        }
        channel && formData.append("channel", channel);
        formData.append("fair_id", app?.appLayout?.fair?.id);
        formData.append("fair_info", "Y");
        formData.append(
          "name",
          fairSettingOptions?.field_first_last_name === 1
            ? `${values?.firstName} ${values?.lastName}`
            : values?.name
        );
        formData.append(
          "first_name",
          values?.firstName ? values?.firstName : ""
        );
        formData.append("last_name", values?.lastName ? values?.lastName : "");
        formData.append("email", values?.email);
        formData.append("toc_checked", isChecked);
        !fairSettingOptions?.field_phone_number &&
          formData.append("phone", phoneNumber);
        formData.append("password", values?.password);
        !fairSettingOptions?.field_company_name &&
          formData.append("user_company", values?.user_company);
        !fairSettingOptions?.field_job_title &&
          formData.append("user_job_title", values?.user_job_title);
        extraData?.length > 0 &&
          extraData?.map((field) => {
            for (const key in values) {
              if (+field?.auto_id === +key) {
                formData.append(
                  `extra_fields[${field?.auto_id}]`,
                  values[key] ? values[key] : ""
                );
              }
            }
          });
        formData.append("user_country", fairData?.userCountry);
        formData.append("user_city", fairData?.userCity);
        formData.append(
          "user_postal_code",
          fairData?.userPostalCode ? fairData?.userPostalCode : "0"
        );
        formData.append("ip", fairData?.ip);
        formData.append("location", fairData?.location);
        formData.append("browser", fairData?.browser);
        formData.append("channel", channel);
        formData.append("role", role);
        formData.append(
          "timezone",
          Intl.DateTimeFormat().resolvedOptions().timeZone
            ? Intl.DateTimeFormat().resolvedOptions().timeZone
            : userTimezone?.timezone
        );
        formData.append("document", values?.cv);
        try {
          const res = await instanceWithAuth.post("candidates", formData, {
            headers: {
              "requested-lang": app.langId,
            },
          });
          // if (res.status === 503) {
          //   history.push(`/${app?.appLayout?.fair?.short_name}/503`);
          // }
          if (res.status === 200 && !res?.data?.error) {
            sessionStorage.getItem("channel") &&
              sessionStorage.removeItem("channel");
            dispatch(handleResetCurrentSelectedData());
            setDistoryRecatpcha(true);
            let translations =
              res.data?.data?.user?.fair_info?.data?.fair?.fair_translations;
            let localLang = JSON.parse(localStorage.getItem("lang"));
            let defaultLanguage;
            let languageId = res.data?.data?.user?.fair_info?.data?.fair
              ?.default_language
              ? res.data?.data?.user?.fair_info?.data?.fair?.default_language
                  ?.auto_id
              : 0;
            if (translations && localLang) {
              let filterLanguages =
                translations?.length > 0 &&
                translations?.filter(
                  (filterLanguage) => +filterLanguage.code === +localLang?.value
                );
              defaultLanguage = filterLanguages[0].keywords;
            }
            if (translations && !localLang) {
              let filterLanguages =
                translations?.length > 0 &&
                translations?.filter(
                  (filterLanguage) => +filterLanguage.code === +languageId
                );
              defaultLanguage = filterLanguages[0].keywords;
            }
            let userData = {
              ...res.data?.data?.user,
              fair_info: res.data?.data?.user?.fair_info?.data,
              authToken: res?.data?.data?.access_token,
              defaultLanguage: defaultLanguage,
            };
            localStorage.setItem(
              "fairTranslation",
              JSON.stringify(
                res.data?.data?.user?.fair_info?.data?.fair?.fair_translations
              )
            );
            dispatch(addUser(userData));
            ReactTostify(
              "success",
              app?.fairLanguage?.trans_string_you_have_successfully_login
            );
            setStylingLayout(
              res.data?.data?.user?.fair_info?.data?.fair?.options
            );
            if (res.data?.data?.user?.fair_info?.data?.fair?.options?.mobile_color_option == 1) {
              setMobileBtnColor(res.data?.data?.user?.fair_info?.data?.fair?.options);
            }
            dispatch(changeAuthDraw(false));
            localStorage.setItem(
              "theme",
              JSON.stringify(res.data?.data?.user?.fair_info?.data)
            );
            localStorage.setItem("isLogin", true);
            if (app?.appLayout?.fair?.match_algorithm == "1") {
              history.push(`/${app?.fairName}/career/test`);
            } else if (
              app?.appLayout?.fair?.match_algorithm != "1" &&
              fairSettingOptions?.enable_force_questionnaire_after_registration_front ==
                "1"
            ) {
              history.push(`/${app?.fairName}/career/test`);
            } else if (app?.loginFromStand && !isFairLive) {
              history.push(`/${app?.fairName}/home`);
            } else if (
              app?.loginFromStand &&
              app?.appLayout?.fair?.match_algorithm != "1"
            ) {
              history.push(`/${app?.fairName}/stand/${app?.loginFromStandId}`);
            } else if (!app?.loginFromStand) {
              dispatch(toggleWelcomeModal(true));
              history.push(`/${app?.fairName}/home`);
            }
          }
          if (res?.data?.error && res?.data?.code === 404) {
            setIsLoading(false);
            ReactTostify("error", res?.data?.msg);
          }
        } catch (error) {
          setIsLoading(false);

          ReactTostify(
            "error",
            error?.response?.data?.msg
              ? error?.response?.data?.msg
              : error?.response?.data?.message
              ? error?.response?.data?.message
              : "Something went wrong!"
          );
        }
      }
    }
  };
  useEffect(() => {
    const data = {
      fair_id: app?.appLayout?.fair?.id,
    };
    app?.appLayout?.fair?.id && extraFieldsData(data);
  }, [app?.langId]);
  useEffect(() => {
    ipapiFun();
  }, []);
  return (
    <div className="RegisterFormWrapper">
      <div className="formFooter">
        {app.isRegisterBtn &&
        !app?.appLayout?.fair?.options?.disable_login_front ? (
          <Fragment>
            <h6 className="footerRegister">
              {`${app?.fairLanguage?.already_have_an_account} ?`}
            </h6>
            <Button
              type="button"
              className="buttonSignup"
              onClick={() => {
                dispatch(handleLoginBtn(true));
              }}>
              {app?.fairLanguage?.login}
            </Button>
          </Fragment>
        ) : (
          ""
        )}
      </div>
      <Divider />
      <Form
        name="basic"
        className="registerForm"
        // labelCol={{
        //   span: 24,
        // }}
        // wrapperCol={{
        //   span: 24,
        // }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autocomplete="off">
        <Row gutter={[32, 0]}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={
                fairSettingOptions?.field_first_last_name === 1
                  ? app?.fairLanguage?.first_name
                  : app?.fairLanguage?.name
              }
              name={
                fairSettingOptions?.field_first_last_name === 1
                  ? "firstName"
                  : "name"
              }
              className="PhoneLabel"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value) {
                      if (value.length < 50) return Promise.resolve();
                      return Promise.reject(
                        new Error(
                          `${app?.fairLanguage?.name}  ${
                            app?.fairLanguage
                              ?.trans_string_name_characters_must_be_less_then_fifty
                              ? app?.fairLanguage
                                  ?.trans_string_name_characters_must_be_less_then_fifty
                              : "characters must be less then 50!"
                          }`
                        )
                      );
                    }
                    return Promise.reject(
                      new Error(
                        fairSettingOptions?.field_first_last_name === 1
                          ? app?.fairLanguage?.trans_please_enter_first_name
                          : app?.fairLanguage?.trans_please_enter_name
                      )
                    );
                  },
                }),
              ]}>
              <div className="DFlex">
                <Input
                  className="nameDiv"
                  autocomplete="off"
                  placeholder={
                    fairSettingOptions?.field_first_last_name === 1
                      ? app?.fairLanguage?.first_name
                      : app?.fairLanguage?.name
                  }
                />
              </div>
            </Form.Item>
          </Col>
          {fairSettingOptions?.field_first_last_name === 1 && (
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label={app?.fairLanguage?.last_name}
                name="lastName"
                className="PhoneLabel"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        if (value.length < 50) return Promise.resolve();
                        return Promise.reject(
                          new Error(
                            `${app?.fairLanguage?.last_name}  ${
                              app?.fairLanguage
                                ?.trans_string_name_characters_must_be_less_then_fifty
                                ? app?.fairLanguage
                                    ?.trans_string_name_characters_must_be_less_then_fifty
                                : "characters must be less then 50!"
                            }`
                          )
                        );
                      }
                      return Promise.reject(
                        new Error(
                          app?.fairLanguage?.trans_please_enter_last_name
                        )
                      );
                    },
                  }),
                ]}>
                <div className="DFlex">
                  <Input
                    className="nameDiv"
                    autocomplete="off"
                    placeholder={app?.fairLanguage?.last_name}
                  />
                </div>
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={app?.fairLanguage?.email}
              name="email"
              className="PhoneLabel"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value) {
                      let emailformat =
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                      if (value.match(emailformat)) {
                        if (value.length < 50) return Promise.resolve();
                        return Promise.reject(
                          new Error(
                            `${app?.fairLanguage?.email} ${
                              app?.fairLanguage?.trans_string_char_must_less_50
                                ? app?.fairLanguage
                                    ?.trans_string_char_must_less_50
                                : "characters must be less then 50!"
                            }`
                          )
                        );
                      }
                      return Promise.reject(
                        new Error(
                          app?.fairLanguage
                            ?.trans_string_please_enter_valid_email
                            ? app?.fairLanguage
                                ?.trans_string_please_enter_valid_email
                            : "Please enter a valid email!"
                        )
                      );
                    }
                    return Promise.reject(
                      new Error(app?.fairLanguage?.trans_please_enter_email)
                    );
                  },
                }),
              ]}>
              <div className="DFlex">
                <Input
                  className="emailDiv"
                  type="email"
                  placeholder={app?.fairLanguage?.email_address}
                />
              </div>
            </Form.Item>
          </Col>
          {!fairSettingOptions?.field_company_name && (
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label={app?.fairLanguage?.company_name}
                name="user_company"
                className="PhoneLabel"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        let companyFormate = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
                        if (value.match(companyFormate)) {
                          if (value.length < 50) return Promise.resolve();
                          return Promise.reject(
                            new Error(
                              `${app?.fairLanguage?.company_name} ${
                                app?.fairLanguage
                                  ?.trans_string_company_name_characters_must_less_then_fifty
                                  ? app?.fairLanguage
                                      ?.trans_string_company_name_characters_must_less_then_fifty
                                  : "characters must be less then 50!"
                              }`
                            )
                          );
                        }
                        return Promise.reject(
                          new Error(
                            app?.fairLanguage
                              ?.trans_string_special_characters_are_not_allowed
                              ? app?.fairLanguage
                                  ?.trans_string_special_characters_are_not_allowed
                              : "Special charaters are not allowed!"
                          )
                        );
                      }
                      return Promise.reject(
                        new Error(
                          app?.fairLanguage?.trans_please_enter_company_name
                        )
                      );
                    },
                  }),
                ]}>
                <div className="DFlex">
                  <Input
                    type="text"
                    className="companyNameDiv"
                    placeholder={app?.fairLanguage?.company_name}
                  />
                </div>
              </Form.Item>
            </Col>
          )}
          {!fairSettingOptions?.field_job_title && (
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                placeholder="Job"
                label={app?.fairLanguage?.job_title}
                name="user_job_title"
                className="PhoneLabel"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        let jobFormate = /^[^*|\":<>[\]{}`\\()';@&$]+$/;
                        if (value.match(jobFormate)) {
                          if (value.length < 50) return Promise.resolve();
                          return Promise.reject(
                            new Error(
                              `${app?.fairLanguage?.job_title} ${
                                app?.fairLanguage
                                  ?.trans_string_Job_title_characters_must_be_less_then_fifty
                                  ? app?.fairLanguage
                                      ?.trans_string_Job_title_characters_must_be_less_then_fifty
                                  : "characters must be less then 50!"
                              }`
                            )
                          );
                        }
                        return Promise.reject(
                          new Error(
                            app?.fairLanguage
                              ?.trans_string_special_characters_are_not_allowed
                              ? app?.fairLanguage
                                  ?.trans_string_special_characters_are_not_allowed
                              : "Special charaters are not allowed!"
                          )
                        );
                      }
                      return Promise.reject(
                        new Error(
                          app?.fairLanguage?.trans_please_enter_job_title
                        )
                      );
                    },
                  }),
                ]}>
                <div className="DFlex">
                  <Input
                    className="jobDiv"
                    type="text"
                    placeholder={app?.fairLanguage?.job_title}
                  />
                </div>
              </Form.Item>
            </Col>
          )}
          {!fairSettingOptions?.field_phone_number && (
            <Col xs={24} sm={24} md={12}>
              <Form.Item
                label={app?.fairLanguage?.phone_number}
                className="PhoneLabel"
                name="phone"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value) {
                        let phoneformat =
                          /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
                        if (value.match(phoneformat)) {
                          if (value.length >= 7 && value.length < 17)
                            return Promise.resolve();
                          return Promise.reject(
                            new Error(
                              app?.fairLanguage
                                ?.trans_string_phone_must_be_greater_than_and_equal_to_less
                                ? app?.fairLanguage
                                    ?.trans_string_phone_must_be_greater_than_and_equal_to_less
                                : "Phone Must Be Greater Then 9 and Equal to 15 or Less!"
                            )
                          );
                        }
                        return Promise.reject(
                          new Error(
                            app?.fairLanguage?.only_numeric_character
                              ? app?.fairLanguage?.only_numeric_character
                              : "Please Enter a Numeric Characters"
                          )
                        );
                      }
                      return Promise.reject(
                        new Error(
                          app?.fairLanguage?.trans_please_enter_phone
                            ? app?.fairLanguage?.trans_please_enter_phone
                            : "Please Enter Phone Number"
                        )
                      );
                    },
                  }),
                ]}>
                <PhoneInput
                  country={'us'}
                  value={phoneNumber}
                  enableSearch={true}
                  countryCodeEditable={false}
                  onChange={phone => setPhoneNumber(`00${phone}`)}
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              label={app?.fairLanguage?.password}
              className="PhoneLabel"
              name="password"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value) {
                      if (value.length < 30 && value.length > 8)
                        return Promise.resolve();
                      return Promise.reject(
                        new Error(
                          app?.fairLanguage
                            ?.trans_string_minimum_eight_allowed_at_least_one_alphabet_letter_number_special_character
                            ? app?.fairLanguage
                                ?.trans_string_minimum_eight_allowed_at_least_one_alphabet_letter_number_special_character
                            : "Please enter minimum nine characters"
                        )
                      );

                      // return Promise.reject(
                      //   new Error(
                      //     app?.fairLanguage
                      //       ?.trans_string_minimum_eight_allowed_at_least_one_alphabet_letter_number_special_character
                      //       ? app?.fairLanguage
                      //           ?.trans_string_minimum_eight_allowed_at_least_one_alphabet_letter_number_special_character
                      //       : 'Minimum eight characters, at least one number'
                      //   )
                      // );
                    }
                    return Promise.reject(
                      new Error(app?.fairLanguage?.trans_please_enter_password)
                    );
                  },
                }),
              ]}>
              <div className="DFlex">
                <Input.Password
                  className="passwordDiv"
                  placeholder={app?.fairLanguage?.password}
                />
              </div>
            </Form.Item>
          </Col>
          {extraData?.length > 0 &&
            extraData?.map((row, index) => {
              return (
                <Col xs={24} sm={24} md={12}>
                  <ExtraFields className="extraFields" key={index} row={row} />
                </Col>
              );
            })}
        </Row>
        {/* <Form.Item
          name='terms'
          valuePropName='checked'
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        `${app?.fairLanguage?.trans_terms_must_accepted}`
                      )
                    ),
            },
          ]}>
          <Checkbox className='checkLabel'>
            {app?.fairLanguage?.i_agree_with}
            <span
              style={{ color: '#40a9ff', cursor: 'pointer' }}
              onClick={handleTermsViewModal}>
              {app?.fairLanguage?.terms_conditions_key}
            </span>
          </Checkbox>
        </Form.Item> */}
        {app?.appLayout?.fair?.fair_setting?.cv_required == "0" ? (
          ""
        ) : app?.appLayout?.fair?.fair_setting?.cv_required == "2" ? (
          <Form.Item
            label={`${app?.fairLanguage?.upload}  ${app?.fairLanguage?.cv}`}
            className="CvLabel">
            <CvUpload
              getCV={(file) => {
                if (file) {
                  if (!hasExtension(file, [".pdf", ".doc", ".docx"])) {
                    setCv(null);
                    setIsCvError({
                      isError: true,
                      errorMessage: app?.fairLanguage
                        ?.trans_only_upload_cv_format
                        ? app?.fairLanguage?.trans_only_upload_cv_format
                        : "Only .doc, .docx and .pdf format is allowed!",
                    });
                  } else {
                    setCv(file);
                    setIsCvError({ isError: false, errorMessage: "" });
                  }
                }
              }}
              isCvError={isCvError}
            />
          </Form.Item>
        ) : (
          <div className="cvContent">
            <Form.Item
              label={`${
                app?.fairLanguage?.upload ? app?.fairLanguage?.upload : "Upload"
              } ${app?.fairLanguage?.cv ? app?.fairLanguage?.cv : "CV"}`}
              className="PhoneLabel">
              <div className="cvDiv">
                <CvUpload
                  className="CvButton"
                  getCV={(file) => {
                    if (file) {
                      if (!hasExtension(file, [".pdf", ".doc", ".docx"])) {
                        setCv(null);
                        setIsCvError({
                          isError: true,
                          errorMessage: app?.fairLanguage
                            ?.trans_only_upload_cv_format
                            ? app?.fairLanguage?.trans_only_upload_cv_format
                            : "Only .doc, .docx and .pdf format is allowed!",
                        });
                      } else {
                        setCv(file);
                        setIsCvError({ isError: false, errorMessage: "" });
                      }
                    } else {
                      setCv(null);
                      setIsCvError({
                        isError: true,
                        errorMessage: "CV is required!",
                      });
                    }
                  }}
                  isCvError={isCvError}
                />
              </div>
            </Form.Item>
          </div>
        )}
        {fairSettingOptions?.disable_term_conditions_privacy_policy_registration_front ===
          0 && (
          <Form.Item name="terms">
            <input type="checkbox" checked={isChecked} onChange={e => setIsChecked(e.target.checked)} required/>
            {" "}
            <span style={{ color: "#fff"}}>{app?.fairLanguage?.i_agree_with}</span>
            <span
              className="checkLabel"
              style={{ color: "#40a9ff", cursor: "pointer" }}
              onClick={handleTermsViewModal}>
              {" "}
              {app?.fairLanguage?.terms_conditions_key}
            </span>
            <span style={{ color: "#fff"}}> and</span>
            <span
                style={{ color: "#40a9ff", cursor: "pointer" }}
                onClick={handlePrivacyViewModal}>
              {" "}
              {app?.fairLanguage?.privacy_policy_key}
            </span>
          </Form.Item>
        )}

        {/* {!distoryRecatpcha && (
          <Form.Item>
            <AppRecaptcha setIsNotRobot={checkRobot} />
            {isErrorRobot ? (
              <p className='my-1 text-danger'>
                {app?.fairLanguage?.trans_string_please_verify_you_are_human}
              </p>
            ) : null}
          </Form.Item>
        )} */}
        <Form.Item>
          <Button
            className="subBtn"
            htmlType="submit"
            onClick={onFinish}
            disabled={isLoading ? true : false}>
            {isLoading ? (
              <div className="AuthSpinerDiv DFlex justify-content-center">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
                <p>{app?.fairLanguage?.please_wait}...</p>
              </div>
            ) : (
              app?.fairLanguage?.submit
            )}
          </Button>
        </Form.Item>
        {/* <div className='userPopup'>
          <p>
            Oops.It loos like you have already register with that email
            address.Click <strong>Here</strong> to sign in.
          </p>
        </div> */}
        {privacyViewModal && (
          <PrivacyModal
            privacyViewModal={privacyViewModal}
            handlePrivacyHideModal={handlePrivacyHideModal}
            privacyTitle={app?.fairLanguage?.privacy_policy_key}
          />
        )}
        {termsCondtionViewModal && (
          <TermsConditionModal
            handleTermsViewModal={handleTermsViewModal}
            handleTermsHideModal={handleTermsHideModal}
            termTitle={app?.fairLanguage?.terms_conditions_key}
          />
        )}
      </Form>
      {/* {app?.welcomeShowModal && (
        <WelcomeRegistrationModal showModal={app?.welcomeShowModal} />
      )} */}
    </div>
  );
};

export default RegisterForm;
