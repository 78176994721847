import { Form, Input, Select } from "antd";

function ExtraFields({ row }) {
  let returnField = "";
  let isRequired = row.is_required === "Y" ? true : false;
  if (row.field_type === "text" && row.auto_id < row.auto_id + 1) {
    returnField = isRequired ? (
      <Form.Item
        className={isRequired ? "PhoneLabel" : "ExtraLabel"}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              let nameformat = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
              if (!value.match(nameformat)) {
                if (isRequired) {
                  if (value) {
                    if (value.length < 100) return Promise.resolve();
                    return Promise.reject(
                      new Error(
                        `${row?.field_title} characters must be less then 100!`
                      )
                    );
                  }
                  return Promise.reject(
                    new Error(`Please Enter ${row?.field_title}`)
                  );
                }
                return Promise.resolve();
              }
              return Promise.reject(new Error(`only alphabet and numerics!`));
            },
          }),
        ]}
        style={{ width: "100%", display: "block" }}
        name={row?.auto_id}
        label={row?.field_title}
      >
        <Input placeholder={row?.field_title} />
      </Form.Item>
    ) : (
      <Form.Item
        className={isRequired ? "PhoneLabel" : "ExtraLabel"}
        style={{ width: "100%", display: "block" }}
        name={row?.auto_id}
        label={row?.field_title}
      >
        <Input placeholder={row?.field_title} />
      </Form.Item>
    );
  }

  if (row.field_type === "dropdown") {
    let options = row.options.map((row, index, array) => {
      return (
        <Select.Option value={row.auto_id}>{row.option_text}</Select.Option>
      );
    });

    returnField = (
      <Form.Item
        label={row?.field_title}
        name={row?.auto_id}
        className={`${isRequired ? "PhoneLabel" : "ExtraLabel"}`}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              // let nameformat = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
              // if (!value.match(nameformat)) {
              if (isRequired) {
                if (value) return Promise.resolve();
                return Promise.reject(
                  new Error(`Please Select ${row?.field_title}`)
                );
              }
              return Promise.resolve();
              // }
              return Promise.reject(new Error(`only alphabet and numerics!`));
            },
          }),
        ]}
      >
        <Select
          style={{ width: "100%", display: "block" }}
          placeholder={`${row?.field_title}`}
        >
          {options}
        </Select>
      </Form.Item>
    );
  }

  return <div>{returnField}</div>;
}

export default ExtraFields;
