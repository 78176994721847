import StarRatings from 'react-star-ratings';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import parse from 'html-react-parser';
import { Col } from 'antd';
import ProgressCard from './ProgressCard';

const ExitSurvayReviewCard = ({ surveyResult, isTakeSurvey, surveyInfo }) => {
  let dOptions = {
    tooltips: {
      enabled: false,
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(1) + '%';
          if (percentage === '0.0%') {
            return '';
          }
          return percentage;
        },
        color: '#fff',
      },
    },
  };
  return (
    <div className='ReviewCardDiv DBlock'>
      {surveyResult?.length > 0 &&
        surveyResult.map((result, ind) => {
          let labels = [];
          let dataSet = [];
          result?.survey_result[0]?.options_result?.map((val, i) => {
            labels.push(val.option_value);
            dataSet.push(val.total_attempt);
          });
          let data = {
            labels: labels,
            datasets: [
              {
                label: 'Result',
                data: dataSet,
                backgroundColor: [
                  'rgb(0, 227, 150)',
                  'rgb(0, 143, 251)',
                  'rgb(254, 176, 25)',
                  'rgb(255, 69, 96)',
                  'rgb(255, 0, 191)',
                  'rgb(51, 0, 0)',
                  'rgba(255, 99, 132, 0.6)',
                ],
              },
            ],
          };
          return (
            <div className='ProgressResult pb-5' key={`resultInd${ind}`}>
              <Col xs={24} sm={24}>
                {surveyInfo?.length > 0 && parse(surveyInfo)}
                <hr />
              </Col>
              {result?.survey_result[0]?.options_result?.length > 0 && (
                <label className='Question mt-3'>
                  {result?.survey_question}
                </label>
              )}
              {result?.survey_graph_type === 'rating' && isTakeSurvey ? (
                <div className='ReviewCard DBlock h-auto mb-2'>
                  <h2 className='Title'>
                    {result?.survey_result[0]?.total_percentage.toFixed(1)}
                    {/* {result?.survey_result[0]?.options_result?.length && (
                      <span>
                        /{result?.survey_result[0]?.options_result?.length}
                      </span>
                    )} */}
                  </h2>
                  <div className='StartDiv'>
                    <StarRatings
                      rating={result?.survey_result[0]?.total_percentage}
                      starRatedColor='orange'
                      numberOfStars={
                        result?.survey_result[0]?.options_result?.length
                      }
                      starDimension='30px'
                      starSpacing='3px'
                    />
                  </div>
                  <p className='person'>
                    {result?.survey_result[0]?.total_attendees} ratings
                  </p>
                  <div className='PercentageDiv'>
                    {result?.survey_result[0]?.options_result?.length > 0 &&
                      result?.survey_result[0]?.options_result?.map(
                        (data, ind) => <ProgressCard key={ind} data={data} />
                      )}
                  </div>
                </div>
              ) : result?.survey_graph_type === 'basicbar' &&
                result?.survey_result[0]?.options_result?.length > 0 ? (
                <div
                  className='row'
                  style={{
                    marginLeft: '15%',
                    width: '80%',
                  }}>
                  <Bar
                    data={data}
                    width={100}
                    height={50}
                    plugins={[ChartDataLabels]}
                    options={{ dOptions, maintainAspectRatio: true }}
                  />
                </div>
              ) : result?.survey_graph_type === 'pie' &&
                result?.survey_result[0]?.options_result?.length > 0 ? (
                <div
                  className='row'
                  style={{
                    marginLeft: '15%',
                    width: '70%',
                  }}>
                  <Pie
                    data={data}
                    width={100}
                    height={50}
                    plugins={[ChartDataLabels]}
                    options={dOptions}
                  />
                </div>
              ) : result?.survey_graph_type === 'donut' &&
                result?.survey_result[0]?.options_result?.length > 0 ? (
                <div
                  className='row'
                  style={{
                    marginLeft: '15%',
                    width: '70%',
                  }}>
                  <Doughnut
                    data={data}
                    width={100}
                    height={50}
                    plugins={[ChartDataLabels]}
                    options={dOptions}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
    </div>
  );
};

export default ExitSurvayReviewCard;
