import { Modal } from 'antd';
import PrivacyPolicy from 'pages/privacypolicy/PrivacyPolicy';

function PrivacyModal({
  privacyViewModal,
  handlePrivacyHideModal,
  privacyTitle,
}) {
  return (
    <Modal
      title={privacyTitle}
      footer={false}
      style={{ marginTop: '-3%' }}
      width='58%'
      bodyStyle={{ height: '100%', overflowY: 'scroll' }}
      visible={privacyViewModal}
      onCancel={handlePrivacyHideModal}>
      <PrivacyPolicy />
    </Modal>
  );
}

export default PrivacyModal;
