import { Modal } from "antd";
import TermCondition from "pages/terms&condition/TermCondition";

function TermsConditionModal({
  handleTermsViewModal,
  handleTermsHideModal,
  termTitle,
}) {
  return (
    <Modal
      title={termTitle}
      width='50%'
      style={{ marginTop: '-3%' }}
      bodyStyle={{ height: '100%', overflowY: 'scroll' }}
      footer={false}
      visible={handleTermsViewModal}
      onCancel={handleTermsHideModal}
    >
      <TermCondition />
    </Modal>
  );
}

export default TermsConditionModal;
