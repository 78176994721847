import { Card } from "antd";
import React, { Fragment, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { XCircle } from "react-feather";

const HeaderNotifications = ({
  app,
  impNotiData,
  isNotification,
  notificationDataList,
  // notificationsData,
  webinarsData,
  viewProfile,
  handleCloseNoti,
}) => {
  return (
    <div className="NotifyDiv DBlock bg-white">
      <div className="Title DFlex h-auto">
        <p>{app?.fairLanguage?.notifications}</p>
        <div>
          <span className="NotifySpan">
            {`${
              app?.profilePending &&
              app?.appLayout?.fair?.fair_setting?.important_notification
                ? notificationDataList.length + 2
                : app?.appLayout?.fair?.fair_setting?.important_notification
                ? notificationDataList.length + 1
                : app?.profilePending
                ? notificationDataList.length + 1
                : notificationDataList.length
            } ${app?.fairLanguage?.new ? app?.fairLanguage?.new : "New"}`}
          </span>
        </div>
        <div className="closeIcon" onClick={handleCloseNoti}>
          <XCircle size={20} />
        </div>
      </div>
      <div className="Desc DBlock">
        <Fragment>
          {impNotiData && (
            <div className="NotificationCard border-0 mt-3">
              {ReactHtmlParser(impNotiData)}
            </div>
          )}
          {/* {app?.profilePending && (
            <Card className='NotificationCard border-0 mt-3'>
              <p>
                {
                  app?.fairLanguage
                    ?.trans_string_complete_your_profile_and_update
                }
              </p>
              {!app?.appLayout?.isTakeTest ? (
                <Link to={`/${app?.fairName}/career/test`}>
                  {app?.fairLanguage?.trans_string_click_here_to_complete}
                </Link>
              ) : (
                <button type='button' onClick={viewProfile}>
                  {app?.fairLanguage?.trans_string_click_here_to_complete}
                </button>
              )}
            </Card>
          )} */}
          {app?.appLayout?.fair?.options
            ?.disable_webinars_notifications_front === 0 &&
            notificationDataList.length > 0 &&
            notificationDataList.map((notification, ind) => (
              <Card
                className="NotificationCard border-0 mt-3"
                key={`headerNoti${ind}`}
              >
                <h5>
                  {notification?.webinar_title} :{" "}
                  {app?.fairLanguage?.webinar_is_live_now
                    ? app?.fairLanguage?.webinar_is_live_now
                    : "Webinar Is Live Now"}
                </h5>
                <p>{`${app?.fairLanguage?.speaker} : ${notification?.speaker_name}`}</p>
                {notification?.webinar_type === "link" ? (
                  <a
                    href={notification?.webinar_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {app?.fairLanguage?.click_to_join}
                  </a>
                ) : notification?.webinar_type === "ms_team_direct" ||
                  notification?.webinar_type === "ms_team" ? (
                  <a
                    href={notification?.webinar_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {app?.fairLanguage?.click_to_join}
                  </a>
                ) : (
                  <Link
                    to={
                      notification?.type === "live"
                        ? `/${app?.fairName}/conference/${notification?.webinar_id}`
                        : `/${app?.fairName}/webinar/${notification?.webinar_id}`
                    }
                  >
                    {app?.fairLanguage?.click_to_join}
                  </Link>
                )}
              </Card>
            ))}
        </Fragment>
      </div>
    </div>
  );
};

export default HeaderNotifications;
