import { Drawer } from 'antd'
import { XCircle } from 'react-feather'
import { Fragment } from 'react'
import { Button } from 'antd'
import LoginForm from './LoginForm'
import AccentureLogin from './accentureLogin/AccentureLogin'
import { useDispatch, useSelector } from 'react-redux'

import { changeAuthDraw, handleRegisterBtn } from 'redux/app/appSlice'
import SSOLogin from './ssologin/SSOLogin'
import './loginDrawer.scss'
import { compose } from '@reduxjs/toolkit'

const LoginDrawer = ({ setIsLogin }) => {
  const { app } = useSelector((state) => state)
  const dispatch = useDispatch()
  const Region = app?.appLayout?.fair?.fairTiming?.chat_region
  const fairId = app?.appLayout?.fair?.id
  const ssoArr = [
    87, 67, 94, 119, 158, 115, 181, 201, 69, 212, 220, 180, 236, 241,
  ]

  let ssoLoginPlace = 'bottom'

  if (app?.appLayout?.fair?.options?.sso_placement_front == 'top') {
    ssoLoginPlace = 'top'
  }

  return (
    <div>
      <Drawer
        title={
          <div className="DFlex">
            <p className="loginHeader">{app?.fairLanguage?.login}</p>
            <div
              className="SingCrossBtn btn bg-transparent outline-none"
              onClick={() => dispatch(changeAuthDraw(false))}
            >
              <XCircle className="SingCrossIcon" />
            </div>
          </div>
        }
        mask={false}
        style={{ top: '49px' }}
        placement="right"
        className="loginDrawer"
        closable={false}
        visible={app?.isAuthDraw}
      >
        {ssoLoginPlace == 'top' && (
          <>
            <SSOLogin
              name={
                app.isLoginBtn
                  ? app?.fairLanguage?.login
                  : app?.fairLanguage?.register
              }
            />
            <hr className="formHr" />
          </>
        )}

        {app.isLoginBtn && <LoginForm setIsLogin={setIsLogin} />}

        {ssoLoginPlace == 'bottom' && (
          <>
            <hr className="formHr" />
            <SSOLogin
              name={
                app.isLoginBtn
                  ? app?.fairLanguage?.login
                  : app?.fairLanguage?.register
              }
            />
          </>
        )}
        {ssoArr.includes(fairId) && Region == 'usa' ? <AccentureLogin /> : ''}
      </Drawer>
    </div>
  )
}

export default LoginDrawer
