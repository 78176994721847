import React, { useEffect, useState } from 'react'
import { instanceWithAuth } from 'App'
import {
  parameterValue,
  setStylingLayout,
  setMobileBtnColor,
} from 'common/common'
import { useToasts } from 'react-toast-notifications'
import { addUser, changeAuthDraw } from 'redux/app/appSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { togglePending, getHallLangApi } from 'redux/app/appSlice'
import IsFairLive from 'common/IsFairLive'
import './ssoLogin.scss'
let ssoCount = 0

function SSOLogin({ name }) {
  const { app } = useSelector((state) => state)
  let fairSettingOptions =
    app?.appLayout?.fair?.options && app?.appLayout?.fair?.options
  const dispatch = useDispatch()
  const history = useHistory()
  const { addToast } = useToasts()
  const [encodedUrl, setEncodedUrl] = useState(null)
  let isFairLive = app?.appLayout?.fair?.fairTiming
    ? IsFairLive(
        app?.appLayout?.fair?.fairTiming?.date,
        app?.appLayout?.fair?.fairTiming?.fair_start,
        app?.appLayout?.fair?.fairTiming?.fair_end,
        app?.appLayout?.fair?.dry_run
      )
    : false
  const handleSSOLogin = async (code, from, encodedUrl) => {
    const fairData = JSON.parse(localStorage.getItem('fairData'))
    let postData = {
      fair_id: fairData.fair_id,
      from: from,
      user_city: fairData.userCity,
      user_country: fairData.userCountry,
      user_postal_code: fairData.userPostalCode
        ? fairData.userPostalCode
        : '569933',
      browser: fairData.browser,
      timezone: fairData.timezone,
      ip: fairData.ip,
      location: fairData.location,
      redirect_uri: encodedUrl,
      fair_info: 'Y',
    }
    try {
      const res = await instanceWithAuth.post(
        `/front/web/sso/login?code=${code}`,
        postData
      )
      // if (res.status === 503) {
      //   history.push(`/${app?.appLayout?.fair?.short_name}/503`);
      // }
      if (res.status === 200) {
        console.log(
          res.data.data?.user?.fair_info?.data?.fair?.fair_translations,
          'api res'
        )
        let defaultLanguage
        let translations =
          res.data.data?.user?.fair_info?.data?.fair?.fair_translations
        let languageId = res.data.data?.user?.fair_info?.data?.fair
          ?.default_language
          ? res.data.data?.user?.fair_info?.data?.fair?.default_language
              ?.auto_id
          : 0
        if (translations?.length > 0) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) => +filterLanguage.code === +languageId
            )
          defaultLanguage = filterLanguages[0].keywords
        }
        let userData = {
          ...res.data?.data?.user,
          authToken: res?.data?.data?.access_token,
          fair_info: res.data?.data?.user?.fair_info?.data,
          defaultLanguage: defaultLanguage,
        }
        localStorage.setItem(
          'fairTranslation',
          JSON.stringify(
            res.data.data?.user?.fair_info?.data?.fair?.fair_translations
          )
        )
        if (res.data?.data?.user?.status === 'profile_pending') {
          dispatch(togglePending(true))
        }
        let data = {
          fair_id: app?.appLayout?.fair?.id,
          authToken: res?.data?.data?.access_token,
          langId: app.langId,
        }
        dispatch(getHallLangApi(data))
        dispatch(addUser(userData))
        addToast('You have successfully login!', { appearance: 'success' })
        setStylingLayout(res.data.data?.user?.fair_info?.data?.fair?.options)
        if (
          res.data.data?.user?.fair_info?.data?.fair?.options
            ?.mobile_color_option == 1
        ) {
          setMobileBtnColor(res.data.data?.user?.fair_info?.data?.fair?.options)
        }
        dispatch(changeAuthDraw(false))
        localStorage.setItem(
          'theme',
          JSON.stringify(res.data.data?.user?.fair_info.data)
        )
        localStorage.setItem('isLogin', true)
        localStorage.setItem(
          'accessToken',
          JSON.stringify(res?.data?.data?.access_token)
        )
        if (
          app?.appLayout?.fair?.match_algorithm === '1' &&
          res.data.data?.user?.is_take_test === 0
        ) {
          history.push(`/${app?.fairName}/career/test`)
        } else if (
          app?.loginFromStandId &&
          isFairLive &&
          res.data.data?.user?.is_take_test === 1
        ) {
          history.push(`/${app?.fairName}/stand/${app?.loginFromStandId}`)
        } else if (
          app?.loginFromStandId &&
          isFairLive &&
          app?.appLayout?.fair?.match_algorithm === '0'
        ) {
          history.push(`/${app?.fairName}/stand/${app?.loginFromStandId}`)
        } else {
          history.push(`/${app?.fairName}/home`)
        }
        // Fhistory.push(`/${app?.fairName}/home`);
        // history.go(0);
      }
    } catch (error) {
      if (error.response.data.msg) {
        addToast(error?.response?.data?.msg, { appearance: 'error' })
      } else {
        addToast('Something went wrong. Please try again!', {
          appearance: 'error',
        })
      }
    }
  }

  useEffect(() => {
    const fairData = JSON.parse(localStorage.getItem('fairData'))
    const hostUrl = window.location.origin + '/' + fairData?.short_name
    const encodedUrl = encodeURIComponent(hostUrl)
    setEncodedUrl(encodedUrl)

    const code = parameterValue('code', window.location.href)
    const authCodeState = parameterValue('state', window.location.href)
    const handleValState = () => {
      if (+authCodeState === 1221) {
        return 'VRD_Facebook'
      }
      if (+authCodeState === 3443) {
        return 'VRD_Google'
      }
      if (+authCodeState === 5665) {
        return 'VRD_Linkdin'
      }
      if (+authCodeState === 7887) {
        return 'VRD_Microsoft'
      }
    }
    const from = handleValState()
    if (code && from && ssoCount === 0) {
      ssoCount = 1
      handleSSOLogin(code, from, encodedUrl)
    }
  }, [app?.appLayout])

  let showLWM = true
  let showLWG = true
  let showLWL = true
  let showLWF = true

  if (fairSettingOptions?.disable_login_with_microsoft == '1') {
    showLWM = false
  }

  if (fairSettingOptions?.disable_login_with_google == '1') {
    showLWG = false
  }

  if (fairSettingOptions?.disable_login_with_linkedin == '1') {
    showLWL = false
  }

  if (fairSettingOptions?.disable_login_with_facebook == '1') {
    showLWF = false
  }

  return (
    <div className="SocialLink">
      {+app?.appLayout?.fair?.sso_enabled === 1 && (
        <>
          {/* <p className='text-center TextP'> {name} </p> */}
          <div className="text-center">
            {showLWF && (
              <a
                href={`https://www.facebook.com/v10.0/dialog/oauth?client_id=${
                  app?.appLayout?.fair.FACEBOOK_APP_ID
                }&redirect_uri=${encodedUrl
                  ?.toString()
                  ?.toLowerCase()}&state=1221&scope=public_profile,email`}
              >
                <div className="SSOdivContainer">
                  <img
                    title={`Facebook ${name}`}
                    alt="Facebook"
                    src="/assets/facebook.png"
                  />
                  <p>{app?.fairLanguage?.trans_string_login_with_facebook}</p>
                </div>
              </a>
            )}

            {showLWG && (
              <a
                href={`https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=profile%20email&access_type=offline&include_granted_scopes=true&response_type=code&state=3443&redirect_uri=${encodedUrl
                  ?.toString()
                  ?.toLowerCase()}&client_id=${
                  app?.appLayout?.fair.GOOGLE_APP_ID
                }&flowName=GeneralOAuthFlow`}
              >
                <div className="SSOdivContainer">
                  <img
                    title={`Google ${name}`}
                    alt="Google"
                    src="/assets/google.png"
                  />
                  <p>{app?.fairLanguage?.trans_string_login_with_google}</p>
                </div>
              </a>
            )}

            {showLWL && (
              <a
                href={`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
                  app?.appLayout?.fair.LINKDIN_APP_ID
                }&redirect_uri=${encodedUrl
                  ?.toString()
                  ?.toLowerCase()}&state=5665&scope=r_liteprofile%20r_emailaddress`}
              >
                <div className="SSOdivContainer">
                  <img
                    title={`Linkedin ${name}`}
                    alt="Linkedin"
                    src="/assets/linkedin.png"
                  />
                  <p>{app?.fairLanguage?.trans_string_login_with_linkedin}</p>
                </div>
              </a>
            )}

            {showLWM && (
              <a
                href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
                  app?.appLayout?.fair.MS_APP_ID
                }&response_type=code&redirect_uri=${encodedUrl
                  ?.toString()
                  ?.toLowerCase()}&response_mode=query&scope=user.read&state=7887`}
              >
                <div className="SSOdivContainer">
                  <img
                    title={`Microsoft ${name}`}
                    alt="Microsoft"
                    src="/assets/microsoft.png"
                  />
                  <p>{app?.fairLanguage?.trans_string_login_with_microsoft}</p>
                </div>
              </a>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default SSOLogin
