import { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import parse from 'html-react-parser';
import { ReactTostify } from 'components/reactToastify/ReactTostify';

import { Row, Col, Form, Input, Radio, Checkbox } from 'antd';
import { instanceWithAuth } from 'App';
import ExitServayRating from './ExitServayRating';
import useFetchToken from "customHooks/useFetchToken";
import {
  setExitSurvey,
  getAppLayout,
  removeUser,
  toggleIsLoading,
  setShowLayout,
  setSwitchCompanyId,
} from 'redux/app/appSlice';

const ExitServayForm = ({
  setExitSurveyModal,
  surveyData,
  isLoading,
  surveyInfo,
  s,
}) => {
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const [initialValues, setInitialValues] = useState(null);
  const [rating, setRating] = useState(null);
  const [ratingObj, setRatingObj] = useState(false);
  const [radioButtonObj, setRadioButtonObj] = useState({});
  const [checkBoxesData, setCheckBoxes] = useState({});
  const [text, setText] = useState('');
  const [textFieldObj, setTextFieldObj] = useState({});
  const [doFormValidation, setdoFormValidation] = useState(true);
  const {getToken} = useFetchToken()

  let fair_id = app?.appLayout?.fair?.id;
  let candidate_id = app?.user?.id;
  const handleChangeRating = (optionVal, surveyId) => {
    let stateRating = { ...rating };
    if (optionVal && surveyId) setRatingObj(true);
    let filterData = surveyData.filter(
      (survey) => +survey.survey_id === +surveyId
    );

    filterData[0]?.survey_options?.map((filterObj, index) => {
      if (index + 1 === optionVal)
        stateRating[surveyId] = {
          option_id: filterObj?.option_id,
          survey_id: filterObj?.survey_id,
          survey_type: filterObj?.survey_type,
        };
    });
    setRating(stateRating);
  };

  const handleRadioButtonObj = (e) => {
    setRadioButtonObj({
      ...radioButtonObj,
      [e.target.id]: e.target.value,
    });
  };
  const handleTextFieldObj = (e) => {
    setText({ ...text, [e.target.id]: e.target.value });
    setTextFieldObj({
      ...textFieldObj,
      [e.target.id]: e.target.value,
    });
  };
  const handleCheckboxButton = (e) => {
    let index;
    const currentData = checkBoxesData[e.target.id]
      ? checkBoxesData[e.target.id]
      : [];
    if (e.target.checked) {
      setCheckBoxes({
        ...checkBoxesData,
        [e.target.id]: [...currentData, e.target.value],
      });
    } else {
      index = currentData.indexOf(e.target.id);
      currentData.splice(index, 1);
    }
  };

  const successFullyLogout = () => {
    dispatch(toggleIsLoading(true));
    let apiData = {
      loginFair: false,
      data: {
        fairName: app?.appLayout?.fair?.short_name,
        fairId: null,
      },
    };
    localStorage.setItem('isLogin', false);
    localStorage.removeItem('timeZone');
    localStorage.removeItem('theme');
    localStorage.removeItem('fairData');
    dispatch(removeUser());
    dispatch(getAppLayout(apiData));
    dispatch(setSwitchCompanyId(null));
    history.push(`/${app?.appLayout?.fair?.short_name}/home`);
  };

  const handleSaveSurveyExit = async (
    values,
    fair_id,
    candidate_id
  ) => {
    let apiData = {
      fair_id,
      candidate_id,
      survey_data: [],
    };
    if (ratingObj) {
      for (const key in rating) {
        apiData.survey_data.push(rating[key]);
      }
    }
    if (radioButtonObj) {
      Object.keys(radioButtonObj).forEach((key) => {
        apiData.survey_data.push({
          poll_id: key,
          survey_type: 'radio',
          option_id: radioButtonObj[key],
          survey_id: key,
        });
      });
    }
    if (checkBoxesData)
      Object.keys(checkBoxesData).forEach((key) => {
        Array.isArray(checkBoxesData[key]) &&
          checkBoxesData[key].forEach((data) => {
            apiData.survey_data.push({
              poll_id: key?.toString(),
              survey_type: 'checkbox',
              option_id: data,
              survey_id: key,
            });
          });
      });
    if (textFieldObj) {
      Object.keys(textFieldObj).forEach((key) => {
        apiData.survey_data.push({
          poll_id: key,
          survey_type: 'text',
          option_id: textFieldObj[key],
          survey_id: key,
        });
      });
    }
    try {
      const bearer = `Bearer ${await getToken()}`;
      const result = await instanceWithAuth.post(`save/survey`, apiData, {
        headers: {
          Authorization: bearer,
        },
      });
      if (result?.status === 200) {
        dispatch(setExitSurvey(true));
        isLoading(false);
        setExitSurveyModal();
        if (app?.appLayout?.fair?.options?.disable_exit_survey_logout == 0) {
          successFullyLogout();
        } else {
          history.push(`/${app?.appLayout?.fair?.short_name}/home`);
        }
        ReactTostify(
          'success',
          app?.fairLanguage?.trans_string_thank_for_taking_time_complete
            ? app?.fairLanguage?.trans_string_thank_for_taking_time_complete
            : 'Thank you for taking the time to complete this survey.'
        );
      }
    } catch (error) {
      isLoading(false);
      ReactTostify(
        'success',
        app?.fairLanguage?.trans_string_thank_for_taking_time_complete
          ? app?.fairLanguage?.trans_string_thank_for_taking_time_complete
          : 'Thank you for taking the time to complete this survey.'
      );
    }
  };
  useEffect(() => {
    if (
      app?.appLayout?.fair?.options
        ?.remove_survey_questions_required_validation_front == '1'
    ) {
      setdoFormValidation(false);
    }
  }, []);
  return (
    <Form
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onFinish={(values) => {
        handleSaveSurveyExit(values, fair_id, candidate_id);
      }}
      initialValues={initialValues}>
      <Row>
        <Col xs={24} sm={24}>
          {surveyInfo?.length > 0 && parse(surveyInfo)}
          <hr />
        </Col>
        {surveyData?.length > 0 &&
          surveyData?.map((survey, index) =>
            survey?.survey_type === 'text' ? (
              <Col key={`SurveyData${index}`} xs={24} sm={24}>
                <Form.Item
                  label={`${survey?.survey_question}`}
                  name={`${survey?.survey_id}`}
                  rules={[
                    survey?.survey_type === 'text' && {
                      required: doFormValidation ? true : false,
                      message: app?.fairLanguage
                        ?.trans_string_please_fill_this_field
                        ? app?.fairLanguage?.trans_string_please_fill_this_field
                        : 'Please fill this field',
                    },
                  ]}>
                  <div className='flex flex-column p-2'>
                    <Input.TextArea
                      value={text[survey?.survey_id]}
                      id={survey?.survey_id}
                      name={`${survey?.survey_id}`}
                      onChange={handleTextFieldObj}
                      placeholder={
                        app?.fairLanguage?.trans_string_remember_to_be_nice
                          ? app?.fairLanguage?.trans_string_remember_to_be_nice
                          : 'Remember, be nice!'
                      }
                      maxLength={500}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                    <span
                      className='text-info'
                      style={{ float: 'right', fontSize: '11px' }}>
                      {app?.fairLanguage
                        ?.trans_string_max_five_hundred_characters
                        ? app?.fairLanguage
                            ?.trans_string_max_five_hundred_characters
                        : 'Max 500 characters'}
                    </span>
                  </div>
                </Form.Item>
              </Col>
            ) : (
              survey?.survey_options?.length > 0 && (
                <Col key={`SurveyData${index}`} xs={24} sm={24}>
                  <Form.Item
                    label={`${survey?.survey_question}`}
                    name={`${survey?.survey_id}`}
                    rules={[
                      survey?.survey_type === 'radio' ||
                      survey?.survey_type === 'checkbox'
                        ? {
                            required: doFormValidation ? true : false,
                            message: `${
                              survey?.survey_type === 'radio' ||
                              survey?.survey_type === 'checkbox'
                                ? app?.fairLanguage
                                    ?.trans_string_please_select_atleast_one_option
                                  ? app?.fairLanguage
                                      ?.trans_string_please_select_atleast_one_option
                                  : 'Please select atleast one option'
                                : app?.fairLanguage
                                    ?.trans_string_please_fill_this_field
                                ? app?.fairLanguage
                                    ?.trans_string_please_fill_this_field
                                : 'Please fill this field'
                            }`,
                          }
                        : {
                            // required: doFormValidation ? true : false,
                          },
                    ]}>
                    {survey?.survey_type === 'rating' ? (
                      <ExitServayRating
                        key={`ratingOpt${index}`}
                        survey={survey}
                        handleChangeRating={handleChangeRating}
                      />
                    ) : survey?.survey_type === 'radio' ? (
                      <div className='flex flex-column p-2'>
                        <Radio.Group>
                          {survey?.survey_options?.map((option, index) => (
                            <Radio
                              onChange={handleRadioButtonObj}
                              key={`Radio${index}`}
                              value={option?.option_id}
                              id={survey?.survey_id}
                              type={survey?.survey_type}
                              name={`${survey?.survey_id}`}>
                              {option?.option_value}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </div>
                    ) : survey?.survey_type === 'checkbox' ? (
                      <div className='flex flex-column p-2'>
                        <Checkbox.Group>
                          {survey?.survey_options?.map((option, index) => (
                            <Checkbox
                              onChange={handleCheckboxButton}
                              key={`Checkbox${index}`}
                              value={option?.option_id}
                              id={survey?.survey_id}
                              type={survey?.survey_type}
                              name={`${survey?.survey_id}`}>
                              {option?.option_value}
                            </Checkbox>
                          ))}
                        </Checkbox.Group>
                      </div>
                    ) : null}
                  </Form.Item>
                </Col>
              )
            )
          )}

        {surveyData?.length > 0 && (
          <Fragment>
            <Col xs={24} sm={24}>
              <button type='submit'>
                {app?.fairLanguage?.save_and_exit
                  ? app?.fairLanguage?.save_and_exit
                  : 'Save & Exit'}
              </button>
            </Col>
          </Fragment>
        )}
      </Row>
    </Form>
  );
};

export default ExitServayForm;
