import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { useSelector } from 'react-redux';

function Timer({ date }) {
  const { app } = useSelector((state) => state);
  const { seconds, minutes, hours, days, restart } = useTimer({
    date,
    onExpire: () => console.warn('onExpire called'),
  });

  useEffect(() => {
    const time = new Date(date);
    restart(time);
  }, []);

  return (
    <div className='TimeStamps d-flex align-items-center align-self-center flex-wrap flex-row'>
      {days > 0 ? (
        <div>
          <span>{days}</span>
          <span>
            {app?.fairLanguage?.days ? app?.fairLanguage?.days : 'Days'}
          </span>
        </div>
      ) : null}
      {hours > 0 ? (
        <div>
          <span>{hours}</span>
          <span>
            {app?.fairLanguage?.hours ? app?.fairLanguage?.hours : 'Hours'}
          </span>
        </div>
      ) : null}
      {minutes > 0 ? (
        <div>
          <span>{minutes}</span>
          <span>
            {app?.fairLanguage?.minutes
              ? app?.fairLanguage?.minutes
              : 'Minutes'}
          </span>
        </div>
      ) : null}
      {seconds > 0 ? (
        <div>
          <span>{seconds}</span>
          <span>
            {' '}
            {app?.fairLanguage?.seconds
              ? app?.fairLanguage?.seconds
              : 'Seconds'}
          </span>
        </div>
      ) : null}
    </div>
  );
}

export default Timer;
