import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { notification } from "antd";

export default function NotificationTimer({ notificationData }) {
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const openedNotification = [];
  notification.config({
    duration: 6,
  });
  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const targetTime = new Date(notificationData.webinar_start_time);
      const endTime =
        new Date(notificationData.webinar_start_time).getTime() + 1200;
      if (
        currentTime >= targetTime &&
        currentTime.getTime() < endTime &&
        !openedNotification.includes(notificationData.webinar_id)
      ) {
        openNotification(notificationData);
        openedNotification.push(notificationData.webinar_id);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const openNotification = (notific) => {
    notification.open({
      message: (
        <h5>
          {notific?.webinar_title} :{" "}
          {app?.fairLanguage?.webinar_is_live_now
            ? app?.fairLanguage?.webinar_is_live_now
            : "Webinar Is Live Now"}
        </h5>
      ),
      description: `Click here to join webinar`,
      onClick: () =>
        notific?.webinar_type === "ms_team_direct" ||
        notific?.webinar_type === "ms_team" ||
        notific?.webinar_type === "link"
          ? window.open(`${notific?.webinar_link}`, "_blank", "noreferrer")
          : notific?.type === "live"
          ? history.push(`/${app?.fairName}/webinar/${notific?.webinar_id}`)
          : history.push(`/${app?.fairName}/webinar/${notific?.webinar_id}`),
    });
  };
  return <div>NotificationTimer</div>;
}
