import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { instanceWithOutAuth } from 'App';
import Spiner from 'common/Spiner';
import HtmlParser from 'react-html-parser';
import { setImageSrc } from 'common/common';
import './privacyPolicy.scss';

function PrivacyPolicy() {
  const { app } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [IsPrivacy, setIsPrivacy] = useState();
  const getPrivacyPolicyData = async (fairId,userId) => {
    try {
      setIsLoading(true);
      const result = await instanceWithOutAuth.get(
        `/auth/fair/privacy/${fairId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'requested-lang': app?.langId,
          },
        }
      );
      if (result.status === 200) {
        setIsLoading(false);
        setIsPrivacy(result?.data?.data?.privacy_policy);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setImageSrc('');
    if (app?.appLayout?.fair?.id) {
      getPrivacyPolicyData(app?.appLayout?.fair?.id);
    }
  }, []);

  return (
    <div className='PrivacyPolicy'>
      {isLoading ? <Spiner /> : HtmlParser(IsPrivacy)}
    </div>
  );
}
export default PrivacyPolicy;
