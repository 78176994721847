import React, { Fragment, useEffect, useState } from 'react'
import { Form, Input, Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { instanceWithAuth } from 'App'
import { ReactTostify } from 'components/reactToastify/ReactTostify'
import AppRecaptcha from 'common/recaptcha/AppRecaptcha'
import { setStylingLayout } from 'common/common'
import { setMobileBtnColor } from 'common/common'
import { Divider } from 'antd'
import IsFairLive from 'common/IsFairLive'
import {
  addUser,
  changeAuthDraw,
  handleRegisterBtn,
  getHallLangApi,
  resetPreviousFairDetails,
} from 'redux/app/appSlice'
import { handleResetCurrentSelectedData } from 'redux/dashboard/dashboardSlice'
import './registerForm.scss'
import './loginForm.scss'

const LoginForm = ({ setIsLogin }) => {
  const dispatch = useDispatch()
  const { app } = useSelector((state) => state)
  const history = useHistory()
  const [isNotRobot, setIsNotRobot] = useState(false)
  const [isErrorRobot, setIsErrorRobot] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [disableBtn, setDisableBtn] = useState(false)
  let fEncodeId = window.btoa(app?.appLayout?.data?.fair?.id)
  let fairSettingOptions = app?.appLayout?.fair?.fair_setting?.options
  let fairOptions = app?.appLayout?.fair?.options
  const fairData = JSON.parse(localStorage.getItem('fairData'))
  let isFairLive =
    app?.appLayout?.fair?.fairTiming &&
    IsFairLive(
      app?.appLayout?.fair?.fairTiming?.date,
      app?.appLayout?.fair?.fairTiming?.fair_start,
      app?.appLayout?.fair?.fairTiming?.fair_end,
      app?.appLayout?.fair?.dry_run
    )
  const onFinish = (values) => {
    // if (isNotRobot) {
    loginUser(values)
    //   setIsErrorRobot(false);
    // } else {
    //   setIsErrorRobot(true);
    // }
  }
  const handleResetPassword = () => {
    history.push(`/password/recover/${fEncodeId}`)
    dispatch(changeAuthDraw(false))
  }

  const loginUser = async (values) => {
    const userData = {
      fair_id: app?.appLayout?.data?.fair?.id,
      // fair_info: 'Y',
      ...values,
      ...JSON.parse(localStorage.getItem('fairData')),
    }
    try {
      setIsLoading(true)
      const res = await instanceWithAuth.post(
        '/front/login',
        { ...userData },
        {
          headers: {
            'requested-lang': app?.langId,
          },
        }
      )
      // if (res.status === 503) {
      //   history.push(`/${app?.appLayout?.fair?.short_name}/503`);
      // }
      if (res.status === 200) {
        dispatch(resetPreviousFairDetails())
        dispatch(handleResetCurrentSelectedData())
        let translations =
          res.data?.data?.user?.fair_info?.data?.fair?.fair_translations
        let localLang = JSON.parse(localStorage.getItem('lang'))
        let defaultLanguage
        let languageId = res.data?.data?.user?.fair_info?.data?.fair
          ?.default_language
          ? res.data?.data?.user?.fair_info?.data?.fair?.default_language
              ?.auto_id
          : 0
        if (translations && localLang) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) => +filterLanguage.code === +localLang?.value
            )
          defaultLanguage = filterLanguages[0].keywords
        }
        if (translations && !localLang) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) => +filterLanguage.code === +languageId
            )
          defaultLanguage = filterLanguages[0].keywords
        }
        let userData = {
          ...res.data?.data?.user,
          fair_info: res.data?.data?.user?.fair_info?.data,
          authToken: res?.data?.data?.access_token,
          defaultLanguage: defaultLanguage,
        }
        localStorage.setItem(
          'fairTranslation',
          JSON.stringify(
            res.data?.data?.user?.fair_info?.data?.fair?.fair_translations
          )
        )
        dispatch(addUser(userData))
        ReactTostify(
          'success',
          app?.fairLanguage?.trans_string_you_have_successfully_login
            ? app?.fairLanguage?.trans_string_you_have_successfully_login
            : 'You Have Successfully Login'
        )
        let data = {
          fair_id: app?.appLayout?.fair?.id,
          authToken: res?.data?.data?.access_token,
          langId: app.langId,
        }
        dispatch(getHallLangApi(data))
        setStylingLayout(res.data?.data?.user?.fair_info?.data?.fair?.options)
        if (
          res.data?.data?.user?.fair_info?.data?.fair?.options
            ?.mobile_color_option == 1
        ) {
          setMobileBtnColor(
            res.data?.data?.user?.fair_info?.data?.fair?.options
          )
        }
        dispatch(changeAuthDraw(false))
        localStorage.setItem(
          'theme',
          JSON.stringify(res.data?.data?.user?.fair_info?.data)
        )
        localStorage.setItem('isLogin', true)
        if (
          app?.appLayout?.fair?.match_algorithm === '1' &&
          !app?.appLayout?.isTakeTest
        ) {
          history.push(`/${app?.fairName}/career/test`)
        } else if (
          app?.loginFromStand &&
          app?.appLayout?.fair?.match_algorithm === '1' &&
          res.data?.data?.user?.is_take_test === 0
        ) {
          history.push(`/${app?.fairName}/career/test`)
        } else if (app?.loginFromStand && !isFairLive) {
          history.push(`/${app?.fairName}/home`) 
        } else if (!app?.meetingThroughLink && !app?.loginFromStand) {
          history.push(`/${app?.fairName}/home`) 
        }
      }
      // if (res.status === 401) {
      //   ReactTostify(
      //     'error',
      //     res?.data?.msg ? res?.data?.msg : 'Credentials not valid'
      //   );
      // }
    } catch (error) {
      setIsLoading(false)
      if (error?.response?.data?.msg) {
        setDisableBtn(true)
        setTimeout(() => setDisableBtn(false), 5000)
        if (error?.response?.data?.code == 401) {
          ReactTostify(
            'error',
            app?.fairLanguage?.trans_credentials_not_valid
              ? app?.fairLanguage?.trans_credentials_not_valid
              : 'Credentials not valid'
          )
        }
        if (error?.response?.data?.code == 403) {
          ReactTostify(
            'error',
            //  app?.fairLanguage?.trans_credentials_not_valid
            //    ? app?.fairLanguage?.trans_credentials_not_valid
            //    :
            error?.response?.data?.msg
          )
        }
        if (error?.response?.data?.code == 404) {
          ReactTostify(
            'error',
            //  app?.fairLanguage?.trans_credentials_not_valid
            //    ? app?.fairLanguage?.trans_credentials_not_valid
            //    :
            error?.response?.data?.msg
          )
        }
      } else {
        ReactTostify('error', 'Something went wrong. Please try again!')
      }
    }
  }

  const checkRobot = (robotVal) => {
    if (robotVal) {
      setIsErrorRobot(false)
      setIsNotRobot(robotVal)
    } else {
      setIsErrorRobot(false)
    }
  }
  useEffect(() => {
    return () => setIsLogin(false)
  }, [app?.langId])
  return (
    <>
      <div className="LoginFormWrapper">
        <div className="formFooter">
          {app.isLoginBtn &&
          !app?.appLayout?.fair?.options?.disable_registration_front ? (
            <Fragment>
              <h6 className="footerLogin">
                {`${app?.fairLanguage?.dont_have_an_account}`}
              </h6>
              <Button
                type="button"
                className="buttonSignup"
                onClick={() => {
                  dispatch(handleRegisterBtn(true))
                }}
              >
                {app?.fairLanguage?.sign_up}
              </Button>
            </Fragment>
          ) : (
            ''
          )}
        </div>
        <Divider />
        {fairOptions?.disable_login_front === 0 && (
          <Form
            name="basic"
            className="loginForm"
            // labelCol={{
            //   span: 24,
            // }}
            // wrapperCol={{
            //   span: 24,
            // }}
            initialValues={{
              remember: false,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              label={app?.fairLanguage?.email}
              className="PhoneLabel"
              name="email"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value) {
                      let emailformat =
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                      if (value.match(emailformat)) {
                        if (value.length < 50) return Promise.resolve()
                        return Promise.reject(
                          new Error(
                            `${app?.fairLanguage?.email} characters must be less then 50!`
                          )
                        )
                      }
                      return Promise.reject(
                        new Error(
                          app?.fairLanguage
                            ?.trans_string_please_enter_valid_email
                            ? app?.fairLanguage
                                ?.trans_string_please_enter_valid_email
                            : 'Please enter a valid email!'
                        )
                      )
                    }
                    return Promise.reject(
                      new Error(app?.fairLanguage?.trans_please_enter_email)
                    )
                  },
                }),
              ]}
            >
              <div className="DFlex">
                <Input
                  type="email"
                  placeholder={app?.fairLanguage?.email_address}
                  size="large"
                />
              </div>
            </Form.Item>
            <Form.Item
              label={app?.fairLanguage?.password}
              className="PhoneLabel"
              name="password"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value) {
                      if (value.length < 50) return Promise.resolve()
                      return Promise.reject(
                        new Error(
                          `${app?.fairLanguage?.password} characters must be less then 50!`
                        )
                      )
                      // let passwordFormat =
                      //   /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[@$!%*#?&])(?=.*[a-zA-Z]).{8,}$/;
                      // if (value.match(passwordFormat)) {
                      //   return Promise.resolve();
                      // }
                      // return Promise.reject(
                      //   new Error(
                      //     'Minimum eight allowed, at least one alphabet letter, one number and one special character!'
                      //   )
                      // );
                    }
                    return Promise.reject(
                      new Error(app?.fairLanguage?.trans_please_enter_password)
                    )
                  },
                }),
              ]}
            >
              <div className="passwordLabel">
                <Input.Password placeholder={app?.fairLanguage?.password} />
              </div>
            </Form.Item>
            <Button
              type="link"
              className="DFlex justify-content-end"
              onClick={() => {
                handleResetPassword()
                dispatch(changeAuthDraw(false))
              }}
              style={{
                marginLeft: '63%',
                marginTop: '0px',
                fontSize: '12px',
                width: '38%',
              }}
            >
              {app?.fairLanguage?.forget_password
                ? app?.fairLanguage?.forget_password
                : 'Forget Your Password ?'}
            </Button>
            {/* <Form.Item>
              <AppRecaptcha className='recapcha' setIsNotRobot={checkRobot} />
              {isErrorRobot ? (
                <p className='my-4 text-danger PhoneLabel'>
                  {app?.fairLanguage?.trans_string_please_validate_recpthca
                    ? app?.fairLanguage?.trans_string_please_validate_recpthca
                    : 'Please validate the recpthca'}
                </p>
              ) : null}
            </Form.Item> */}
            <Form.Item>
              <Button
                className="subBtnLogin"
                htmlType="submit"
                disabled={disableBtn ? true : false}
              >
                {isLoading ? (
                  <div className="AuthSpinerDiv DFlex justify-content-center">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 20, color: 'white' }}
                          spin
                        />
                      }
                    />
                    <p style={{ color: 'white' }}>
                      {app?.fairLanguage?.please_wait}...
                    </p>
                  </div>
                ) : (
                  app?.fairLanguage?.login
                )}
              </Button>
            </Form.Item>
          </Form>
        )}
        {(() => {
          let ssoArr = [64, 87, 67, 94, 119, 158, 115, 181, 201, 69, 212]
          if (
            app?.appLayout?.fairTiming?.app_region === 'uk' &&
            ssoArr.includes(app?.appLayout?.fair?.id)
          ) {
            return (
              <>
                <div
                  className="custom-control custom-checkbox"
                  style={{ paddingBottom: '10px' }}
                >
                  <div
                    className="register-parivacy form-check"
                    style={{
                      fontSize: '13px',
                      marginTop: '7px',
                      marginLeft: '-20px',
                    }}
                  >
                    <input
                      name="parivacy"
                      type="checkbox"
                      id="parivacy"
                      className="form-check-input"
                      // onChange={handlePrivacy}
                      // checked={props.isPrivacy}
                    />
                    <label
                      type="checkbox"
                      className="form-check-label"
                      style={{ cursor: 'pointer' }}
                    >
                      {app?.fairLanguage?.i_agree_with}{' '}
                      <span
                        style={{ color: 'blue' }}
                        // onClick={() => props.handleShowPrivacyModel()}
                      >
                        {app?.fairLanguage?.privacy_policy_key}
                      </span>
                    </label>
                  </div>
                  <div className="invalid-feedback invalid-privacy">
                    {/* {!props.isPrivacy
                    ? app?.fairLanguage
                        ?.trans_string_acknowledgement_privacy_policy_required
                      ? app?.fairLanguage
                          ?.trans_string_acknowledgement_privacy_policy_required
                      : 'Privacy Policy must be required'
                    : ''} */}
                  </div>
                </div>
                <button
                  className="g-recaptcha button button--lg main-form-button button--uppercase button--success"
                  id="Accenture"
                  style={
                    fairSettingOptions?.button_background_color_front && {
                      background:
                        fairSettingOptions?.button_background_color_front,
                      color: fairSettingOptions?.button_inner_color_front,
                      border: fairSettingOptions?.button_background_color_front,
                      marginTop: '10px',
                      lineHeight: '17px',
                      // opacity: !props.isPrivacy ? 0.7 : 1,
                    }
                  }
                  // disabled={!props.isPrivacy ? true : false}
                  // onClick={login}
                >
                  Log In with Accenture
                  <small style={{ display: 'block', fontSize: '10px' }}>
                    Confidential
                  </small>
                </button>
              </>
            )
          }
        })()}
      </div>
    </>
  )
}

export default LoginForm
