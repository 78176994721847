import { useState, useEffect } from "react";
import {useMsal,useAccount} from "@azure/msal-react";

const useFetchToken = (url) => {
    const {instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {})

    const getToken = async () => {
        const tokenRequest = {
            account: account, // This is an example - Select account based on your app's requirements
            scopes: ["openid", "profile", "offline_access", "gql"],
            extraQueryParameters: {
                identity_provider: "microsoft"
            }
        }
    
        const token = await instance.acquireTokenSilent(tokenRequest);
        return token.accessToken
    }

  
    return {getToken};
  };
  
  export default useFetchToken;