import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instanceWithOutAuth } from "App";

const initialState = {
  isLoading: false,
  loadMoreTab: {
    isLoadMore: false,
    tabName: "profile",
  },
  questionaireData: [],
  questionaireError: null,
  currentStep: 0,
  subMinSelected: true,
  subquestionaireData: undefined,
  subCurrentSelectedData: [],
};

export const getQuestionaireData = createAsyncThunk(
  "getQuestionaireData",
  async (userData) => {
    const data = {
      candidate_id: userData?.userId,
      fair_id: userData?.fairId,
    };
    try {
      const response = await instanceWithOutAuth.post(
        `/fair/career/test/show`,
        data,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("accessToken")
            )}`,
            "requested-lang": userData?.langId,
          },
        }
      );
      if (response.status === 200) {
        return response?.data?.data?.questionList;
      }
    } catch (error) {
      throw new Error(
        error?.response?.data?.msg
          ? error?.response?.data?.msg
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : "Something went wrong!"
      );
    }
  }
);

const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState,
  reducers: {
    toggleLoading: (state, action) => {
      state.isLoading = action?.payload;
    },
    handleStep: (state, action) => {
      state.currentStep = action?.payload;
    },
    setLoadMoreTab: (state, action) => {
      state.loadMoreTab.isLoadMore = action?.payload?.isLoadMore;
      state.loadMoreTab.tabName = action?.payload?.tabName;
    },
    handleQuestionaireData: (state, action) => {
      state.isLoading = false;
      state.questionaireData = action?.payload;
    },
    handleSubQuestionaireData: (state, action) => {
      state.subquestionaireData = action?.payload;
    },
    handleSubCurrentSelectedData: (state, action) => {
      state.subCurrentSelectedData = action?.payload;
    },
    handleResetCurrentSelectedData: (state) => {
      state.subCurrentSelectedData = [];
    },

    handleSubMinSelected: (state, action) => {
      state.subMinSelected = action?.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getQuestionaireData.pending, (state) => {
        state.isLoading = true;
        state.questionaireError = null;
        state.questionaireData = [];
      })
      .addCase(getQuestionaireData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questionaireData = action.payload;
      })
      .addCase(getQuestionaireData.rejected, (state, action) => {
        state.isLoading = false;
        state.questionaireError = action.error;
      }),
});

export const {
  toggleLoading,
  setLoadMoreTab,
  handleQuestionaireData,
  handleStep,
  handleSubQuestionaireData,
  handleSubCurrentSelectedData,
  handleResetCurrentSelectedData,
  handleSubMinSelected,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
