import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const ToolTip = () => {
  const { app } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleClick = () => {
    history.push(`/${app?.fairName}/career/test`);
  };

  return (
    <div>
      {app?.appLayout?.isTakeTest === false && (
        <div className='box arrow-top'>
          <button className='tooltipBtn' onClick={handleClick}>
            <p className='innerText'>
              {app?.fairLanguage?.trans_take_career_test
                ? app?.fairLanguage?.trans_take_career_test
                : `Please complete your career test`}
            </p>
          </button>
        </div>
      )}
    </div>
  );
};

export default ToolTip;
