import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { notification } from 'antd'
import { instanceWithAuth, instanceWithOutAuth } from 'App'
import { ReactTostify } from 'components/reactToastify/ReactTostify'
import NotificationTimer from './NotificationTimer'
import { useIsAuthenticated } from '@azure/msal-react'

const WebinarNotification = () => {
  const { app } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [webinarsData, setWebinarsData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [notificationDataList, setNotificationDataList] = useState([])
  const isAuthenticated = useIsAuthenticated()

  let history = useHistory()
  let currentDate = new Date()
  useEffect(() => {
    const data = {
      fair_id: app?.appLayout?.fair?.id,
      candidate_id: app?.user?.id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    if (isAuthenticated) {
      notificationsData(data)
    }
  }, [isAuthenticated])
  const notificationsData = async (data) => {
    setIsLoading(true)
    // try {
    //   const response = await instanceWithAuth.post(
    //     `/fair/fairimportantnotification`,
    //     data,
    //     {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'requested-lang': app?.langId,
    //       },
    //     }
    //   )
    //   if (response.status === 200) {
    //     setIsLoading(false)
    //     setIsNotification(!isNotification)
    //     setWebinarsData(response?.data?.data?.fair_webinars)
    //   }
    // } catch (error) {
    //   setIsLoading(false)
    //   ReactTostify(
    //     'error',
    //     error?.response?.data?.msg
    //       ? error?.response?.data?.msg
    //       : error?.response?.data?.message
    //       ? error?.response?.data?.message
    //       : 'Something went wrong!'
    //   )
    // }
  }
  return (
    <div style={{ display: 'none' }}>
      {webinarsData?.map((notification, index) => (
        <NotificationTimer
          notificationData={notification}
          key={index}
        ></NotificationTimer>
      ))}
    </div>
  )
}

export default WebinarNotification
