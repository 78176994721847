import useConfig from "customHooks/useConfig";
import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'
const CustomMsalProvider = (props) => {
    const [config] = useConfig();
    
    if(config !== undefined){
        // MSAL configuration
        const configuration = {config};
        const pca = new PublicClientApplication(config);
        return <MsalProvider instance={pca}>{props.children}</MsalProvider>;
    }else{
        return <>Loading...</>;
    }
    
   
    
   
};

export default CustomMsalProvider