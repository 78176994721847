import { instanceWithOutAuth } from 'App'
import { instanceWithAuth } from 'App'
import axios from 'axios'
import {
  detectBrowser,
  setStylingLayout,
  setMobileBtnColor,
} from 'common/common'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { isMobileOnly, isTablet, isBrowser } from 'react-device-detect'
import { Alert } from 'antd'
import { useSelector } from 'react-redux'

const initialState = {
  appLayout: null,
  appPage: null,
  fairClose: false,
  fairLanguage: null,
  fairLanguageId: null,
  isLogin: false,
  hallId: null,
  getHallLang: {},
  webinarType: '',
  meetingToReception: false,
  hallToReception: false,
  hallToWebinar: false,
  standToReception: false,
  standToWebinar: false,
  webinarToReception: false,
  webinarToMainHall: false,
  conferenceToReception: false,
  conferenceToMainHall: false,
  networkinghallToReception: false,
  standToMainHall: false,
  user: null,
  userMsalAccount: {},
  fairName: null,
  isLoading: true,
  isNotFound: false,
  sideBarCollapsed: false,
  isAuthDraw: true,
  isRegisterBtn: false,
  isLoginBtn: false,
  profilePending: false,
  isSidebarActiveTab: false,
  sidebarActiveTab: '',
  showAppLayout: true,
  fairCloseInfo: null,
  switchCompanyId: null,
  switchFair: false,
  meetingThroughLink: false,
  fairLoaded: false,
  fromStand: null,
  langId: 109,
  previousFairId: null,
  previousFairName: null,
  loginFromStand: false,
  loginFromStandId: null,
  networkingData: null,
  clickedLogout: false,
  persistRoute:"",
  unreadCount: 0,
};
export const getAppLayout = createAsyncThunk(
  'getAppLayout',
  async (apiData) => {
    let data
    let UserInfo
    let deviceType = 4
    if (isMobileOnly) {
      deviceType = 1
    } else if (isTablet) {
      deviceType = 2
    } else if (isBrowser) {
      deviceType = 3
    } else {
      deviceType = 4
    }
    if (apiData?.loginFair) {
      data = {
        ip: 'anonymous',
        location: `${'anonymous'}, ${'anonymous'}`,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        browser: detectBrowser(),
        platform: navigator.platform,
        short_name: apiData?.data?.fairName,
        fair_id: apiData?.data?.fairId,
        candidate_id: apiData?.data?.candidate_id,
        device: deviceType,
      }
    } else {
      data = {
        ip: 'anonymous',
        location: `${'anonymous'}, ${'anonymous'}`,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        browser: detectBrowser(),
        platform: navigator.platform,
        short_name: apiData?.data?.fairName,
        device: deviceType,
      }
    }

    try {
      const systemInfo = await axios.get(
        'https://ipapi.co/json/?key=ulRK9gwyboDOcoUqctHtM7AMTDiF9kNhetQD3GDTyrPmLQarS9',
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      if (systemInfo.status === 200) {
        UserInfo = systemInfo;
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if(!timeZone){
          timeZone = systemInfo?.data?.timezone
        }
        
        if (apiData?.loginFair) {
          data = {
            ip: systemInfo.data.ip,
            location: `${systemInfo.data.country_name}, ${systemInfo.data.city}`,
            timezone: timeZone,
            browser: detectBrowser(),
            platform: navigator.platform,
            short_name: apiData?.data?.fairName,
            fair_id: apiData?.data?.fairId,
            candidate_id: apiData?.data?.candidate_id,
            device: deviceType,
          }
        } else {
          data = {
            ip: systemInfo.data.ip,
            location: `${systemInfo.data.country_name}, ${systemInfo.data.city}`,
            timezone: timeZone,
            browser: detectBrowser(),
            platform: navigator.platform,
            short_name: apiData?.data?.fairName,
            device: deviceType,
          }
        }
      }
    } catch (errro) {
      try {
        let result = await instanceWithAuth.post(
          '/fair/show-by-shortname',
          data
        )

        if (result.status === 503) {
          let redirectUrl = `/event/maintainance/503/page`
          window.location = `${redirectUrl}`
        }

        if (result.status === 200 && result.data.status !== false) {
          setStylingLayout(result?.data?.data?.fair?.options)
          if (result?.data?.data?.fair?.options?.mobile_color_option == 1) {
            setMobileBtnColor(result?.data?.data?.fair?.options)
          }
          data.fair_id = result?.data?.data?.fair?.id
          data.userCity = UserInfo?.data?.city
          data.userCountry = UserInfo?.data?.country_name
          data.userPostalCode = UserInfo?.data?.postal
          data.ip = UserInfo?.data?.ip
          let translations = result?.data?.data?.fair?.fair_translations
          let localLang = JSON.parse(localStorage.getItem('lang'))
          let defaultLanguage
          let languageId = result.data?.data?.fair?.default_language
            ? result.data?.data?.fair?.default_language?.auto_id
            : 0
          if (translations && localLang) {
            let filterLanguages =
              translations?.length > 0 &&
              translations?.filter(
                (filterLanguage) => +filterLanguage.code === +localLang?.value
              )
            defaultLanguage = filterLanguages[0].keywords
          }
          if (translations && !localLang) {
            let filterLanguages =
              translations?.length > 0 &&
              translations?.filter(
                (filterLanguage) => +filterLanguage.code === +languageId
              )
            defaultLanguage = filterLanguages[0].keywords
          }
          localStorage.setItem('systemInfo', JSON.stringify(UserInfo))
          localStorage.setItem(
            'timeZone',
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )
          localStorage.setItem('fairData', JSON.stringify(data))
          localStorage.setItem(
            'fairTranslation',
            JSON.stringify(result.data?.data?.fair?.fair_translations)
          )
          localStorage.setItem(
            'fid',
            JSON.stringify(result.data?.data?.fair?.id)
          )
          return {
            fairInfo: result?.data?.data,
            defaultLanguage: defaultLanguage,
          }
        } else {
          let fairClose = { error: true, fairCloseInfo: result?.data?.data }
          return fairClose
        }
      } catch (error) {
        throw new Error('Something went wrong!')
      }
    }
    try {
      let result = await instanceWithAuth.post('/fair/show-by-shortname', data)

      if (result.status === 503) {
        let redirectUrl = `/event/maintainance/503/page`
        window.location = `${redirectUrl}`
      }

      if (result.status === 200 && result.data.status !== false) {
        setStylingLayout(result?.data?.data?.fair?.options)
        if (result?.data?.data?.fair?.options?.mobile_color_option == 1) {
          setMobileBtnColor(result?.data?.data?.fair?.options)
        }
        data.fair_id = result?.data?.data?.fair?.id
        data.userCity = UserInfo?.data?.city
        data.userCountry = UserInfo?.data?.country_name
        data.userPostalCode = UserInfo?.data?.postal
        data.ip = UserInfo?.data?.ip
        let translations = result?.data?.data?.fair?.fair_translations
        let localLang = JSON.parse(localStorage.getItem('lang'))
        let defaultLanguage
        let languageId = result.data?.data?.fair?.default_language
          ? result.data?.data?.fair?.default_language?.auto_id
          : 0
        if (translations && localLang) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) => +filterLanguage.code === +localLang?.value
            )
          defaultLanguage = filterLanguages[0].keywords
        }
        if (translations && !localLang) {
          let filterLanguages =
            translations?.length > 0 &&
            translations?.filter(
              (filterLanguage) => +filterLanguage.code === +languageId
            )
          defaultLanguage = filterLanguages[0].keywords
        }
        localStorage.setItem('systemInfo', JSON.stringify(UserInfo))
        localStorage.setItem(
          'timeZone',
          Intl.DateTimeFormat().resolvedOptions().timeZone
        )
        localStorage.setItem('fairData', JSON.stringify(data))
        localStorage.setItem(
          'fairTranslation',
          JSON.stringify(result.data?.data?.fair?.fair_translations)
        )
        localStorage.setItem(
          'fid',
          JSON.stringify(result.data?.data?.fair?.id)
        )
        return {
          fairInfo: result?.data?.data,
          defaultLanguage: defaultLanguage,
        }
      } else {
        let fairClose = { error: true, fairCloseInfo: result?.data?.data }
        return fairClose
      }
    } catch (error) {
      throw new Error('Something went wrong!')
    }
  }
)

export const getHallLangApi = createAsyncThunk(
  'getHallLang',
  async (userData) => {
    try {
      const response = await instanceWithOutAuth.get(
        `fair/halls/list/${userData?.fair_id}`,
        {
          headers: {
            Authorization: `Bearer ${userData?.authToken}`,
            'requested-lang': userData?.langId,
          },
        }
      )
      if (response?.status === 200) {
        return response?.data?.data
      }
    } catch (error) {
      throw new Error(
        error?.response?.data?.msg
          ? error?.response?.data?.msg
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : 'Something went wrong!'
      )
    }
  }
)

export const getUserWithToken = createAsyncThunk(
  'getUserWithToken',
  async (apiData) => {
    try {
      let result = await instanceWithOutAuth.post(
        '/candidate/show',
        { headers: { Authorization: apiData.token } },
        apiData
      )
      if (result.status === 200) {
        setStylingLayout(result.data?.fair_info?.fair?.options)
        if (result.data?.fair_info?.fair?.options?.mobile_color_option == 1) {
          setMobileBtnColor(result.data?.fair_info?.fair?.options)
        }
        localStorage.setItem('theme', JSON.stringify(result.data?.fair_info))
        localStorage.setItem('isLogin', true)
        return result.data
      }
    } catch (error) {
      console.log(error)
      throw new Error('Something went wrong!')
    }
  }
)

const appSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setWebinarType: (state, action) => {
      state.webinarType = action.payload
    },
    setMeetingToReception: (state, action) => {
      state.meetingToReception = action.payload
    },
    setHallToReception: (state, action) => {
      state.hallToReception = action.payload
    },
    setHallToWebinar: (state, action) => {
      state.hallToWebinar = action.payload
    },
    setStandToReception: (state, action) => {
      state.standToReception = action.payload
    },
    setStandToMainHall: (state, action) => {
      state.standToMainHall = action.payload
    },
    setStandToWebinar: (state, action) => {
      state.standToWebinar = action.payload
    },
    setWebinarToReception: (state, action) => {
      state.webinarToReception = action.payload
    },
    setWebinarToMainHall: (state, action) => {
      state.webinarToMainHall = action.payload
    },
    setConferenceToReception: (state, action) => {
      state.conferenceToReception = action.payload
    },
    setConferenceToMainHall: (state, action) => {
      state.conferenceToMainHall = action.payload
    },
    setNetworkingHallToReception: (state, action) => {
      state.networkinghallToReception = action.payload
    },
    setAppPage: (state, action) => {
      state.appPage = action.payload
    },
    setGoodiesCount: (state, action) => {
      state.appLayout.fairCandidateGoodiesCount = action.payload
    },
    setIsTakeTest: (state, action) => {
      state.appLayout.isTakeTest = action.payload
    },
    setFairLanguage: (state, action) => {
      state.fairLanguage = action.payload
    },
    toggleIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    toggleNotFound: (state, action) => {
      state.isNotFound = action.payload
    },
    togglePending: (state, action) => {
      state.profilePending = action.payload
    },
    toggleSidebarActiveTab: (state, action) => {
      state.isSidebarActiveTab = action.payload
    },
    setShowLayout: (state, action) => {
      state.showAppLayout = action.payload
    },
    setSidebarActiveTab: (state, action) => {
      state.sidebarActiveTab = action.payload
    },
    toggleWelcomeModal: (state, action) => {
      state.welcomeShowModal = action.payload
    },
    setHallId: (state, action) => {
      state.hallId = action.payload
    },
    setHallLang: (state, action) => {
      state.isLoading = false
      state.getHallLang = action.payload
    },
    removeUser: (state) => {
      state.isLogin = false
      state.user = null
      // state.appLayout = null
      state.previousFair = null
    },
    setUserInfo: (state, action) => {
      state.isLoading = false
      state.isLogin = true
      state.user = action.payload
    },
    addUser: (state, action) => {
      state.isLoading = false
      state.isLogin = true
      state.user = action.payload
      state.appLayout = action.payload?.fair_info
      state.fairLanguage = action.payload?.defaultLanguage
    },
    updateUserAccessToken: (state, action) => {
      state.user.authToken = action.payload
    },
    addUserMsalAccount: (state, action) => {
      state.userMsalAccount = action.payload
    },
    handleIsLoginBtnClicked: (state, action) => {
      state.isLoginBtnClicked = action.payload
    },
    changeSideBarCollapsed: (state, action) => {
      state.sideBarCollapsed = action.payload
    },
    changeAuthDraw: (state, action) => {
      state.isAuthDraw = action.payload
    },
    handleRegisterBtn: (state, action) => {
      state.isLoginBtn = false
      state.isRegisterBtn = action.payload
      state.isAuthDraw = true
    },
    handleLoginBtn: (state, action) => {
      state.isRegisterBtn = false
      state.isLoginBtn = action.payload
      state.isAuthDraw = true
    },
    setFairName: (state, action) => {
      state.fairName = action.payload
    },
    setCloseFairData: (state, action) => {
      state.fairCloseInfo = action.payload
    },
    setAppLayout: (state, action) => {
      state.isLoading = action.payload?.isLoading
      state.appLayout = action.payload?.fairInfo
      state.fairName = action.payload?.short_name
      state.fairCloseInfo = action.payload?.fairCloseInfo
      state.fairLanguage = action.payload?.defaultLanguage
    },
    setExitSurvey: (state, action) => {
      state.appLayout.isTakeSurvey = action.payload
    },
    setupdateLangId: (state, action) => {
      state.langId = action.payload
    },
    setFromScheduleTab: (state, action) => {
      state.fromStand = action.payload
    },
    setSwitchCompanyId: (state, action) => {
      state.switchCompanyId = action.payload
    },
    setSwitchFair: (state, action) => {
      state.switchFair = action.payload
    },
    setMeetingThroughLink: (state, action) => {
      state.meetingThroughLink = action.payload
    },
    setPreviousFairDetails: (state, action) => {
      state.previousFairId = action.payload?.previousFairId
      state.previousFairName = action.payload?.previousFairName
    },
    resetPreviousFairDetails: (state) => {
      state.previousFairId = null
      state.previousFairName = null
    },
    setloginFromStand: (state, action) => {
      state.loginFromStand = action.payload?.loginFromStand
      state.loginFromStandId = action.payload?.loginFromStandId
    },
    setNetworkingData: (state, action) => {
      state.networkingData = action.payload
    },
    handleClikLogout: (state, action) => {
      state.clickedLogout = action.payload
    },
    handlePersistRoute: (state, action) => {
      state.persistRoute = action.payload;
    },
    handlePersistRoute: (state, action) => {
      state.persistRoute = action.payload;
    },
    setUnreadCount: (state, action) => {
        state.unreadCount = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAppLayout.pending, (state) => {
        state.isLoading = true
        state.isError = null
        state.appLayout = null
        state.fairName = null
        state.fairCloseInfo = null
        state.fairLanguage = null
        state.fairClose = false
        // state.isNotFound = false;
      })
      .addCase(getAppLayout.fulfilled, (state, action) => {
        if (!action.payload?.error) {
          state.isLoading = false
          state.appLayout = action.payload?.fairInfo
          state.fairName = action.payload?.fairInfo?.fair?.short_name
          state.fairCloseInfo = action.payload?.fairCloseInfo
          state.fairLanguage = action.payload?.defaultLanguage
          state.fairLoaded = true
          // state.isNotFound = false;
        } else {
          state.isLoading = false
          state.fairClose = true
          state.fairName = action.payload.fairName
          state.fairCloseInfo = action.payload.fairCloseInfo
        }
      })
      .addCase(getAppLayout.rejected, (state, action) => {
        // state.isNotFound = true;
        // let redirectUrl = `/event/maintainance/503/page`;
        // window.location = `${redirectUrl}`;
        let theme = JSON.parse(localStorage.getItem('theme'))
        if (theme) {
          let defaultLanguage
          let translations = theme?.fair?.fair_translations
          if (translations && theme?.fair?.default_language) {
            for (const key in translations) {
              if (+theme?.fair?.default_language?.auto_id === +key) {
                defaultLanguage = translations[key]
              }
            }
          }
          localStorage.setItem(
            'fairTranslation',
            JSON.stringify(theme?.fair?.fair_translations)
          )
          setStylingLayout(theme?.fair?.options)
          if (theme?.fair?.options?.mobile_color_option == 1) {
            setMobileBtnColor(theme?.fair?.options)
          }
          state.isLoading = false
          state.isNotFound = true
          state.appLayout = theme
          state.fairLanguage = defaultLanguage
        } else {
          state.isLoading = false
          state.isNotFound = true
          state.isError = action.error
        }
      })
      .addCase(getUserWithToken.pending, (state) => {
        state.isLoading = true
        state.isError = null
      })
      .addCase(getUserWithToken.fulfilled, (state, action) => {
        state.isLoading = false
        state.isLogin = true
        state.user = action.payload
        state.appLayout = action.payload?.fair_info
      })
      .addCase(getUserWithToken.rejected, (state, action) => {
        state.isLoading = false
        state.isError = action.error
      })
      .addCase(getHallLangApi.pending, (state) => {
        state.isError = null
      })
      .addCase(getHallLangApi.fulfilled, (state, action) => {
        state.getHallLang = action.payload
      })
      .addCase(getHallLangApi.rejected, (state, action) => {
        state.isError = action.error
      }),
})

export const {
  changeSideBarCollapsed,
  setFairName,
  changeAuthDraw,
  toggleIsLoading,
  setAppLayout,
  addUser,
  updateUserAccessToken,
  addUserMsalAccount,
  handleIsLoginBtnClicked,
  removeUser,
  setHallId,
  setHallLang,
  setFairLanguage,
  setAppPage,
  toggleNotFound,
  setUserInfo,
  setGoodiesCount,
  setExitSurvey,
  handleRegisterBtn,
  handleLoginBtn,
  setWebinarType,
  setHallToReception,
  setHallToWebinar,
  setStandToReception,
  setStandToWebinar,
  setWebinarToReception,
  setWebinarToMainHall,
  togglePending,
  setSidebarActiveTab,
  toggleSidebarActiveTab,
  toggleWelcomeModal,
  setMeetingToReception,
  setConferenceToReception,
  setConferenceToMainHall,
  setupdateLangId,
  setShowLayout,
  setSwitchCompanyId,
  setSwitchFair,
  setFromScheduleTab,
  setIsTakeTest,
  setMeetingThroughLink,
  setPreviousFairDetails,
  resetPreviousFairDetails,
  setloginFromStand,
  setNetworkingData,
  setNetworkingHallToReception,
  handleClikLogout,
  handlePersistRoute,
  setUnreadCount,
  setStandToMainHall,
} = appSlice.actions

export default appSlice.reducer
