import React from "react";
import lazyRetry from "@tdotcode/react-lazy-retry";

const About = lazyRetry(() => import("pages/about/About"));
const Home = lazyRetry(() => import("pages/home/Home"));
const Landing = lazyRetry(() => import("pages/landingPage/LandingPage"));
const Dashboard = lazyRetry(() => import("pages/dashboard/Dashboard"));
const Meeting = lazyRetry(() => import("pages/meeting/Meeting"));
const Companies = lazyRetry(() => import("pages/companies/Companies"));
const GoodieBag = lazyRetry(() => import("pages/goodieBag/GoodieBag"));
const Help = lazyRetry(() => import("pages/help/Help"));
const MainHall = lazyRetry(() => import("pages/mainHall/MainHall"));
const ProductsAndServices = lazyRetry(() =>
  import('pages/productsAndServices/ProductsAndServices')
)
const ExibitorDetail = lazyRetry(() =>
  import('common/detailPage/ExibitorDetail')
)
const Speakers = lazyRetry(() => import('pages/speakers/Speakers'))
const Login = lazyRetry(() => import('pages/login/Login'))
const Logout = lazyRetry(() => import('pages/login/Logout'))
const SigninCallback = lazyRetry(() =>
  import('pages/signincallback/SigninCallback')
)
const NotFoundPage = lazyRetry(() => import('pages/404Page/NotFoundPage'))
const InternalServerError = lazyRetry(() =>
  import('pages/500Page/InternalServerError')
)
const UnderMaintainence = lazyRetry(() =>
  import('pages/503Page/UnderMaintainence')
)
const Faqs = lazyRetry(() => import('pages/faqs/Faqs'))
const StageConferenceAgenda = lazyRetry(() =>
  import('pages/stageConferenceAgenda/StageConferenceAgenda')
)

const SpeakerConferenceAgenda = lazyRetry(() =>
  import("pages/speakerConferenceAgenda/SpeakerConferenceAgenda")
);
const Scoreboard = lazyRetry(() => import("pages/scoreboard/Scoreboard"));
const Metaverse = lazyRetry(() => import("pages/metaverse/Metaverse"));
const Recruiter = lazyRetry(() => import("pages/Recruiters/Recruiters"));
const ConferenceAgenda = lazyRetry(() =>
  import('pages/conferenceAgenda/ConferenceAgenda')
)
const Conference = lazyRetry(() => import('pages/conferences/Conference'))
const CompaniesMap = lazyRetry(() =>
  import('pages/companies/maps/CompaniesMap')
)
const CandidateNetworkList = lazyRetry(() =>
  import('pages/candidateNetworkList/CandidateNetworkList')
)
const Courses = lazyRetry(() => import('pages/courses/Courses'))
const CourseDetail = lazyRetry(() =>
  import('pages/courses/courseDetail/CourseDetail')
)
const ProductsAndServiceDetail = lazyRetry(() =>
  import(
    'pages/productsAndServices/productsAndServiceDetail/ProductsAndServiceDetail'
  )
)
const QuestionnairesTab = lazyRetry(() =>
  import('components/dashboard/tabs/QuestionnairesTab')
)
const ResetPassword = lazyRetry(() =>
  import('pages/resetPassword/ResetPassword')
)
const ForgetPassword = lazyRetry(() =>
  import('pages/forgetPassword/ForgetPassword')
)
const Reception = lazyRetry(() => import('pages/reception/Reception'))
const FairClose = lazyRetry(() => import('pages/fairClose/FairClose'))
const Webinar = lazyRetry(() => import('pages/webinar/Webinar'))
const Stands = lazyRetry(() => import('pages/stands/Stands'))
const CandidateNetworkingTable = lazyRetry(() =>
  import('pages/candidateNetworkingTable/CandidateNetworkingTable')
)

const NetworkTableDetail = lazyRetry(() =>
  import('pages/candidateNetworkingTable/NetworkTableDetail')
)

const ExternalServay = lazyRetry(() =>
  import('components/exitServay/ExternalServay')
)
const SwitchFair = lazyRetry(() => import('pages/switchFair/SwitchFair'))
const StandPreview = lazyRetry(() => import('pages/standPreview/standpreview'))
const MarketingClick = lazyRetry(() =>
  import('components/marketing/HandleMarketingClick')
)
const NetworkingHall = lazyRetry(() =>
  import('pages/networkingHall/NetworkingHall')
)
const FairNotFound = lazyRetry(() => import('pages/fairNotFound/FairNotFound'))
const PersonalityTest = lazyRetry(() =>
  import('pages/personalityTest/PersonalityTest')
)
const AutoLogin = lazyRetry(() => import('pages/autoLogin/AutoLogin'))
const EventsCalendar = lazyRetry(() =>
  import('pages/EventsCalendar/EventsCalendar.jsx')
)
const VideoGallery = lazyRetry(() => import('pages/videoGallery/VideoGallery'))
const UserProfile = lazyRetry(() => import('pages/profile/UserProfile'))

const SecondaryRecruiters = lazyRetry(() =>
  import('pages/secondaryRecruiters/SecondaryRecruiters')
)
const WebinarListing = lazyRetry(() =>
  import('pages/webinarListing/WebinarListing')
)
const routes = [
  {
    id: 1,
    path: '/:fairName',
    component: Home,
  },
  {
    id: 2,
    path: '/:fairName/home/',

    component: Home,
  },
  {
    id: 3,
    path: '/:fairName/dashboard',
    component: Dashboard,
  },
  {
    id: 4,
    path: '/:fairName/about',
    component: About,
  },
  {
    id: 5,
    path: '/:fairName/speakers',
    component: Speakers,
  },
  {
    id: 6,
    path: '/:fairName/login',
    component: Login,
  },
  {
    id: 6,
    path: '/:fairName/logout',
    component: Logout,
  },
  {
    id: 6,
    path: '/:fairName/redirect',
    component: Login,
  },
  {
    id: 7,
    path: '/:fairName/partners',
    component: Companies,
  },
  {
    id: 8,
    path: '/:fairName/companies/map',
    component: CompaniesMap,
  },
  {
    id: 9,
    path: '/:fairName/products-services',
    component: ProductsAndServices,
  },
  {
    id: 9,
    path: '/:fairName/jobs',
    component: ProductsAndServices,
  },
  {
    id: 10,
    path: '/:fairName/goodies',
    component: GoodieBag,
  },
  {
    id: 11,
    path: '/:fairName/main-hall',
    component: MainHall,
    isAuthProtected: true,
  },
  {
    id: 12,
    path: '/:fairName/help',
    component: Help,
  },
  {
    id: 13,
    path: '/no-route/404/page/not/found',
    component: NotFoundPage,
    isStatic: true,
  },
  {
    id: 14,
    path: '/:fairName/500',
    component: InternalServerError,
  },
  {
    id: 15,
    path: '/:fairName/503',
    component: UnderMaintainence,
  },
  {
    id: 15,
    path: '/event/maintainance/503/page',
    component: UnderMaintainence,
  },
  {
    id: 16,
    path: '/:fairName/faqs',
    component: Faqs,
  },
  {
    id: 17,
    path: '/:fairName/conferences/agenda/list',
    component: ConferenceAgenda,
  },
  {
    id: 18,
    path: '/:fairName/conference/:stageId',
    component: Conference,
  },
  {
    id: 19,
    path: '/:fairName/courses',
    component: Courses,
  },
  {
    id: 20,
    path: '/:fairName/course/detail/:courseDetailId',
    component: CourseDetail,
  },
  {
    id: 21,
    path: '/:fairName/reception',
    component: Reception,
    isAuthProtected: true,
  },
  {
    id: 22,
    path: '/:fairName/close',
    component: FairClose,
    isStatic: true,
  },
  {
    id: 23,
    path: '/:fairName/webinar/:webinarId',
    component: Webinar,
    isAuthProtected: true,
  },
  {
    id: 24,
    path: '/:fairName/jobs/detail/:jobDetailId',
    component: ProductsAndServiceDetail,
  },
  {
    id: 25,
    path: '/:fairName/detail/:detailId',
    component: ExibitorDetail,
  },
  {
    id: 26,
    path: '/:fairName/stand/:standId',
    component: Stands,
    // isAuthProtected: true,
  },
  {
    id: 27,
    path: '/:fairName/recruiter/interview/room/:interviewId',
    component: Meeting,
    // isAuthProtected: true,
  },
  {
    id: 28,
    path: '/:fairName/career/test',
    component: QuestionnairesTab,
  },
  {
    id: 29,
    path: '/:fairName/network/candidates',
    component: CandidateNetworkList,
  },
  {
    id: 30,
    path: '/:fairName/networks/tables',
    component: CandidateNetworkingTable,
  },
  {
    id: 31,
    path: '/password/recover/:fairId',
    component: ForgetPassword,
  },
  {
    id: 32,
    path: '/password/find/:token',
    component: ResetPassword,
  },
  {
    id: 33,
    path: '/:fairName/stage/conference/agenda/list',
    component: StageConferenceAgenda,
  },
  {
    id: 34,
    path: '/:fairName/landing',
    component: Landing,
  },
  {
    id: 41,
    path: '/:fairName/networking',
    component: NetworkingHall,
  },
  {
    id: 42,
    path: '/fairNotFound/404',
    component: FairNotFound,
  },
  {
    id: 43,
    path: '/:fairName/personalityTest',
    component: PersonalityTest,
  },
  {
    id: 44,
    path: '/:fairName/auto_login',
    component: AutoLogin,
  },
  {
    id: 45,
    path: '/',
    component: EventsCalendar,
  },
  {
    id: 46,
    path: '/:fairName/videogallery',
    component: VideoGallery,
  },
  {
    id: 47,
    path: '/:fairName/videogallery',
    component: VideoGallery,
  },
  {
    id: 48,
    path: '/:fairName/UserProfile',
    component: UserProfile,
  },
  {
    id: 49,
    path: '/:fairName/recruiter/list',
    component: Recruiter,
  },
  {
    id: 50,
    path: '/:fairName/secondaryrecruiter/list',
    component: SecondaryRecruiters,
  },
  {
    id: 51,
    path: '/:fairName/webinars/list',
    component: WebinarListing,
  },
  {
    id: 52,
    path: '/:fairName/join/event',
    component: AutoLogin,
  },
  {
    id: 35,
    path: '/:fairName/network/table/room/:auto_id',
    exact: true,
    component: NetworkTableDetail,
  },
  {
    id: 36,
    path: '/:fair_name/survey/:candidate_id',
    exact: true,
    component: ExternalServay,
  },
  {
    id: 37,
    path: '/:fair_name/companystand/:standId',
    component: SwitchFair,
    isAuthProtected: true,
  },
  {
    id: 38,
    path: '/:fair_name/preview',
    component: StandPreview,
  },
  {
    id: 39,
    path: '/:fair_name/:channel_name',
    component: MarketingClick,
  },
  {
    id: 40,
    path: '/:fairName/s/agenda/list',
    component: SpeakerConferenceAgenda,
  },
  {
    id: 41,
    path: "/:fairName/score/board",
    exact: true,
    isAuthProtected: true,
    component: Scoreboard,
  },
  {
    id: 42,
    path: "/:fairName/virtual/room",
    exact: true,
    component: Metaverse,
  }
];

export default routes;
