import { Upload, Form } from 'antd';
import { useSelector } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
function CvUpload({ getCV, isCvError }) {
  const { app } = useSelector((state) => state);
  const beforeUpload = (file) => {
    getCV(file);
  };

  const handleRemove = (file) => {
    getCV(null);
  };

  return (
    <Form.Item
      name='cv'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Dragger
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        beforeUpload={beforeUpload}
        onRemove={handleRemove}
        maxCount={1}
        accept='.pdf, .doc, docx'>
        {/* <p className='ant-upload-text'>
          {app?.fairLanguage?.upload ? app?.fairLanguage?.upload : 'Upload'}{' '}
          {app?.fairLanguage?.cv ? app?.fairLanguage?.cv : 'CV'}
        </p>
        <p className='ant-upload-hint'>
          {app?.fairLanguage?.trans_only_upload_cv_format
            ? app?.fairLanguage?.trans_only_upload_cv_format
            : ' Only upload .doc, .docx and .pdf format'}
        </p> */}
        <p className='ant-upload-hint'>
          {' '}
          <UploadOutlined style={{ fontSize: '16px' }} />{' '}
          {app?.fairLanguage?.choose_file
            ? app?.fairLanguage?.choose_file
            : 'Choose File'}
        </p>
      </Dragger>
      {isCvError?.isError && getCV ? (
        <p style={{ color: 'red', fontSize: '11px' }}>
          {isCvError?.errorMessage}
        </p>
      ) : (
        <p style={{ color: 'white',fontWeight:'100',fontSize:'12px',marginTop:'2px' }}>
          {app?.fairLanguage?.trans_string_only_upload_doc_pdf_format
            ? app?.fairLanguage?.trans_string_only_upload_doc_pdf_format
            : ' Only upload .doc, .docx and .pdf format'}
        </p>
      )}
    </Form.Item>
  );
}

export default CvUpload;
