import React, { Fragment, useEffect, useState } from 'react'
import { Menu, ChevronLeft, Edit3 } from 'react-feather'
import { useToasts } from 'react-toast-notifications'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Layout, Select } from 'antd'
import { Dropdown, Menu as ProfileMenu, Modal, Button } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import $ from 'jquery'
import {
  getQuestionaireData,
  handleStep,
  setLoadMoreTab,
} from 'redux/dashboard/dashboardSlice'
import {
  useIsAuthenticated,
  useAccount,
  useMsalAuthentication,
  useMsal,
} from '@azure/msal-react'
import {
  InteractionType,
} from '@azure/msal-browser'

import {
  setFairLanguage,
  setupdateLangId,
  handleRegisterBtn,
  handleClikLogout,
  removeUser
} from 'redux/app/appSlice'
import { instanceWithAuth} from 'App'
import { ReactTostify } from 'components/reactToastify/ReactTostify'
import LoginDrawer from 'drawer/LoginDrawer'
import RegisterDrawer from 'drawer/RegisterDrawer'
import MobileSidebarDrawer from 'drawer/MobileSidebarDrawer'
import ExitServayModal from 'modals/exitServay/ExitServayModal'
import HeaderText from './HeaderText'
import HeaderNotifications from './HeaderNotifications'
import HeaderHallsTabs from './HeaderHallsTabs'
import ImageUpload from 'layouts/sidebar/ImageUpload'

import './header.scss'

const NavHeader = ({ collapsedState, toggle }) => {
  const { app, dashboard } = useSelector((state) => state)
  const { login, result, error } = useMsalAuthentication()
  const dispatch = useDispatch()
  const history = useHistory()
  const [upImg, setUpImg] = useState(null)
  const { addToast } = useToasts()
  const [visible, setVisible] = useState(false)
  const [avatar, setAvatar] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [isLive, setIsLive] = useState(true)
  const [exitServayModal, setExitServayModal] = useState(false)
  const [isNotification, setIsNotification] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRegister, setIsRegister] = useState(false)
  const [webinarsData, setWebinarsData] = useState([])
  const [impNotiData, setImpNotiData] = useState([])
  const [openNotification, setOpenNotification] = useState(false)
  const [languages, setLanguages] = useState([])
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [notificationDataList, setNotificationDataList] = useState([])
  const [defaultLanguage, setDefaultLanguage] = useState({
    label: 'Default',
    value: 0,
  })
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  let filterLang = languages?.filter((lang) => {
    if (lang?.label !== 'Default') {
      return lang
    }
  })
  const handleCloseNoti = () => {
    return setOpenNotification(!openNotification)
  }

  const initializeSignIn = () => {
    instance.loginRedirect({scopes: ["openid", "profile", "offline_access", "gql"],
      extraQueryParameters: {
        identity_provider: "microsoft"
      }}).catch(e => console.log(e))
  }
  

  const initializeSignOut = () => {
    instance.logoutRedirect()
  }

  const handleCloseLogin = () => {
    setIsLogin(!isLogin)
  }

  const successFullyLogout = () => {
    $('.chatMainContainer,.chat-popup').hide()
    localStorage.setItem('isLogin', false)
    dispatch(removeUser())
    // history.push(
    //   `/${
    //     app?.fairName ? app?.fairName : app?.appLayout?.fair?.short_name
    //   }/home`
    // )
  }

  let fairOptions = app?.appLayout?.fair?.options
  const logoutUser = () => {
    dispatch(handleClikLogout(true))
    if (
      app?.appLayout?.fair?.options?.survey_enable == 1 &&
      currentDate >= startTime &&
      currentDate < endTime
    ) {
      if (app?.appLayout?.isTakeSurvey == true) {
        successFullyLogout()
      } else {
        failedLogout()
      }
    } else {
      successFullyLogout()
      dispatch(handleStep(dashboard?.currentStep - dashboard?.currentStep))
    }
    // history.push(`/${app?.appLayout?.fair?.short_name}/home`);
  }

  const modalClose = () => {
    setUpImg(null)
    setAvatar(false)
  }
  const failedLogout = () => {
    if (app?.appLayout?.fair?.options?.disable_force_exit_survey == 0) {
      setExitServayModal(!exitServayModal)
      addToast(
        app?.fairLanguage?.trans_string_before_leaving_platform_complete_survey
          ? app?.fairLanguage
              ?.trans_string_before_leaving_platform_complete_survey
          : 'Before leaving the plaform kindly complete the survey',
        {
          appearance: 'info',
          autoDismiss: true,
        }
      )
    } else {
      successFullyLogout()
    }
  }
  let currentDate = new Date()
  let startTime = new Date(app?.appLayout?.fair?.fairTiming?.fair_start)
  let endTime = new Date(app?.appLayout?.fair?.fairTiming?.fair_end)
  const notificationsData = async (data) => {
    setIsLoading(true)
    // try {
    //   const response = await instanceWithAuth.post(
    //     `/fair/fairimportantnotification`,
    //     data,
    //     {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'requested-lang': app?.langId,
    //       },
    //     }
    //   )
    //   if (response.status === 200) {
    //     setIsLoading(false)
    //     setIsNotification(!isNotification)
    //     setWebinarsData(response?.data?.data?.fair_webinars)
    //     let currentDate = new Date()
    //     let filteredNotification = []

    //     if (
    //       app?.appLayout?.fair?.options
    //         ?.disable_webinars_notifications_front === 0 &&
    //       currentDate >= startTime &&
    //       currentDate < endTime
    //     ) {
    //       response?.data?.data?.fair_webinars?.map((notification) => {
    //         if (
    //           currentDate >= new Date(notification.webinar_start_time) &&
    //           currentDate < new Date(notification.webinar_end_time)
    //         ) {
    //           filteredNotification.push(notification)
    //         }
    //       })
    //     }
    //     setNotificationDataList(filteredNotification)
    //     setImpNotiData(response?.data?.data?.important_notification)
    //   }
    // } catch (error) {
    //   setIsLoading(false)
    //   ReactTostify(
    //     'error',
    //     error?.response?.data?.msg
    //       ? error?.response?.data?.msg
    //       : error?.response?.data?.message
    //       ? error?.response?.data?.message
    //       : 'Something went wrong!'
    //   )
    // }
  }

  const changeLanguage = (language) => {
    let translations = app?.appLayout?.fair?.fair_translations
    if (translations?.length > 0) {
      let filterLanguages = translations.filter(
        (filLanguage) => +filLanguage.code === +language?.value
      )
      localStorage.setItem('lang', JSON.stringify(language))
      setDefaultLanguage(language.label)
      dispatch(setupdateLangId(language.value))
      dispatch(setFairLanguage(filterLanguages[0]?.keywords))
    }
  }

  const viewProfile = () => {
    dispatch(
      setLoadMoreTab({
        isLoadMore: false,
        tabName: 'default_profile',
      })
    )
    history.push(
      `/${
        app?.fairName ? app?.fairName : app?.appLayout?.fair?.short_name
      }/dashboard`
    )
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
  }, [])
  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth)
  }
  const isMobile = windowWidth <= 876
  useEffect(() => {
    let SSOLogin = JSON.parse(localStorage.getItem('SSOLogin'))
    if (SSOLogin === 'Login') setIsLogin(true)
    if (app?.appLayout) {
      let currentDate = new Date()
      let startTime = new Date(app?.appLayout?.fair?.fairTiming?.fair_start)
      let endTime = new Date(app?.appLayout?.fair?.fairTiming?.fair_end)

      if (currentDate >= startTime && currentDate < endTime) setIsLive(true)
      else setIsLive(false)

      if (app?.appLayout?.fair?.fair_languages)
        setLanguages(
          app?.appLayout?.fair?.fair_languages?.map((language) => {
            return {
              label: language?.lang_name,
              value: language?.auto_id,
              langCode: language?.lang_code,
            }
          })
        )
      let localLang = JSON.parse(localStorage.getItem('lang'))
      if (localLang) setDefaultLanguage(localLang)
      if (app?.appLayout?.fair?.default_language && !localLang) {
        setDefaultLanguage({
          label: app?.appLayout?.fair?.default_language?.lang_name,
          value: app?.appLayout?.fair?.default_language?.auto_id,
          key: app?.appLayout?.fair?.default_language?.auto_id,
        })
      }
    }
    if (isAuthenticated && app?.user?.id && app?.appLayout?.fair?.id) {
      const data = {
        fair_id: app?.appLayout?.fair?.id,
        candidate_id: app?.user?.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
      notificationsData(data)
    }
  }, [app?.appLayout, app?.langId])
  useEffect(() => {
    let userData = {
      fairId: app?.appLayout?.fair?.id,
      userId: app?.user?.id,
      langId: app?.langId,
    }
    if (
      isAuthenticated &&
      window.location.href ==
        `/${
          app?.fairName ? app?.fairName : app?.appLayout?.fair?.short_name
        }/career/test`
    )
      dispatch(getQuestionaireData(userData))
    dispatch(handleStep(dashboard?.currentStep - dashboard?.currentStep))
  }, [app?.langId])

  useEffect(()=>{
    const queryParams = new URLSearchParams(window.location.search)
    const idTokenHint = queryParams.get("id_token_hint")
    if(idTokenHint){
      instance.loginRedirect({
        extraQueryParameters: {'id_token_hint': idTokenHint}
      });
    }
  },[])

  return (
    <Fragment>
      <Layout.Header className="site-layout-background header">
        <div
          className={`toggleBtn d-none d-lg-block toggleBtn${app?.appLayout?.fair?.id}`}
        >
          {React.createElement(collapsedState ? Menu : Menu, {
            className: 'trigger',
            onClick: toggle,
          })}
        </div>
        <div
          className={`toggleBtn d-block d-lg-none toggleBtn${app?.appLayout?.fair?.id}`}
        >
          {React.createElement(collapsedState ? Menu : Menu, {
            className: 'trigger',
            onClick: () => setVisible(true),
          })}
        </div>
        {isMobile && app?.appPage != 'home' ? (
          <div className="HeaderBtnDiv  d-md-flex flex-row flex-wrap">
            <button onClick={history.goBack}>
              <ChevronLeft /> {app?.fairLanguage?.back}
            </button>
          </div>
        ) : (
          <>
            {app?.appLayout && (
              <HeaderText
                isLive={isLive}
                app={app}
                isDisableDate={fairOptions?.disable_start_end_date}
              />
            )}
          </>
        )}
        <HeaderHallsTabs />
        <div className="RightSide">
          {app?.appLayout?.fair?.options
            ?.disable_webinars_notifications_front === 0 &&
            isAuthenticated &&
            app?.appLayout?.isTakeTest &&
            currentDate >= startTime &&
            currentDate < endTime && (
              <>
                <Dropdown
                  overlay={
                    <HeaderNotifications
                      app={app}
                      notificationsData={notificationsData}
                      impNotiData={impNotiData}
                      webinarsData={webinarsData}
                      isNotification={isNotification}
                      notificationDataList={notificationDataList}
                      viewProfile={viewProfile}
                      handleCloseNoti={handleCloseNoti}
                    />
                  }
                  trigger={['click']}
                  visible={openNotification}
                  placement="bottomRight"
                >
                  <Badge
                    size="small"
                    count={
                      app?.profilePending &&
                      app?.appLayout?.fair?.fair_setting?.important_notification
                        ? notificationDataList.length + 2
                        : app?.appLayout?.fair?.fair_setting
                            ?.important_notification
                        ? notificationDataList.length + 1
                        : app?.profilePending
                        ? notificationDataList.length + 1
                        : notificationDataList.length
                    }
                  >
                    <button type="button" onClick={handleCloseNoti}>
                      {notificationDataList.length > 0 ? (
                        <div className="fa-2x">
                          <i
                            style={{ color: 'white' }}
                            className="far fa-bell"
                          ></i>
                        </div>
                      ) : (
                        <div className="fa-2x">
                          <i
                            style={{ color: 'white' }}
                            className="far fa-bell"
                          ></i>
                        </div>
                      )}
                    </button>
                  </Badge>
                </Dropdown>
              </>
            )}
          {filterLang.length > 1 && (
            <Select
              labelInValue
              value={defaultLanguage}
              className="LanguageSelect ms-4 me-2 d-md-block d-sm-block"
              style={{
                width: 'fit-content',
                outline: 'none',
                boxShadow: 'none',
              }}
              onChange={changeLanguage}
            >
              {filterLang.length > 1 &&
                filterLang?.map((language) => (
                  <Select.Option
                    key={`LanguageId${language.value}`}
                    value={language.value}
                    className="text-capitalize"
                    name={language.langCode}
                  >
                    {language.label}
                  </Select.Option>
                ))}
            </Select>
          )}
          <ul className="headerUl DFlex ms-3">
            {isAuthenticated && (
              <ProfileMenu>
                <div className="profileSec">
                  <div className="DFlex">
                    <Badge color={'green'}>
                      <div className="imgDiv">
                        {fairOptions?.disable_update_user_profile == 1 ? (
                          <Fragment>
                            {app?.user?.profile_image && !imageError ? (
                              <div>
                                <img
                                  src={`${process.env.REACT_APP_ASSETS_URL}/assets/candidate_avatars/${app?.user?.profile_image}`}
                                  className="imgCover fileImg"
                                  onError={() => setImageError(true)}
                                  alt=""
                                />
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={`https://ui-avatars.com/api/?name=${app.user?.name}`}
                                  className="imgCover fileImg"
                                  alt=""
                                />
                              </div>
                            )}
                          </Fragment>
                        ) : (
                          <button
                            type="button"
                            onClick={() => setAvatar(true)}
                            className="UploadAvatar"
                          >
                            {app?.user?.profile_image && !imageError ? (
                              <div>
                                <img
                                  src={`${process.env.REACT_APP_ASSETS_URL}/assets/candidate_avatars/${app?.user?.profile_image}`}
                                  className="imgCover fileImg"
                                  onError={() => setImageError(true)}
                                  alt=""
                                />
                                <span className="previewUpload DFlex">
                                  <Edit3 size={18} />
                                </span>
                              </div>
                            ) : (
                              <div>
                                <img
                                  src={`https://ui-avatars.com/api/?name=${app.user?.name}`}
                                  className="imgCover fileImg"
                                  alt=""
                                />
                                <span className="previewUpload DFlex">
                                  <Edit3 size={18} />
                                </span>
                              </div>
                            )}
                          </button>
                        )}
                      </div>
                    </Badge>
                  </div>
                  {/* </Col>
                    </Row> */}
                </div>
                <Modal
                  visible={avatar}
                  title={
                    app?.fairLanguage?.update_profile_image
                      ? app?.fairLanguage?.update_profile_image
                      : 'Upload Profile Image'
                  }
                  onCancel={modalClose}
                  footer={null}
                >
                  <ImageUpload
                    modalClose={modalClose}
                    upImg={upImg}
                    setUpImg={setUpImg}
                  />
                </Modal>
              </ProfileMenu>
            )}

            {isAuthenticated ? (
              <div className="logout">
                <Button
                  className="ProfileMenuBtn"
                  onClick={initializeSignOut}
                  icon={<LogoutOutlined />}
                />
              </div>
            ) : (
              !isAuthenticated &&
              window.innerWidth >= 500 && (
              <li>
                <button type="button" onClick={initializeSignIn}>{app?.fairLanguage?.login}</button>
              </li>
              )
            )}
            {!isAuthenticated &&
              !app?.appLayout?.fair?.options?.disable_registration_front &&
              window.innerWidth >= 500 && (
                <Fragment>
                  <li>|</li>
                  <li>
                    <button
                      type="button"
                      onClick={() => dispatch(handleRegisterBtn(true))}
                    >
                      {app?.fairLanguage?.register}
                    </button>
                  </li>
                </Fragment>
              )}
          </ul>
        </div>
      </Layout.Header>

      <MobileSidebarDrawer visible={visible} setVisible={setVisible} />
      {app.isLoginBtn && (
        <LoginDrawer
          onClose={handleCloseLogin}
          isLogin={isLogin}
          setIsLogin={setIsLogin}
          sRegister={isRegister}
          setIsRegister={setIsRegister}
        />
      )}
      {app.isRegisterBtn && (
        <RegisterDrawer
          isRegister={isRegister}
          setIsRegister={setIsRegister}
          isLogin={isLogin}
          setIsLogin={setIsLogin}
        />
      )}
      {exitServayModal && (
        <ExitServayModal
          exitServayModal={exitServayModal}
          setExitServayModal={() => setExitServayModal(!exitServayModal)}
        />
      )}
    </Fragment>
  )
}

export default NavHeader
